/* eslint-disable */
import { Breadcrumb, Button, Col, Divider, Edit, Form, Icons, Input, List, Modal, RcFile, Row, Select, Typography, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";

import dayjs from "dayjs";

import { message, notification, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { majorCareUrl } from "../../../constants";

    {/* 
        MAIN STATUS
        64b505fef3440e02a3156fb3 = เปิดงาน
        64b50609f3440e02a3156fb4 = ปิดงาน
    */}

    {/* 
        SUB STATUS
        ******* main = เปิดงาน ******* option_open_job
        64b50671f3440e02a3156fb5 = รอดำเนินการ
        64b5067ef3440e02a3156fb6 = กำลังดำเนินการ

        ******* main = เลื่อนนัดจากนิติ ******* option_close_job
        64b5069bf3440e02a3156fb7 = งานซ้ำ
        64b506abf3440e02a3156fb8 = ซ่อมเสร็จแล้ว
        64b506c4f3440e02a3156fb9 = เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ
        64b506dcf3440e02a3156fba = เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)
        64b506ebf3440e02a3156fbb = นิติลงมติยังไม่ดำเนินการ
    */}

export const MjcRepairFacilityNewJobCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    // console.log('user_data', user_data?.user_profile)
    const format = 'YYYY-MM-DD HH:mm';
    const { Option } = Select;
    const { Text } = Typography;


    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [res_data, set_res_data] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);
    const [textcount1, settextcount1] = useState('');
    const [textcount2, settextcount2] = useState('');

    const [vVid, setVid] = useState<any>();
    const [vVidId, setVidId] = useState<any>([]);

    const [vImgEn, setImgEn] = useState<any>();
    const [ImgId, SetImgId] = useState<any>([]);

    // const [InpectorName, SetInpectorName] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]
    // console.log('item_id', item_id)

    // ******** MAIN STATUS ********
    const option_main_status:any = [
        {
            label: "เปิดงาน",
            value: "64b505fef3440e02a3156fb3",
        },
        {
            label: "ปิดงาน",
            value: "64b50609f3440e02a3156fb4",
        },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_open_job:any = [
        {
            label: "รอดำเนินการ",
            value: "64b50671f3440e02a3156fb5",
        },
        {
            label: "กำลังดำเนินการ",
            value: "64b5067ef3440e02a3156fb6",
        },
    ];

    const option_close_job:any = [
        {
            label: "งานซ้ำ",
            value: "64b5069bf3440e02a3156fb7",
        },
        {
            label: "ซ่อมเสร็จแล้ว",
            value: "64b506abf3440e02a3156fb8",
        },
        {
            label: "เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ",
            value: "64b506c4f3440e02a3156fb9",
        },
        {
            label: "เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)",
            value: "64b506dcf3440e02a3156fba",
        },
        {
            label: "นิติลงมติยังไม่ดำเนินการ",
            value: "64b506ebf3440e02a3156fbb",
        },
    ];

    // const [Inspector, SetInspector] = useState<any>([]); // user-profile
    // Data ส่วนของ รายละเอียดการแจ้ง
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();

    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    // Data แก้ไขสถานะรายการ ทำวันนัด
    const [Stat, setStat] = useState<any>(undefined);
    const [SubStat, setSubStat] = useState<any>(undefined);
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [Inspector, SetInspector] = useState<any>();
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [ vPropertyID, setPropertyID ] = useState<any>([]);
    const [ vPropertyName, setPropertyName ] = useState<any>([]);
    const [ UnitId, setUnitId ] = useState<any>([]);
    const [ UnitName, setUnitName ] = useState<any>();
    // const [ TransferDate, SetTransferDate] = useState<any>("");
    const [ CustTel, SetCustTel] = useState<any>("");

    // console.log('AppointStart', AppointStart)

    // MASTER REPAIR DATA
    const [ DataZone, SetDataZone] = useState<any>([]);
    const [ DataCategory, SetDataCategory] = useState<any>([]);
    const [ DataCatDetail, SetDataCatDetail] = useState<any>([]);
    
    // SELECTED DATA
    const [ SelectedCateDetail, SetSelectedCateDetail] = useState<any>([]);
    const [ SelectedCategory, SetSelectedCategory] = useState<any>();
    const [ SelectedZone, SetSelectedZone] = useState<any>();
    const [ RemarkStat, SetRemarkStat] = useState<any>("");
    const [ RemarkDetail, SetRemarkDetail] = useState<any>("");


    const [ TechnicianName, SetTechnicianName] = useState<any>("");
    const [ TechnicianEmail, SetTechnicianEmail] = useState<any>("");


    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const [ CountVid, SetCountVid ] = useState<any>(0);
    const [ CountImg, SetCountImg ] = useState<any>(0);

    let sla_days:any
    let sla_hours:any

    let initF : any;
    initF = {
        field       : 'deleteStatus',
        operator    : 'contains',
        value       :  'N',
    };

    // console.log('Inspector', Inspector)
    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        // getRepairStatus();
        getRepairZone();
        getRepairCategory();
        // getRepairCateDetail(); // ย้ายไป get ตอน select category
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-settings",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            // {
            //     field: "role_management][id]",
            //     operator: "eq",
            //     value: 19 // role Inspector
            // },
            // {
            //     field: "property][id]",
            //     operator: "in",
            //     // value: PropertyId ? PropertyId : null
            //     value: PropertyIdJob ? PropertyIdJob : null 

            // },
            // {
            //     field: "user_profile][id]",
            //     operator: "in",
            //     value: InspectorSettingId ? InspectorSettingId : null // role Inspector
            // }
            {
                field: "role_management][id]",
                operator: "eq",
                value: 21 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const FindInspectorUserId = async (e:any) => {    
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        let inspector_profile = k?.data?.data;
        SetInspector(inspector_profile[0].attributes.user_profile?.data.id)
        SetInspectorProfile(inspector_profile)
    }
  
    const handleDatePickerChange = (date: Dayjs | null, dateString: string, type: any) => {
        // console.log('dateString', dateString)
        if(type == "start"){
            setAppointStart(dateString);
        }else{
            setAppointEnd(dateString);
        }
    };

    const getRepairZone = async () => {
        let res = await axios.get(majorCareUrl + "/majorcare/repairZoneFacility" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            SetDataZone(dataLoad ? filteredDataCate : null)
        })
    }

    const getRepairCategory = async () => {
        let res = await axios.get(majorCareUrl + "/majorcare/repairCategoryFacility" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            SetDataCategory(dataLoad ? filteredDataCate : null)
        })
    }

    // const getRepairCateDetail = async () => {
    //     let res = await axios.get(
    //     majorCareUrl + `/majorcare/repairCategoryDetail`,
    //     { headers: {  } }).then((res) => {
    //         let dataCateDetail: any = res?.data;
    //         // console.log('dataCateDetail', dataCateDetail)
    //         SetDataCatDetail(dataCateDetail);
    //     });
    // }

    async function createJobAndTrail(){
        let dataAssign:any
        let data:any

        console.log('SelectedCategory', SelectedCategory)
        console.log('SelectedZone', SelectedZone)

        let userData = user_data?.user_profile

        let user_profile_data = {
            "first_name": userData ? userData.first_name : '-',
            "last_name":userData ? userData.last_name : '-' ,
            "gender": userData ? userData.gender : '-',
            "nick_name": userData ? userData.nick_name : '-',
            "tel": userData ? userData.tel : '-',
            // "user_sub_types": userData ? userData.user_sub_type : null,
            // "user_sub_types": userData ? data_sub_type : null,
            "user_type": userData ?  userData.user_type.type_name : null,
        }

        // find property in majorcare db
        let MajorProperty = await axios.get(majorCareUrl + "/majorcare/findAllProperty", { headers: { } });
        // console.log('MajorProperty', MajorProperty)
        let FindProp:any = MajorProperty?.data.find((item:any) => item?.property_id == vPropertyID);
        // console.log('FindProp', FindProp)

        // find unit in majorcare db
        let MajorUnit = await axios.get(majorCareUrl + "/majorcare/findAllUnit", { headers: { } });
        // console.log('MajorUnit', MajorUnit)
        let FindUnit:any = MajorUnit?.data.find((item:any) => item?.unit_id == UnitId);
        // console.log('FindUnit', FindUnit)

        {/* 
            MAIN STATUS
            64b505fef3440e02a3156fb3 = เปิดงาน
            64b50609f3440e02a3156fb4 = ปิดงาน
        */}

        {/* 
            SUB STATUS
            ******* main = เปิดงาน ******* option_open_job
            64b50671f3440e02a3156fb5 = รอดำเนินการ
            64b5067ef3440e02a3156fb6 = กำลังดำเนินการ

            ******* main = เลื่อนนัดจากนิติ ******* option_close_job
            64b5069bf3440e02a3156fb7 = งานซ้ำ
            64b506abf3440e02a3156fb8 = ซ่อมเสร็จแล้ว
            64b506c4f3440e02a3156fb9 = เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ
            64b506dcf3440e02a3156fba = เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)
            64b506ebf3440e02a3156fbb = นิติลงมติยังไม่ดำเนินการ
        */}

        // ****************** 1. CREATE JOB ******************
        // ***************************************************
        data = {
            "status_id": Stat.value,
            "status_name": Stat.label,
            "sub_status_id": SubStat !== undefined ? SubStat.value : '',
            "sub_status_name": SubStat !== undefined ? SubStat.label : '',
            "is_delegate": true,  // admin create job == true
            "property": FindProp ? FindProp._id : '',
            "create_by": user_data?.user_profile,
            "tc": {},
            "request_by": {
                // "attributes": userData ? userData.user_profile : null,
                "attributes": userData ? user_profile_data : null,
                "unitDetail": {
                    "unit_id": UnitId ? UnitId : null ,
                    "unit_name": UnitName ? UnitName : null,
                    "unit_name_en": UnitName ? UnitName : null,
                    "unit_number": UnitName ? UnitName : null,
                    "transfer_date": TransferDate ? TransferDate : '',
                    "unit_type": '',
                    "property_id": vPropertyID,
                    "property_name": vPropertyName
                }
            },
            "assign": {
                "email": TechnicianEmail ? TechnicianEmail : null,
                "name": TechnicianName ? TechnicianName : null
            },
        };

        // console.log('data', data)

        let res = await axios.post(majorCareUrl + "/majorcare/repairJobFacility", data, { headers: { } }).then(async response => {
            console.log(response.data);
            let resJobId = response.data._id
            let resJobData = response?.data
            console.log('resJobId', resJobId)
            console.log('resJobData', resJobData)

            // ****************** 2. CREATE TRAIL ******************
            // *****************************************************
            let dataTrail = {
                "repair_job":resJobId ? resJobId : '',
                "status": Stat.value,
                "status_name": Stat.label,
                "sub_status": SubStat !== undefined ? SubStat.value : null,
                "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "remark": RemarkStat !== undefined ? RemarkStat : "",
                "create_by": Identity_data?.user_profile,
                "assign": {
                    "email": TechnicianEmail ? TechnicianEmail : null,
                    "name": TechnicianName ? TechnicianName : null
                },
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailFacility", dataTrail, { headers: { } });
            // console.log('res_trail', res_trail)

            // // ****************** 3. CREATE JOB DETAIL ******************
            // // **********************************************************
            let dataJobDetail = {
                "job_id": resJobId ? resJobId : '',
                "zone": SelectedZone ? SelectedZone.value : null,
                "category": SelectedCategory ? SelectedCategory.value : null ,
                // "category_detail": SelectedCateDetail ? SelectedCateDetail.value : null ,
                "pic": ImgId,
                "vdo": vVidId[0],
                // "detail": "จะซ่อมก็ซ่อม",
                "detail": RemarkDetail !== undefined ? RemarkDetail : "",
                // "contact": CustTel ? CustTel : "",
                "create_by": Identity_data?.user_profile,
            }
            let res_detail = await axios.post(majorCareUrl + "/majorcare/repairJobDetailFacility", dataJobDetail, { headers: { } });


        // ====== SEND EMAIL GROUP ========

        if(TechnicianEmail !== undefined){
            let data_send_mail = {
                "job_no": resJobData.job_id + '',
                "property_id": resJobData?.request_by.unitDetail.property_id +'', 
                "property_name": resJobData?.request_by.unitDetail.property_name +'', 
                "zone": SelectedZone.children,
                "category": SelectedCategory.children,
                "description": RemarkStat + '',
                "inspector_name": TechnicianName + '',
                "email": TechnicianEmail
            }

            console.log('data_send_mail', data_send_mail)
            // let res_email = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
            let res_email = await axios.post(API_URL + `/like-systems/sendEmailRepairJobFacility`, data_send_mail , {
                headers: { Authorization: "Bearer " + TOKEN_KEY }
            });

            console.log('res_email', res_email)
        }


            // // setCountInterval(0)
            successNoti('200', 'success');
            replace("/repair-facility/repair-facility-job");

        })
        // console.log('res', res)
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully created',
        });
    };

    const { selectProps: PropertySelectProps } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        filters: [
            initF,
            // {
            //     field : 'id',
            //     operator : 'nin',
            //     // value : 1
            //     // value :  ["1","2","3","7"],
            //     value :  vPropCreate[0],
            // },
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            //  {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
        ]
    });


    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }

        console.log('isJpgOrPng', isJpgOrPng)
        console.log('isLt2M', isLt2M)

        // if(isJpgOrPng && isLt2M){
        //     return Dragger.ignore
        // }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const beforeUploadVid = (file: RcFile) => {
        const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/quicktime';
        if (!isJpgOrPng) {
            message.error('You can only upload MP4 file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
            message.error('Image must smaller than 25MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange_vid(e: any) {
        console.log('event vid', e)
        if(e.file.status === "removed") {
            SetCountVid(CountVid -1)
            // setImgEn((pre:any) => ({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [],
            // }));

            // setVidId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));
            setVidId([]);

            setVid((prev: any) => ({
                ...prev,
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));

            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            SetCountVid(CountVid +1)

            if (e.file.response) {
                let res = e.file.response[0];
                setVidId((pre:any) => ([
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setVid((pre:any) => ([{
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    function fileChange_EN(e: any) {
        console.log('event', e)
        if(e.file.status === "removed") {
            SetCountImg(CountImg -1)
            // setImgEn((pre:any) => ({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [],
            // }));

            SetImgId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));

            if(vImgEn.defaultFileList.length == 1){
                setImgEn((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: []
                }));
            }else{
                setImgEn((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
                }));
            }
            // setImgEn((prev: any) => ({
            //     ...prev,
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
            // }));

            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                SetCountImg(CountImg +1)

                let res = e.file.response[0];
                SetImgId((pre:any) => ([
                    ...pre,
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setImgEn((pre:any) => ([
                    // ...pre,
                    {
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    const handleChangeRemark1 = (e:any) => {
        settextcount1(e.target.value);
    };

    const handleChangeRemark2 = (e:any) => {
        settextcount2(e.target.value);
    };

    const onChangeEmail = (e: any) => {
        SetTechnicianEmail(e.target.value);
    }

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="Create รายการแจ้งซ่อม"
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>แจ้งซ่อมส่วนกลาง</Breadcrumb.Item>
                    <Breadcrumb.Item>รายการแจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Create รายการแจ้งซ่อมส่วนกลาง</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form
                form = {form}
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    createJobAndTrail();
                }}
            >
                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียด
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label="Property"
                                    name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                    rules={[
                                        {
                                            required:true , message: "Please select property"
                                        },
                                    ]}
                                >
                                    <Select 
                                        {...PropertySelectProps}
                                        // onChange={(e:any, label:any) => setPropertyID(e)}
                                        // onSelect={(label:any) => setPropertyName(label)}
                                        // onSelect={(e:any, value:any) => {console.log('label >>>>>>> ', value.label)}}
                                        onSelect={(e:any, value:any) => {setPropertyName(value.label)}}
                                        // onSelect={(value:any) => setPropertyName(value.label)}
                                        onChange={(e:any) => setPropertyID(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="Job no."
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input 
                                        placeholder="autorun" 
                                        disabled
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่แจ้ง"
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input 
                                        value={dayjs(CreateDate).format("DD/MM/YY HH:mm")} 
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียดการแจ้งซ่อม1233
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="โซน" 
                            name="zone"
                            style={{width:"95%", marginTop:'-3%'}}
                            rules={[
                                {
                                    required:true , message: "Please select zone"
                                },
                            ]}
                        >
                            <Select
                                onChange={(e:any, record:any) => SetSelectedZone(record)}
                            >
                                {
                                    DataZone?.length > 0 ?
                                        DataZone?.map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                >
                                                    {item.zone_name ? item.zone_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="หมวด" 
                            name="category"
                            style={{width:"100%", marginTop:'-3%'}}
                            rules={[
                                {
                                    required:true , message: "Please select category"
                                },
                            ]}
                        >
                            <Select
                                onChange={(e:any, record:any) => { SetSelectedCategory(record)} }
                            >
                                {
                                    DataCategory?.length > 0 ?
                                        DataCategory?.filter((item:any) => item.zone_name == SelectedZone?.value).map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                    data={item}
                                                >
                                                    {item.category_name ? item.category_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={16}>
                        <Form.Item 
                            label="รายละเอียด" 
                            // name="remark"
                            style={{width:"100%"}}
                        >
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                maxLength={255}
                                onChange={(e) => { 
                                    SetRemarkDetail(e.target.value)
                                    handleChangeRemark1(e)
                                }}
                                // disabled={Stat == "6485bd6e681aef755a4f279b" ? true : false}
                            />
                            <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount1?.length} / 255`}</Text>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        {/* รูปประกอบ: */}
                        <Form.Item
                            label="รูปประกอบ (สูงสุด 10 รูป)"
                            name="pic2"
                        >
                            <Upload.Dragger 
                                style={{ 
                                    borderRadius: "10px",
                                    backgroundColor: CountImg == 5 ? 'gainsboro' : '#40495F', 
                                }}
                                {...vImgEn}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={5}
                                beforeUpload={beforeUpload}
                                onChange={fileChange_EN}
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="ไฟล์วิดีโอ (1 คลิป)"
                            name="pic"
                        >
                            <Upload.Dragger 
                                style={{ 
                                    borderRadius: "10px", 
                                    backgroundColor: CountVid == 1 ? 'gainsboro' : '#40495F', 
                                    // opacity: CountVid == 1 ? '2' : '0.65'  
                                    // visibility: CountVid == 1 ? 'hidden' : 'visible'
                                }}
                                {...vVid}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={1}
                                multiple={false}
                                beforeUpload={beforeUploadVid}
                                onChange={fileChange_vid}
                                // disabled={CountVid == 1 ? true : false}
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            สถานะการทำงาน
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="สถานะ" 
                            name="status"
                            style={{width:"90%"}}
                            rules={[
                                {
                                    required:true , message: "Please select status"
                                },
                            ]}
                        >
                            {/* 
                                MAIN STATUS
                                64b505fef3440e02a3156fb3 = เปิดงาน
                                64b50609f3440e02a3156fb4 = ปิดงาน
                            */}

                            {/* 
                                SUB STATUS
                                ******* main = เปิดงาน ******* option_open_job
                                64b50671f3440e02a3156fb5 = รอดำเนินการ
                                64b5067ef3440e02a3156fb6 = กำลังดำเนินการ

                                ******* main = เลื่อนนัดจากนิติ ******* option_close_job
                                64b5069bf3440e02a3156fb7 = งานซ้ำ
                                64b506abf3440e02a3156fb8 = ซ่อมเสร็จแล้ว
                                64b506c4f3440e02a3156fb9 = เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ
                                64b506dcf3440e02a3156fba = เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)
                                64b506ebf3440e02a3156fbb = นิติลงมติยังไม่ดำเนินการ
                            */}
                            <Select 
                                placeholder={t("select.sel")}
                                style={{ width: '100%' }}
                                onSelect={(value: any, record:any) => {
                                    setStat(record);
                                }}
                                options={option_main_status}
                            />
                        </Form.Item>
                    </Col>


                    {
                        Stat !== undefined ?
                        Stat.value == "64b505fef3440e02a3156fb3" ||  Stat.value == "64b50609f3440e02a3156fb4" ?
                            <Col span={8}>
                                {/* 
                                    4.2.3.กรณีเลือก สถานะที่มี สถานะย่อย ให้แสดงสถานะย่อยให้เลือกด้วย บังคับเลือก
                                    64b505fef3440e02a3156fb3 = เปิดงาน   option_open_job
                                    64b50609f3440e02a3156fb4 = ปิดงาน    option_close_job
                                */}
                                <Form.Item 
                                    label="สถานะย่อย" 
                                    name="sub_status"
                                    style={{width:"90%"}}
                                    rules={[
                                        {
                                            required:true , message: "Please select sub status"
                                        },
                                    ]}
                                >
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            setSubStat(record);
                                        }}
                                        options={
                                            Stat.value == "64b505fef3440e02a3156fb3" ? option_open_job
                                            : Stat.value == "64b50609f3440e02a3156fb4" ? option_close_job
                                            : null
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        : <></>
                        : <></>
                    }
                </Row>            

                {
                    Stat ?
                        // Stat.value == "64b505fef3440e02a3156fb3" && SubStat.value == "64b50671f3440e02a3156fb5" ?
                        // Stat.value == "64b505fef3440e02a3156fb3" ?
                        Stat?.value == "64b505fef3440e02a3156fb3" && SubStat?.value == "64b5067ef3440e02a3156fb6" ?
                            <>
                                 <Row>
                                    <Col span={8}>
                                        <Form.Item 
                                            label="ชื่อช่าง" 
                                            name="technician"
                                            style={{width:"90%"}}
                                            rules={[
                                                {
                                                    required:true , message: "Please enter technician's name"
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Technician Name" onChange={(e) => SetTechnicianName(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label="email" 
                                            name="email"
                                            style={{width:"90%"}}
                                            rules={[
                                                {
                                                    required:true , message: "Please enter email"
                                                },
                                            ]}
                                        >
                                            <TextArea 
                                                style={{height: "100px", maxHeight: "80px"}} 
                                                placeholder="seperate email by comma ex. testmail@mail.com,testmail2@mail.com" 
                                                onChange={(e) => onChangeEmail(e)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        : <></>
                    : <></>
                }

                <Row>
                    <Col span={18}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            <TextArea 
                                style={{ height: "90px", maxHeight: "80px" }}
                                // defaultValue={RemarkFromLastTrail}
                                maxLength={255}
                                // value={Remark}
                                onChange={(e) => {
                                    SetRemarkStat(e.target.value)
                                    handleChangeRemark2(e)
                                }}
                                // disabled={Stat == "6485bd6e681aef755a4f279b" ? true : false}
                            />
                            <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount2?.length} / 255`}</Text>

                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                </Row>

            </Form>
    </Edit>   
    );
};