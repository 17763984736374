/* eslint-disable */
import { Breadcrumb, Button, Col, Divider, Edit, Form, Icons, Image, Input, Modal, RcFile, Row, Select, UploadFile, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { DatePicker, DatePickerProps, InputNumber, Table, Tag, Upload, message, notification } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";

 
// รออนุมัติงานซ่อม แสดงรายการที่มีสถานะ รออนุมัติงานซ่อม , อนุมัติงานซ่อม
export const MjcRepairMdpcWaitCustApproveJobEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const format = 'YYYY-MM-DD HH:mm';

    const { RangePicker } = DatePicker;

     // ******** MAIN STATUS ********
     const option_main_status:any = [
        {
            label: "ปิดงาน",
            value: "64985bcf515b80b78fe13bba",
        },
    ];
    const option_main_status_waiting:any = [
        // {
        //     label: "รอดำเนินการ",
        //     value: "649859ca515b80b78fe13b84",
        // },
        // {
        //     label: "รับเรื่องแจ้งซ่อม",
        //     value: "649859e7515b80b78fe13b86",
        // },
        {
            label: "รออนุมัติใบแจ้งซ่อม",
            value: "64ab86a5785d4236922eaff7"
        },
        {
            label: "นัด วัน/เวลา ประเมินหน้างาน",
            value: "649859f8515b80b78fe13b88",
        },
        {
            label: "เข้าประเมิน",
            value: "64985a01515b80b78fe13b8a",
        },
        {
            label: "เข้าประเมิน-เกินกำหนด",
            value: "64985a0f515b80b78fe13b8c",
        },
        {
            label: "ประเมินเสร็จ",
            value: "64985a29515b80b78fe13b8e",
        },
        {
            label: "นัด วัน/เวลา เข้าซ่อม",
            value: "64985a35515b80b78fe13b90",
        },
        {
            label: "เข้าซ่อม",
            value: "64985a3c515b80b78fe13b92",
        },
        {
            label: "เข้าซ่อม-เกินกำหนด",
            value: "64985a47515b80b78fe13b94",
        },
        {
            label: "เลื่อนนัดจากลูกค้า",
            value: "64985a67515b80b78fe13b96",
        },
        {
            label: "เลื่อนนัดจากนิติ",
            value: "64985ae5515b80b78fe13ba2",
        },
        {
            label: "อุปกรณ์อยู่ระหว่างการผลิต",
            value: "64985b3b515b80b78fe13bac",
        },
        {
            label: "ลูกค้าจัดหาอะไหล่",
            value: "64d0a43db0a6b7718d211832",
        },
        {
            label: "นิติจัดหาอะไหล่ (มีค่าใช้จ่าย)",
            value: "64d0a44fb0a6b7718d211833",
        },
        {
            label: "รออนุมัติงานซ่อม",
            value: "64985bbe515b80b78fe13bb6",
        },
        {
            label: "อนุมัติงานซ่อม",
            value: "64985bc6515b80b78fe13bb8",
        },
        {
            label: "ส่งใบเสนอราคา",
            value: "64ad1085f3440e02a3156fa6",
        },
        {
            label: "อนุมัติใบเสนอราคา",
            value: "64ad1075f3440e02a3156fa5",
        },
        {
            label: "ไม่อนุมัติใบเสนอราคา",
            value: "64ad1091f3440e02a3156fa7",
        },
        {
            label: "ปิดงาน",
            value: "64985bcf515b80b78fe13bba",
        },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_cust_postpone:any = [
        {
            label: "ลูกค้าติดธุระส่วนตัว",
            value: "64985a95515b80b78fe13b98",
        },
        {
            label: "ลูกค้าเป็นโควิด/กักตัว",
            value: "64985a9c515b80b78fe13b9a",
        },
        {
            label: "ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน",
            value: "64985aaa515b80b78fe13b9c",
        },
        {
            label: "ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน",
            value: "64985ab9515b80b78fe13b9e",
        },
        {
            label: "ไม่สามารถติดต่อลูกค้าได้",
            value: "64985ac3515b80b78fe13ba0",
        },
    ];

    const option_juristic_postpone:any = [
        {
            label: "ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด",
            value: "64985b04515b80b78fe13ba4",
        },
        {
            label: "ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้",
            value: "64985b0d515b80b78fe13ba6",
        },
        {
            label: "ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง",
            value: "64985b17515b80b78fe13ba8",
        },
        {
            label: "รอ Major อนุมัติ PO/PR",
            value: "64985b21515b80b78fe13baa",
        },
    ];

    const option_matt_in_manufacturing:any = [
        {
            label: "อุปกรณ์ สุขภัณฑ์",
            value: "64985b5e515b80b78fe13bae",
        },
        {
            label: "วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์",
            value: "64985b68515b80b78fe13bb0",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์ที่แจ้ง",
            value: "64985b74515b80b78fe13bb2",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า",
            value: "64985b80515b80b78fe13bb4",
        },
    ];

    const option_close_job:any = [
        {
            label: "ส่งงานเสร็จสมบูรณ์",
            value: "64985be1515b80b78fe13bbc",
        },
        {
            label: "Customer เปิดงานซ้ำ",
            value: "64985bec515b80b78fe13bbe",
        },
        {
            label: "Customer เปิดงานผิด",
            value: "64985bf6515b80b78fe13bc0",
        },
        {
            label: "Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า",
            value: "64985c0c515b80b78fe13bc2",
        },
        {
            label: "ให้คำแนะนำ เพื่อจัดจ้าง",
            value: "64985c15515b80b78fe13bc4",
        },
        {
            label: "งานที่ไม่อยู่ในเงื่อนไขรับประกัน",
            value: "64985c22515b80b78fe13bc6",
        },
        {
            label: "รายละเอียดไม่ตรงกับรายการรับประกัน",
            value: "64985c2d515b80b78fe13bc8",
        },
        {
            label: "ลูกค้าเลื่อนซ่อม เกิน SLA",
            value: "64985c38515b80b78fe13bca",
        },
        {
            label: "รอสั่งของ",
            value: "64985c42515b80b78fe13bcc",
        },
        {
            label: "งานซ่อมที่เกิดจากการใช้งาน",
            value: "64985c4a515b80b78fe13bce",
        },
        {
            label: "งานเคลมประกันภัยอาคาร",
            value: "64985c56515b80b78fe13bd0",
        },
    ];

    const { Option } = Select;

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let job_id = id[1]

    // console.log('item_id', item_id)
    // console.log('job_id', job_id)

    // const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียด
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [CustomerTelNo, SetCustomerTelNo] = useState<any>();
    const [CustomerEmail, SetCustomerEmail] = useState<any>();
    const [Type, SetType] = useState<any>();


    // Data ส่วนของ รายละเอียดการแจ้งซ่อม
    const [ZoneId, SetZoneId] = useState<any>(undefined); 
    const [ZoneName, SetZoneName] = useState<any>(undefined); 
    const [CateId, SetCateId] = useState<any>(undefined); 
    const [CateName, SetCateName] = useState<any>(undefined); 
    const [CateDetailId, SetCateDetailId] = useState<any>(undefined); 
    const [CateDetailName, SetCateDetailName] = useState<any>(undefined); 
    const [WarantyDate, SetWarantyDate] = useState<any>(undefined); 
    const [IsWaranty, SetIsWaranty] = useState<any>(undefined); 
    const [WarantyExpire, SetWarantyExpire] = useState<any>(undefined); 
    const [RemarkDetail, SetRemarkDetail] = useState<any>(undefined); 
    const [vImgEn, setImgEn] = useState<any>();
    const [vVid, setVid] = useState<any>();
    const [vImgInspec, setImgInspec] = useState<any>();
    const [vVidInspec, setVidInspec] = useState<any>();


     // MASTER REPAIR DATA
     const [ DataZone, SetDataZone] = useState<any>([]);
     const [ DataCategory, SetDataCategory] = useState<any>([]);
     const [ DataCatDetail, SetDataCatDetail] = useState<any>([]);


    // SELECTED DATA
    const [ SelectedCateDetail, SetSelectedCateDetail] = useState<any>([]);
    const [ SelectedCategory, SetSelectedCategory] = useState<any>();
    const [ SelectedZone, SetSelectedZone] = useState<any>();
    const [ RemarkStat, SetRemarkStat] = useState<any>("");
    const [SubStat, setSubStat] = useState<any>(undefined);



    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);
    const [LastTrail, setLastTrail] = useState<any>(undefined);
    const [LastTrailDate, setLastTrailDate] = useState<any>(undefined);
    const [dataSourceTrail, SetDataSourceTrail] = useState<any>([]);


    // Data แก้ไขสถานะรายการ ทำวันนัด
    const [Stat, setStat] = useState<any>();
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [Inspector, SetInspector] = useState<any>();
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [PropertyId, SetPropertyId] = useState<any>();
    const [Remark, SetRemark] = useState<any>("");


    // Data การอนุมัติจากลูกค้า
    const [ApproveDate, SetApproveDate] = useState<any>();
    const [ApproveName, SetApproveName] = useState<any>();
    const [ApproveType, SetApproveType] = useState<any>();
    const [ApproveUrl, SetApproveUrl] = useState<any>();
    const [ResultApprove, SetResultApprove] = useState<any>();


    // Data HOD Approve
    const [IsHodApprove, SetIsHodApprove] = useState<any>(false);


    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const [dataSource, SetDataSource] = useState<any>([]);

    const [SlipImg, SetSlipImg] = useState<any>();

    const [Price, SetPrice] = useState<any>(0);
    const [vQuoId, setQuoId] = useState<any>([]);
    const [vQuotation, setQuotation] = useState<any>({
        name: 'files',
        multiple: false,
        defaultFileList:[],
    });

    let sla_days:any
    let sla_hours:any


    function isISOString(dateString:any) {
        const parsedDate = dayjs(dateString);
        if(parsedDate.isValid() && parsedDate.toISOString() === dateString){
            return dateString
        }else{
            return  dayjs(dateString, 'DD/MM/YYYY').format("YYYY-MM-DD");
        }
        // return parsedDate.isValid() && parsedDate.toISOString() === dateString;
    }

    const getDataK = async ()=>{
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId?stat=${job_id}`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad', dataLoad)
                dataSource.push(dataLoad[0])
                let keepTime = null

                // Set รายละเอียด
                SetJobNo(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].job_id : '-' : '-')
                SetCreateDate(dataLoad ? dataLoad[0].job_obj[0].create_date ? dataLoad[0].job_obj[0].create_date : '-' : '-')
                // SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by ? dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-')
                // SetTransferDate(isISOString(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date))
                SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by ? dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YYYY") : '-' : '-')
                keepTime = dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YYYY")
                if (keepTime == "Invalid Date") {
                    SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date : '-')
                }else {
                    SetTransferDate(keepTime)
                }

                if (dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date == "") {
                    SetTransferDate("-")
                }

                SetProperty(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_name : '-' : '-')
                SetUnit(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.unit_name : '-' : '-')
                SetPropertyIdJob(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_id : '-' : '-')
                
                SetCustomer(dataLoad[0].job_obj[0].request_by.attributes !== undefined ? dataLoad[0].job_obj[0].request_by.attributes.first_name + ' ' + dataLoad[0].job_obj[0].request_by.attributes.last_name : '-')
                // SetType(dataLoad[0] ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-' : '-')
                SetType(
                    dataLoad[0] ? 
                        dataLoad[0].job_obj[0].request_by ? 
                            dataLoad[0]?.job_obj[0]?.request_by?.attributes?.user_sub_types?.data?.length > 0 ? 
                                dataLoad[0]?.job_obj[0]?.request_by?.attributes?.user_sub_types?.data[0]?.attributes?.sub_type_name 
                            : '-' 
                        : '-' 
                    : '-'
                )
                // SetCustomerTelNo(dataLoad[0].job_obj[0].request_by.tel !== undefined ? dataLoad[0].job_obj[0].request_by.tel : '-')
                // SetCustomerEmail(dataLoad[0].job_obj[0].request_by?.attributes.user !== undefined ? dataLoad[0].job_obj[0].request_by?.attributes.user?.attributes.email : '-')
                SetCustomerTelNo(dataLoad[0]?.job_obj[0]?.request_by?.tel !== undefined ? dataLoad[0].job_obj[0].request_by.tel : dataLoad[0].job_obj[0]?.request_by?.attributes?.tel)
                SetCustomerEmail(
                    dataLoad[0].job_obj[0].request_by?.attributes.user !== undefined ? 
                        dataLoad[0].job_obj[0].request_by?.attributes.user?.attributes.email 
                    : dataLoad[0].job_obj[0].request_by?.attributes?.username ? dataLoad[0].job_obj[0].request_by?.attributes?.username 
                    : dataLoad[0].job_obj[0].request_by?.username ? dataLoad[0].job_obj[0].request_by?.username : '-'
                )

                if(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date){
                    let currentDate = dayjs(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date);
                    if(!currentDate.isValid()){
                        currentDate = dayjs(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date , 'DD/MM/YYYY')
                    }
                    const parsedDate = dayjs(currentDate, 'YYYY-MM-DD');
                    const daysToAdd = dataLoad[0]?.category_detail_obj[0] ? dataLoad[0]?.category_detail_obj[0]?.warantee : 0;
                    const newDate = parsedDate.add(daysToAdd, 'day');
                    // SetWarantyExpire(dayjs(newDate).format("YYYY-MM-DD"))
                    SetWarantyExpire(dayjs(newDate).format("DD/MM/YYYY") == "Invalid Date" ? "-" : dayjs(newDate).format("DD/MM/YYYY"))
                }

                if (dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date == "") {
                    SetWarantyExpire("-")
                }
                
                // Set รายละเอียดการแจ้งซ่อม
                SetZoneId(dataLoad ? dataLoad[0].zone_obj.length > 0 ? dataLoad[0].zone_obj[0]._id : '-' : '-')
                SetZoneName(dataLoad ? dataLoad[0].zone_obj.length > 0 ? dataLoad[0].zone_obj[0].zone_name : '-' : '-')
                SetCateId(dataLoad ? dataLoad[0].category_obj.length > 0 ? dataLoad[0].category_obj[0]._id : '-' : '-')
                SetCateName(dataLoad ? dataLoad[0].category_obj.length > 0 ? dataLoad[0].category_obj[0].category_name : '-' : '-')
                SetCateDetailId(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0]._id : '-' : '-')
                SetCateDetailName(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0].category_detail_name : '-' : '-')
                SetWarantyDate(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0]?.category_detail_obj[0]?.warantee : '-' : '-')
                SetIsWaranty(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0].status : '-' : '-')
                SetRemarkDetail(dataLoad ? dataLoad[0].detail : '-')


                // Data การอนุมัติจากลูกค้า
                SetApproveDate(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].approve ? dataLoad[0].job_obj[0].update_date : '-' : '-' : '-')
                SetApproveName(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].approve ? dataLoad[0].job_obj[0].approve.approve_name : '-' : '-' : '-')
                SetApproveType(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].approve ? dataLoad[0].job_obj[0].approve.approve_user_type : '-' : '-' : '-')
                SetApproveUrl(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].approve ? dataLoad[0].job_obj[0].approve.sign : '-' : '-' : '-')
                SetResultApprove(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].approve ? dataLoad[0].job_obj[0].approve.remark : '-' : '-' : '-')


                // SetSlipImg(dataLoad[0] ? dataLoad[0]?.job_obj[0].slip : undefined)
                SetSlipImg(dataLoad[0] ? dataLoad[0].job_obj.length > 0 ? dataLoad[0]?.job_obj[0].slip : undefined : undefined)


                const fileList_EN: UploadFile[] =
                    dataLoad && dataLoad[0]?.pic?.length > 0 ? dataLoad[0]?.pic.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: 'detail_' + v.id,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];
                    
                setImgEn({
                    name: 'files',
                    multiple: true,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.pic?.length > 0 ? [...fileList_EN] : [],
                })
                form.resetFields(["pic2"]);

                const fileListVid:any= []
                if (dataLoad[0]?.vdo?.id && dataLoad[0]?.vdo?.url) {
                    fileListVid.push({
                      id: dataLoad[0]?.vdo?.id,
                      uid: String(dataLoad[0]?.vdo?.id),
                      name: 'video',
                      status: "done",
                      url: dataLoad[0]?.vdo?.url,
                      thumbUrl: dataLoad[0]?.vdo?.url
                    });
                  }

                setVid({
                    name: 'files',
                    multiple: false,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.vdo  ? [...fileListVid] : [],
                })
                form.resetFields(["pic"]);


                // ************************************
                // ******** Set รูปช่าง *******
                const fileList_Inspec: UploadFile[] =
                    dataLoad?.length > 0 && dataLoad[0]?.pic_technician?.length > 0 ? dataLoad[0]?.pic_technician.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: 'picture_' + v.id,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];

                setImgInspec({
                    name: 'files',
                    multiple: true,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.pic_technician?.length > 0 ? [...fileList_Inspec] : [],
                })
                form.resetFields(["pic_inspec"]);

                // ************************************
                // ******** Set video ที่ช่างอัพเดท *******
                const fileListVidInspec:any= [] 

                if(dataLoad[0]?.vdo_technician){
                    if (dataLoad[0]?.vdo_technician.id && dataLoad[0]?.vdo_technician.url) {
                        fileListVidInspec.push({
                            id: dataLoad[0]?.vdo_technician.id,
                            uid: String(dataLoad[0]?.vdo_technician.id),
                            name: 'video',
                            status: "done",
                            url: dataLoad[0]?.vdo_technician.url,
                            thumbUrl: dataLoad[0]?.vdo_technician.url
                        });
                    }
    
                    setVidInspec({
                        name: 'files',
                        multiple: false,
                        // defaultFileList: [...fileList3],
                        defaultFileList: dataLoad[0]?.vdo_technician  ? [...fileListVidInspec] : [],
                    })
                    form.resetFields(["vid_inspec"]);
                }


            });
    }

    console.log('dataSource', dataSource)

    const getDataTrail = async ()=>{
        // http://localhost:10000/api/majorcare/repairJobTrail/findRepairTrailByJob?jid=64a57929c65706886187b7ec
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobTrailMDPC/findRepairTrailByJob?jid=${job_id}`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad trail', dataLoad.reverse())

                let dataTrail:any = dataLoad.reverse()
                // dataSource.push(dataLoad[0])
                console.log('dataTrail', dataTrail)

                for (let index = 0; index < dataTrail.length; index++) {
                    dataSourceTrail.push(dataTrail[index])   
                }
                
                // Set สถานะการทำงาน
                setLastTrail(dataTrail ? dataTrail[0].status_name : '-')
                setLastTrailDate(dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm") : '-')
                // Set ประวัติ

                 // Set Stat Job
                 setStat({
                    "value" : dataTrail[0].status,
                    "label" : dataTrail[0].status_name
                })
            });
    }

    console.log('dataSourceTrail', dataSourceTrail)
    const getRepairZone = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairZone`,
        { headers: {  } }).then((res) => {
            let dataZone: any = res?.data;
            // console.log('dataZone', dataZone)
            SetDataZone(dataZone);
        });
    }

    const getRepairCategory = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategory`,
        { headers: {  } }).then((res) => {
            let dataCate: any = res?.data;
            // console.log('dataCate', dataCate)
            SetDataCategory(dataCate);
        });
    }

    const getRepairCateDetail = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategoryDetail`,
        { headers: {  } }).then((res) => {
            let dataCateDetail: any = res?.data;
            // console.log('dataCateDetail', dataCateDetail)
            SetDataCatDetail(dataCateDetail);
        });
    }

    const colTrail:any = [
        {
            title: 'วันที่ทำรายการ',
            dataIndex: 'item_name',
            key: 'item_name',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                // console.log('record',record)
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YYYY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ผู้ทำรายการ',
            // dataIndex: 'name',
            key: 'name',
            // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_by ? record.create_by.first_name + ' ' + record.create_by.last_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'สถานะ',
            dataIndex: 'createBy',
            key: 'createBy',
            render: (val:any, record:any) => {
                return (
                    <>
                        <Tag>{record.status_name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'สถานะย่อย',
            dataIndex: 'sub_stat',
            key: 'sub_stat',
            render: (val:any, record:any) => {
                console.log('record xxxxx', record)
                return (
                    <>
                        {
                            record.sub_status_name ? 
                                <Tag>{record.sub_status_name}</Tag>
                            :
                                <>-</>
                        }
                    </>
                );
            },
        },
        {
            title: 'SLA',
            dataIndex: 'sla',
            key: 'sla',
            render: (value:any, record:any) => {

                {
                    if(record.sla && record.sla.sla_time > 60){
                        // console.log('over 60')
                        const hours = Math.floor(record.sla.sla_time / 60);
                        const days = Math.floor(hours / 24);
                        // console.log(`${record.sla.sla_time} minutes is equal to ${days} days and ${hours % 24} hours`);
                        sla_days = days
                        // sla_hours = hours
                        sla_hours = hours % 24

                    }
                }
                return (
                    
                    <>
                        {
                            record.sla && record.sla.sla_time > 60 ?
                                <div>
                                    {
                                        sla_days > 0 ?
                                            <div>{sla_days} วัน {sla_hours} ชั่วโมง</div>
                                        :
                                            <div> {sla_hours} ชั่วโมง</div>
                                    }
                                </div>
                            :
                                <div>{record ? record.sla ? record.sla.sla_time !== '-' && record.sla.sla_time !== '' ? record.sla.sla_time  + ' นาที' : '-' : '-': '-'}</div>
                        }
                    </>
                );
            },
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'remark',
            key: 'remark',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.remark ? record.remark : '-': '-'}
                        {
                            record?.quotation ?
                                record?.quotation?.file?.length > 0 ?
                                    record?.quotation?.file?.map((item: any, key: any) => {
                                        return <>
                                                <a href={item.url} download={"quotation_" + key} target='_blank'>
                                                    <Button style={{marginLeft:'5px', color:'green'}} icon={<Icons.DownloadOutlined />}></Button>
                                                </a>
                                            </>
                                    })
                                :
                                    <></>
                            : <></>
                        }
                        </div>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
        getRepairZone();
        getRepairCategory();
        getRepairCateDetail();
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-setting-mdpcs",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    console.log('PropertyIdJob', PropertyIdJob)

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "role_management][id]",
                operator: "eq",
                value: 23 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const FindInspectorUserId = async (e:any) => {
        // console.log('e', e);
    
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        // console.log('k', k)
        let inspector_profile = k?.data?.data;
        // console.log('inspector_profile', inspector_profile[0].attributes.user_profile?.data.attributes)
        // console.log('inspector_profile[0].attributes.user_profile?.data.id', inspector_profile[0].attributes.user_profile?.data.id)
        SetInspector(inspector_profile[0].attributes.user_profile?.data.id)
        SetInspectorProfile(inspector_profile)
        // Inspector.push(inspector_profile[0].attributes.user_profile?.data.attributes);
        // InspectorId.push(inspector_profile[0].attributes.user_profile?.data.id);
        // SetInspector(Inspector);

        // setTimeout(() => {
        //     form.resetFields(["Inspector"]);
        // }, 500);

        // console.log('Inspector', Inspector)
        // console.log('IdFilter', IdFilter)
    }

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, type: any) => {
        console.log('dateString', dateString)

        if(type == "start"){
            setAppointStart(dateString);
        }else{
            setAppointEnd(dateString);
        }
      
    };

    const onChangeRemark = (e: any) => {
        SetRemark(e.target.value);
    }

    async function updateJobAndTrail(){

        // console.log('Stat.value', Stat.value)
        // console.log('Stat.label', Stat.label)
        // console.log('InspectorProfile', InspectorProfile[0])
        // console.log('AppointStart', AppointStart)
        // console.log('AppointEnd', AppointEnd)
        // console.log('SubStat', SubStat)
        // console.log('Remark', Remark)

        // SET QUOTATION DATA
        let quo_data:any
        if(Stat.value == "64ad1085f3440e02a3156fa6"){ // ส่งใบเสนอราคา
            quo_data = {
                "file": vQuoId.length > 0 ? vQuoId : null,
                "price": Price !== 0 ? Price.toString() : ""
            }
        }else{
            quo_data = {
                "file": null,
                "price": ""
            }
        }

        // ****************** 1. UPDATE JOB with Selected stat ******************
        // **********************************************************************
        // let data = {
        //     "status_id": Stat.value,  
        //     "status_name": Stat.label, 
        //     // "assign": InspectorProfile ? InspectorProfile[0] : null,
        //     // "appoint_start": AppointStart ? AppointStart : null,
        //     // "appoint_end": AppointEnd ? AppointEnd : null ,
        //     "hod_approve": IsHodApprove,
        //     "hod_status": {
        //         "hod_id": '',
        //         "hod_name": '',
        //         "hod_app_status": '', // A = approve, R = not approve 
        //         "hod_remark": '',
        //         "create_date": ''
        //     },
        //     "quotation": quo_data

        // };
        let data:any
        if(Stat.value == "64ad1085f3440e02a3156fa6"){ // ส่งใบเสนอราคา
            data = {
                "status_id": Stat.value,  // รับเรื่องแจ้งซ่อม
                "status_name": Stat.label,  // รับเรื่องแจ้งซ่อม
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "hod_approve": IsHodApprove,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                },
                "quotation": quo_data
            };
        }else{
            data = {
                "status_id": Stat.value,  // รับเรื่องแจ้งซ่อม
                "status_name": Stat.label,  // รับเรื่องแจ้งซ่อม
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "hod_approve": IsHodApprove,
                "hod_status": {
                    "hod_id": '', 
                    "hod_name": '', 
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            };
        }

        // console.log('data', data)
        let res = await axios.patch(majorCareUrl + "/majorcare/repairJobMDPC/updateRepairjob?id=" + job_id, data, { headers: { } });

        // ****************** 2. CREATE TRAIL ******************
        // *****************************************************
        // let dataTrail = {
        //     "repair_job": job_id,
        //     "status": Stat.value,
        //     "status_name": Stat.label,
        //     "sub_status": SubStat !== undefined ? SubStat.value : null,
        //     "sub_status_name": SubStat !== undefined ? SubStat.label : null,
        //     // "appoint_start": AppointStart ? AppointStart : null,
        //     // "appoint_end": AppointEnd ? AppointEnd : null ,
        //     // "assign": InspectorProfile ? InspectorProfile[0] : null,
        //     "sla": {
        //         "sla": "-",
        //         "sla_time": "-"
        //     },
        //     "remark": Remark !== undefined ? Remark : "",
        //     "create_by": Identity_data?.user_profile,
        //     "hod_approve": IsHodApprove,
        //     "hod_status": {
        //         "hod_id": '',
        //         "hod_name": '',
        //         "hod_app_status": '', // A = approve, R = not approve 
        //         "hod_remark": '',
        //         "create_date": ''
        //     },
        //     "quotation": quo_data
        // }
        let dataTrail:any
        if(Stat.value == "64ad1085f3440e02a3156fa6"){ // ส่งใบเสนอราคา
            dataTrail = {
                "repair_job": job_id,
                "status": Stat.value,
                "status_name": Stat.label,
                "sub_status": SubStat !== undefined ? SubStat.value : null,
                "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                // "remark": Remark !== undefined ? Remark : "",
                "remark": RemarkStat !== undefined ? RemarkStat : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": IsHodApprove,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                },
                "quotation": quo_data
            }
        }else{
            dataTrail = {
                "repair_job": job_id,
                "status": Stat.value,
                "status_name": Stat.label,
                "sub_status": SubStat !== undefined ? SubStat.value : null,
                "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                // "remark": Remark !== undefined ? Remark : "",
                "remark": RemarkStat !== undefined ? RemarkStat : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": IsHodApprove,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }
        }

        // console.log('dataTrail', dataTrail)
        let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailMDPC", dataTrail, { headers: { } });

        // ====== SEND MAIL TO HOD APPROVE ========
        {/* 
            MAIN STATUS

            64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
            64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
            64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
            64985bcf515b80b78fe13bba = ปิดงาน *

            SUB STAT
            64985c15515b80b78fe13bc4 = ให้คำแนะนำ เพื่อจัดจ้าง
            64985c22515b80b78fe13bc6 = งานที่ไม่อยู่ในเงื่อนไขรับประกัน
            64985c2d515b80b78fe13bc8 = รายละเอียดไม่ตรงกับรายการรับประกัน
            64985c38515b80b78fe13bca = ลูกค้าเลื่อนซ่อม เกิน SLA
            64985c42515b80b78fe13bcc = รอสั่งของ
            64985c4a515b80b78fe13bce = งานซ่อมที่เกิดจากการใช้งาน
            64985c56515b80b78fe13bd0 = งานเคลมประกันภัยอาคาร
        */}

        if(Stat.value == "64985a67515b80b78fe13b96" || Stat.value == "64985ae5515b80b78fe13ba2" || Stat.value == "64985b3b515b80b78fe13bac" ){
            let data_send_mail = {
                "property_id": dataSource[0].job_obj[0].request_by.unitDetail.property_id, 
                "property_name": dataSource[0].job_obj[0].request_by.unitDetail.property_name +'', 
                "unit_no": dataSource[0].job_obj[0].request_by.unitDetail.unit_name +'',
                "waranty_1": dataSource[0].category_detail_obj[0].warantee, // ประกันอุปกรณ์
                "waranty_2": "Yes", // ประกันงานซ่อม

                "owner_name": dataSource[0].job_obj[0].request_by.first_name + " " + dataSource[0].job_obj[0].request_by.last_name +'',
                "contact_name": dataSource[0].job_obj[0].request_by.first_name + " " + dataSource[0].job_obj[0].request_by.last_name +'', 
                "contact_tel": dataSource[0].job_obj[0].request_by.tel +'',
                // "contact_email": dataSource[0].job_obj[0].request_by.tel +'',
                "contact_email": CustomerEmail + '',

                "job_no": dataSource[0].job_obj[0].job_id + '',
                "job_type": "PRIVATE AREA (ภายในห้องชุด)", // PRIVATE AREA (ภายในห้องชุด) / Facility / บริการงานช่าง
                "zone": dataSource[0].zone_obj[0].zone_name,
                "category": dataSource[0].category_obj[0].category_name,
                "category_detail": dataSource[0].category_detail_obj[0].category_detail_name,
                "remark": RemarkStat,
                "case_url": "",
                "send_type": "MDPC", // MAJORCARE, MDPC
                // "approver_name": dataSource[0].job_obj[0].approve.approve_name +'', 
                // "approver_date": dataSource[0].job_obj[0].approve_date +'',
                // "inspector_name": dataSource[0].job_obj[0].assign.attributes.user_profile.data.attributes.first_name + " " + dataSource[0].job_obj[0].assign.attributes.user_profile.data.attributes.last_name,
                // "email": vEmail
            }

            console.log('data_send_mail', data_send_mail)
            // let res_email = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
            let res_email = await axios.post(API_URL + `/like-systems/sendEmailToHodRepairJobMdpc`, data_send_mail , {
                headers: { Authorization: "Bearer " + TOKEN_KEY }
            });

            console.log('res_email', res_email)
        }else if(Stat.value == "64985bcf515b80b78fe13bba"){ // ปิดงาน
            if(SubStat !== undefined){
                if( SubStat.value == "64985c15515b80b78fe13bc4" || 
                    SubStat.value == "64985c22515b80b78fe13bc6" || 
                    SubStat.value == "64985c2d515b80b78fe13bc8" || 
                    SubStat.value == "64985c38515b80b78fe13bca" || 
                    SubStat.value == "64985c42515b80b78fe13bcc" || 
                    SubStat.value == "64985c4a515b80b78fe13bce" || 
                    SubStat.value == "64985c56515b80b78fe13bd0" 
                ){
                    let data_send_mail = {
                        "property_id": dataSource[0].job_obj[0].request_by.unitDetail.property_id, 
                        "property_name": dataSource[0].job_obj[0].request_by.unitDetail.property_name +'', 
                        "unit_no": dataSource[0].job_obj[0].request_by.unitDetail.unit_name +'',
                        "waranty_1": dataSource[0].category_detail_obj[0].warantee, // ประกันอุปกรณ์
                        "waranty_2": "Yes", // ประกันงานซ่อม
        
                        "owner_name": dataSource[0].job_obj[0].request_by.first_name + " " + dataSource[0].job_obj[0].request_by.last_name +'',
                        "contact_name": dataSource[0].job_obj[0].request_by.first_name + " " + dataSource[0].job_obj[0].request_by.last_name +'', 
                        "contact_tel": dataSource[0].job_obj[0].request_by.tel +'',
                        // "contact_email": dataSource[0].job_obj[0].request_by.tel +'',
                        "contact_email": CustomerEmail + '',
        
                        "job_no": dataSource[0].job_obj[0].job_id + '',
                        "job_type": "PRIVATE AREA (ภายในห้องชุด)", // PRIVATE AREA (ภายในห้องชุด) / Facility / บริการงานช่าง
                        "zone": dataSource[0].zone_obj[0].zone_name,
                        "category": dataSource[0].category_obj[0].category_name,
                        "category_detail": dataSource[0].category_detail_obj[0].category_detail_name,
                        "remark": RemarkStat,
                        "case_url": "",
                        "send_type": "MDPC", // MAJORCARE, MDPC
                        // "approver_name": dataSource[0].job_obj[0].approve.approve_name +'', 
                        // "approver_date": dataSource[0].job_obj[0].approve_date +'',
                        // "inspector_name": dataSource[0].job_obj[0].assign.attributes.user_profile.data.attributes.first_name + " " + dataSource[0].job_obj[0].assign.attributes.user_profile.data.attributes.last_name,
                        // "email": vEmail
                    }
        
                    console.log('data_send_mail', data_send_mail)
                    // let res_email = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
                    let res_email = await axios.post(API_URL + `/like-systems/sendEmailToHodRepairJobMdpc`, data_send_mail , {
                        headers: { Authorization: "Bearer " + TOKEN_KEY }
                    });
        
                    console.log('res_email', res_email)
                }
            }
        }

        successNoti();
        replace("/repair-mdpc/repair-mdpc-job")
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };


    function fileChange_quotation(e: any) {
        console.log('event', e)
        if(e.file.status === "removed") {
            console.log('file id', e.file)
            // setQuoId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));
            setQuoId((prev:any) => prev.filter((item:any) => item.id !== e.file.response[0].id));

            if(vQuotation[0].defaultFileList.length == 1){
                setQuotation((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: []
                }));
            }else{
                setQuotation((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
                }));
            }
            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                console.log('res', res)
                setQuoId((pre:any) => ([
                    ...pre,
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setQuotation((pre:any) => ([
                    // ...pre,
                    {
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    const beforeUploadQuo = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf';
        if (!isJpgOrPng) {
            message.error('You can only upload PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('PDF must smaller than 10MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleClick = () => {
        setButtonDisabled(true);
        form.submit()
    };

    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        // console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        setAppointStart(dateString[0])
        setAppointEnd(dateString[1])
    };

    const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        console.log('onOk: ', value);
    };

    return (
        <Edit 
            title="แก้ไข"
            saveButtonProps={{ hidden: true }}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>MDPC แจ้งซ่อม</Breadcrumb.Item>
                    <Breadcrumb.Item>รายการแจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">แก้ไข</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>,
                onBack(e?) {
                    replace("/repair-mdpc/repair-mdpc-job")
                },
            }}
        >
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    updateJobAndTrail();
                }}
            >

                {/* ====================== รายละเอียด ====================== */}
                {/* ======================================================= */}
                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียด
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label="Job no."
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Property"
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="ผู้แจ้ง"
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="วันหมดประกัน" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={WarantyExpire} defaultValue={WarantyExpire} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่แจ้ง"
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YYYY HH:mm")} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Unit" 
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="ประเภท" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่โอน" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>
                                <Form.Item 
                                    label="Email ผู้แจ้ง" 
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={CustomerEmail} defaultValue={CustomerEmail} disabled/>   
                                </Form.Item>
                                <Form.Item 
                                    label="เบอร์ผู้แจ้ง" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={CustomerTelNo} defaultValue={CustomerTelNo} disabled/>   
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                {/* ====================== รายละเอียดการแจ้งซ่อม ====================== */}
                {/* ================================================================ */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียดการแจ้งซ่อม
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="โซน" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Select
                                // onChange={(e:any, record:any) => SetSelectedZone(record)}
                                disabled
                            >
                                {
                                    DataZone?.length > 0 ?
                                        DataZone?.map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                >
                                                    {item.zone_name ? item.zone_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select> */}
                            <Input disabled value={ZoneName} />
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="หมวด" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Select
                                onChange={(e:any, record:any) => { SetSelectedCategory(record)} }
                                disabled
                            >
                                {
                                    DataCategory?.length > 0 ?
                                        DataCategory?.map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                >
                                                    {item.category_name ? item.category_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select> */}
                            <Input disabled value={CateName} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label="รายการ" 
                            // name="details"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Select
                                onChange={(e:any, record:any) => SetSelectedCateDetail(record)}
                                disabled
                            >
                                {
                                    DataCatDetail?.length > 0 ?
                                        DataCatDetail?.filter((item:any) => item.category_name == SelectedCategory?.value).map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                    data={item}
                                                >
                                                    {item.category_detail_name ? item.category_detail_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select> */}
                            <Input disabled value={CateDetailName} />

                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="ประกัน" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <div>{SelectedCateDetail? SelectedCateDetail.data ? SelectedCateDetail.data.warantee + ' วัน' : '-' : '-' }</div> */}
                            {WarantyDate}
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="อยู่ในประกัน" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <div>{SelectedCateDetail? SelectedCateDetail.data ? SelectedCateDetail.data.status == "Y" ? 'ใช่' : 'ไม่ใช่' : '-' : '-' }</div> */}
                            <div>{IsWaranty ? IsWaranty == "Y" ? 'ใช่' : 'ไม่ใช่' : '-' }</div>
                        </Form.Item>
                    </Col>                    
                </Row>

                <Row>
                    <Col span={16}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                // onChange={(e) => SetRemarkDetail(e.target.value)}
                                value={RemarkDetail}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="รูปประกอบ (สูงสุด 10 รูป)"
                            name="pic2"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vImgEn}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={5}
                                // beforeUpload={beforeUpload}
                                // onChange={fileChange_EN}
                                disabled
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="ไฟล์วิดีโอ (1 คลิป)"
                            name="pic"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vVid}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={1}
                                multiple={false}
                                // beforeUpload={beforeUploadVid}
                                // onChange={fileChange_vid}
                                disabled
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                {
                    SlipImg ? SlipImg !== undefined ?
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="หลักฐานการโอนเงิน"
                                    name="slip"
                                >
                                    {
                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                            {

                                                SlipImg?.file?.length > 0 ?
                                                    SlipImg?.file?.map((item: any, key: any) => {
                                                        return <>
                                                            <Image
                                                                width={200}
                                                                src={item.url}
                                                                style={{paddingRight:'5px'}}
                                                            />
                                                        </>
                                                    })       
                                                :
                                                    <>
                                                        <Image
                                                            width={200}
                                                            src=""
                                                            style={{paddingRight:'5px'}}
                                                        />
                                                    </>                                             
                                            }
                                        </div>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    : <></>
                    : <></>
                }

                <Divider />

                {/* ====================== สถานะการทำงาน ====================== */}
                {/* =========================================================== */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            สถานะการทำงาน
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <Tag color="#108ee9">สถานะล่าสุด</Tag>
                    </Col>

                    <Col span={8}>
                        {LastTrail} วันที่ {LastTrailDate}
                    </Col>

                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="สถานะ" 
                            name="status"
                            style={{width:"90%"}}
                            rules={[
                                {
                                    required: true, message: "Please select status"
                                },
                            ]}
                        >

                            {/* 
                                MAIN STATUS

                                649859ca515b80b78fe13b84 = รอดำเนินการ
                                649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
                                649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
                                64985a01515b80b78fe13b8a = เข้าประเมิน
                                64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
                                64985a29515b80b78fe13b8e = ประเมินเสร็จ
                                64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
                                64985a3c515b80b78fe13b92 = เข้าซ่อม
                                64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
                                64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
                                64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
                                64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
                                64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
                                64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
                                64985bcf515b80b78fe13bba = ปิดงาน *
                            */}

                            {/* 
                                SUB STATUS

                                ******* main = เลื่อนนัดจากลูกค้า *******
                                64985a95515b80b78fe13b98 = ลูกค้าติดธุระส่วนตัว
                                64985a9c515b80b78fe13b9a = ลูกค้าเป็นโควิด/กักตัว
                                64985aaa515b80b78fe13b9c = ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน
                                64985ab9515b80b78fe13b9e = ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน
                                64985ac3515b80b78fe13ba0 = ไม่สามารถติดต่อลูกค้าได้


                                ******* main = เลื่อนนัดจากนิติ *******
                                64985b04515b80b78fe13ba4 = ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด
                                64985b0d515b80b78fe13ba6 = ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้
                                64985b17515b80b78fe13ba8 = ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง
                                64985b21515b80b78fe13baa = รอ Major อนุมัติ PO/PR


                                ******* main = อุปกรณ์อยู่ระหว่างการผลิต *******
                                64985b5e515b80b78fe13bae = อุปกรณ์ สุขภัณฑ์
                                64985b68515b80b78fe13bb0 = วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์
                                64985b74515b80b78fe13bb2 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์
                                64985b80515b80b78fe13bb4 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า


                                ******* main = ปิดงาน *******
                                64985be1515b80b78fe13bbc = ส่งงานเสร็จสมบูรณ์
                                64985bec515b80b78fe13bbe = Customer เปิดงานซ้ำ
                                64985bf6515b80b78fe13bc0 = Customer เปิดงานผิด
                                64985c0c515b80b78fe13bc2 = Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า
                                64985c15515b80b78fe13bc4 = ให้คำแนะนำ เพื่อจัดจ้าง
                                64985c22515b80b78fe13bc6 = งานที่ไม่อยู่ในเงื่อนไขรับประกัน
                                64985c2d515b80b78fe13bc8 = รายละเอียดไม่ตรงกับรายการรับประกัน
                                64985c38515b80b78fe13bca = ลูกค้าเลื่อนซ่อม เกิน SLA
                                64985c42515b80b78fe13bcc = รอสั่งของ
                                64985c4a515b80b78fe13bce = งานซ่อมที่เกิดจากการใช้งาน
                                64985c56515b80b78fe13bd0 = งานเคลมประกันภัยอาคาร

                            */}
                            
                            <Select 
                                placeholder={t("select.sel")}
                                style={{ width: '100%' }}
                                onSelect={(value: any, record:any) => {
                                    console.log(value);
                                    // console.log('record', record);
                                    // setStat(value);
                                    setStat(record);

                                    if(value == "64985a67515b80b78fe13b96" || value == "64985ae5515b80b78fe13ba2" || value == "64985b3b515b80b78fe13bac" || value == "64985bcf515b80b78fe13bba"  ){
                                        console.log('hod approve')
                                        SetIsHodApprove(true)
                                    }else{
                                        console.log('not approve')
                                        SetIsHodApprove(false)
                                    }
                                    
                                    SetInspectorProfile(undefined)
                                    setAppointStart(undefined)
                                    setAppointEnd(undefined)

                                }}
                                // options={option_main_status}
                                options={  
                                    Stat && Stat.value == "64985bcf515b80b78fe13bba" ?
                                    option_main_status :
                                    option_main_status_waiting
                                }
                                
                            />
                        </Form.Item>
                    </Col>


                    {
                        Stat !== undefined ?
                        Stat.value == "64985a67515b80b78fe13b96" ||  Stat.value == "64985ae5515b80b78fe13ba2" ||  Stat.value == "64985b3b515b80b78fe13bac" ||  Stat.value == "64985bcf515b80b78fe13bba" ?
                            <Col span={8}>
                                {/* 
                                    4.2.3.กรณีเลือก สถานะที่มี สถานะย่อย ให้แสดงสถานะย่อยให้เลือกด้วย บังคับเลือก
                                    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า   option_cust_postpone
                                    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ          option_juristic_postpone
                                    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต  option_matt_in_manufacturing
                                    64985bcf515b80b78fe13bba = ปิดงาน                option_close_job
                                */}
                                <Form.Item 
                                    label="สถานะย่อย" 
                                    name="sub_status"
                                    style={{width:"90%"}}
                                    rules={[
                                        {
                                            required: true, message: "Please select sub status"
                                        },
                                    ]}
                                >
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            // setSubStat(value);
                                            if( value == "64985c15515b80b78fe13bc4" || 
                                                value == "64985c22515b80b78fe13bc6" || 
                                                value == "64985c2d515b80b78fe13bc8" || 
                                                value == "64985c38515b80b78fe13bca" ||
                                                value == "64985c42515b80b78fe13bcc" ||
                                                value == "64985c4a515b80b78fe13bce" ||
                                                value == "64985c56515b80b78fe13bd0" ||

                                                value == "64985b5e515b80b78fe13bae" ||
                                                value == "64985b68515b80b78fe13bb0" ||
                                                value == "64985b74515b80b78fe13bb2" ||
                                                value == "64985b80515b80b78fe13bb4" ||

                                                value == "64985b04515b80b78fe13ba4" ||
                                                value == "64985b0d515b80b78fe13ba6" ||
                                                value == "64985b17515b80b78fe13ba8" ||
                                                value == "64985b21515b80b78fe13baa" ||

                                                value == "64985a95515b80b78fe13b98" ||
                                                value == "64985a9c515b80b78fe13b9a" ||
                                                value == "64985aaa515b80b78fe13b9c" ||
                                                value == "64985ab9515b80b78fe13b9e" ||
                                                value == "64985ac3515b80b78fe13ba0"
                                            ){
                                                console.log('hod approve')
                                                SetIsHodApprove(true)
                                            }else{
                                                console.log('not approve')
                                                SetIsHodApprove(false)
                                            }

                                            setSubStat(record);
                                        }}
                                        options={
                                            Stat.value == "64985a67515b80b78fe13b96" ? option_cust_postpone
                                            : Stat.value == "64985ae5515b80b78fe13ba2" ? option_juristic_postpone
                                            : Stat.value == "64985b3b515b80b78fe13bac" ? option_matt_in_manufacturing
                                            : Stat.value == "64985bcf515b80b78fe13bba" ? option_close_job
                                            : null
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        : <></>
                        : <></>
                    }
                    
                </Row>

                {/* STAT ส่งใบเสนอราคา */}
                {/* เปิดช่องใส่ราคา ช่องอัพโหลดใบเสนอราคา */}
                {
                    Stat !== undefined ?
                    Stat.value == "64ad1085f3440e02a3156fa6" ?
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                        label="ราคา" 
                                        name="price"
                                        style={{
                                            width:"90%",
                                        }}
                                        rules={[
                                            {
                                                required: true, message: "Please enter price"
                                            },
                                        ]}
                                    >
                                    <InputNumber 
                                        // onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                                        // value={Waranty} 
                                        // defaultValue={Waranty}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        style={{width:'80%'}} 
                                        min={0} 
                                        type="float" 
                                        onChange={(e:any) => ( 
                                            SetPrice(e)
                                            // form.setFieldsValue({price : Price}) 
                                        )}
                                    ></InputNumber>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="แนบไฟล์ใบเสนอราคา (only .PDF size 10 MB)" 
                                    name="quotation_file"
                                    style={{
                                        width:"95%",
                                    }}
                                    rules={[
                                        {
                                            required: true, message: "Please upload quotation"
                                        },
                                    ]}
                                >
                                    <Upload.Dragger 
                                        style={{ borderRadius: "10px" }}
                                        // {...vQuotation}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        // maxCount={1}
                                        multiple={true}
                                        beforeUpload={beforeUploadQuo}
                                        onChange={fileChange_quotation}
                                    >
                                        <p>Upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                            </Col>
                        </Row>
                    : <></>
                    : <></>
                }

                {/* 
                    CONDITION
                    4.2.1.กรณีเลือก สถานะ นัด วัน / เวลา ประเมินหน้างาน จะต้องมี ตั้งแต่วันที ่ให้เลือกวันเวลา , ถึงวันที่ ต้องให้เลือกวัน เวลา และเลือก ช่างผู้รับผิดชอบ
                    4.2.2.กรณีเลือก สถานะ นัด วัน / เวลา เข้าซ่อม จะต้องมี  ตั้งแต่วันที ่ให้เลือกวันเวลา , ถึงวันที่ ต้องให้เลือกวัน เวลา และเลือก ช่างผู้รับผิดชอบ

                    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
                    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
                */}
                {
                    Stat !== undefined ?
                    Stat.value == "649859f8515b80b78fe13b88" || Stat.value == "64985a35515b80b78fe13b90" ?
                        <Row>
                            <Col span={16}>
                                <Form.Item 
                                    label="วันที่นัด" 
                                    name="appointment_date"
                                    style={{
                                        width:"95%",
                                    }}
                                    rules={[
                                        {
                                            required: true, message: "Please select appointment date"
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        style={{width: "100%"}}
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={onChange}
                                        onOk={onOk}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={8}>
                                <Form.Item 
                                        label="วันที่นัด" 
                                        name="appoint_start"
                                        style={{
                                            width:"95%",
                                        }}
                                        rules={[
                                            {
                                                required: true, message: "Please select appointment date"
                                            },
                                        ]}
                                    >
                                    <DatePicker
                                        style={{width: "100%"}}
                                        className="ant-picker-mjc" 
                                        onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "start")} 
                                        format={format}
                                        showTime
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="ถึงวันที่" 
                                    name="appoint_end"
                                    style={{
                                        width:"95%",
                                    }}
                                    rules={[
                                        {
                                            required: true, message: "Please select appointment date"
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        style={{width: "100%"}}
                                        className="ant-picker-mjc" 
                                        onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "end")} 
                                        showTime
                                        format={format}
                                    />
                                </Form.Item>
                            </Col> */}

                            <Col span={8}>
                                <Form.Item 
                                    label="ช่างผู้รับผิดชอบ" 
                                    name="inspector"
                                    style={{
                                        width:"95%", 
                                    }}
                                    rules={[
                                        {
                                            required: true, message: "Please select inspector"
                                        },
                                    ]}
                                >
                                    <Select 
                                        placeholder={t("select.sel")} 
                                        {...selInspector}
                                        onSelect={(value: any, record:any) => {
                                            FindInspectorUserId(value)
                                        }}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    : <></>
                    : <></>
                }
                
            
                {
                    vImgInspec ? <Row>
                        <Col span={8}>
                            <Form.Item
                                label="รูปประกอบ (สูงสุด 10 รูป)"
                                name="pic_inspec"
                            >
                                <Upload.Dragger 
                                    style={{ borderRadius: "10px" }}
                                    {...vImgInspec}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={5}
                                    // beforeUpload={beforeUpload}
                                    // onChange={fileChange_EN}
                                    disabled
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    : <></>
                }

                {
                    vVidInspec ? <Row>
                        <Col span={8}>
                            <Form.Item
                                label="ไฟล์วิดีโอ (1 คลิป)"
                                name="vid_inspec"
                            >
                                <Upload.Dragger 
                                    style={{ borderRadius: "10px" }}
                                    {...vVidInspec}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    multiple={false}
                                    // beforeUpload={beforeUploadVid}
                                    // onChange={fileChange_vid}
                                    disabled
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    : <></>
                }

                <Row>
                    <Col span={18}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                onChange={(e) => SetRemarkStat(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row> 


                {
                    Stat && Stat.value == "64985bc6515b80b78fe13bb8" && <>
                        <Divider />
                        <Row gutter={82} >
                            <Col span={24}>
                                <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                                    การอนุมัติจากลูกค้า
                                </div>
                            </Col>
                        </Row>
        
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่อนุมัติ" 
                                    // name=""
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(ApproveDate).format("DD/MM/YYYY HH:mm")} defaultValue={dayjs(ApproveDate).format("DD/MM/YYYY HH:mm")}  disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item 
                                    label="ผู้อนุมัติ" 
                                    // name=""
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={ApproveName} defaultValue={ApproveName} disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item 
                                    label="ประเภท" 
                                    // name=""
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={ApproveType} defaultValue={ApproveType} disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                    label="ลายเซ็น" 
                                    // name=""
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Image 
                                        style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                        marginLeft: "auto", marginRight: "auto",
                                        // background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                        borderRadius: "10px"}}
                                        src={ApproveUrl} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                }
               



                <Row style={{float: 'right',marginTop: "1.2rem",marginBottom: "1.2rem"}}>
                    {/* <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button> */}
                    <Button type="primary" onClick={() => handleClick()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled}>Save</Button>

                </Row>

                <Divider />

                {/* ====================== ประวัติการทำรายการ ====================== */}
                {/* ============================================================== */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            ประวัติการทำรายการ
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Table 
                            columns={colTrail} 
                            dataSource={dataSourceTrail.length > 0 ? dataSourceTrail : null}
                        />
                    </Col>
                </Row>
            </Form>
    </Edit>   
    );
};