/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, Icon, notification, ShowButton, Tooltip, useForm } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { majorCareUrl } from "../../../constants";
import { CheckCircleOutlined, QuestionOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from 'antd/es/table';

let swLocal : boolean = true;
let dataonFilter: any = [];
export const MjcNewJob: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { formProps } = useForm<any,HttpError,{}>()
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const { edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(0);
    const [dataList, setDataList] = useState<any>([]);

    const [dataChecklist, setDataCheckList] = useState<any>([]);
    const dataBlank:any = []
    
    const [keepJobNo, setKeepJobNo] = useState<any>()

    const getDataK = async ()=>{

        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findHomecheckjobByStat`,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad', dataLoad)
                // console.log('prop_id', prop_id)

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.request_by?.unitDetail?.property_id == Number(prop_id))
                }


                let xx = dataLoad.filter((e:any)=> e.status == "6485ba66049e59df2850eb9e")
                // let xx = dataLoad.filter((e:any)=> e.status !== "xxxx")
                for (let index = 0; index < xx.length; index++) {
                    dataList.push(xx[index]);
                }
            });

    }
    // console.log('dataList', dataList)
    const { confirm } = Modal;
    const successNoti = (stat:any, msg:any) => {
        notification.success({
            // message: stat + ' : ' + msg.TH,
            message: msg,
            // description: msg,
            duration: 3,
        });
    };

    function showAcknowledge(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'ยืนยันการรับเรื่องแจ้งซ่อม', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateAcknowledge(id, "ยืนยันการรับเรื่องแจ้งซ่อม");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    async function updateAcknowledge(id:any, event:any){
        console.log('id : ', id)

        // ================================= CAL SLA ======================
        // ถ้า sla time ไม่เกินที่กำหนด 24 ชั่วโมง ขึ้น Y ถ้าเกิน N
        // เอาเวลาที่กด รับเรื่อง - วันที่สร้าง log 1 คิดมาเป็นจำนวนนาที

        // 1 วันมี 1440 นาที
        let presentDateTime:any = dayjs().format("YYYY-MM-DD HH:mm:ss")

        let SlaVal = 'Y'    // Y = ไม่เกิน      N = เกิน
        let SlaTime = "0"   
        let TrailDate:any

        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/findTrailByJob?jid=` + id ,
            { headers: {  } }).then((res) => {
                let dataTrail: any = res?.data;
                console.log('dataTrail', dataTrail[0])
                TrailDate = dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm:ss") : dayjs().format("YYYY-MM-DD HH:mm:ss")
            
                console.log('TrailDate', TrailDate)
                console.log('presentDateTime', presentDateTime)

                const startDateTime = dayjs(TrailDate);
                const endDateTime = dayjs(presentDateTime);
                const diffInMinutes = endDateTime.diff(startDateTime, 'minute');
                // console.log(diffInMinutes);

                if(diffInMinutes > 1440 ){ // ถ้าเกิน 1 วัน
                    SlaVal = 'N'
                }
                SlaTime = diffInMinutes.toString()

            });

        // ======================================================================

        // และช่อง เงื่อนไขการรับประกัน ให้เช็คจากข้อมูล
        // template ที่ใช้ โดยเอาวันที่ปัจจุบัน - วันที่โอนบ้าน
        // แล้วเอาไปเทียบกับจำนวนวันที่ระบุไว้ว่าประกันกี่วัน
        // ถ้าจำนวนวันเกิน แสดง ไม่อยู่ในประกัน
        // จำนวนวันไม่เกิน แสดง อยู่ในประกัน

        const presentDate = dayjs().format("YYYY-MM-DD");  // วันที่ปัจจุบัน
        // const transferDate = dayjs('2022-01-10').format("YYYY-MM-DD"); // transfer date
        // console.log('presentDate', presentDate)
        // const diffInDays = dayjs(presentDate).diff(transferDate, 'day');
        // console.log(diffInDays); // Output: 9

        // ================================= UPDATE is_warranty =================================
        console.log('-----> id : ',id);
        let res_data_checklist = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckjobChecklist?jid=` + id , 
            { headers: {  } }).then((res) => {
                var dataLoad: any = res?.data;
                console.log('dataLoad Checklist', dataLoad)
                let xx = dataLoad.filter((e:any)=> e.type == "CHECKLIST");
                for (let index = 0; index < xx.length; index++) {
                    dataChecklist.push(xx[index]);
                }
            });

        console.log('dataChecklist', dataChecklist)

        // let data_trail = dataList.filter((e:any)=> e._id == "6485bcf9049e59df2850eba2");
        //if not check error
        if(dataChecklist.length > 0 ) {
            let data_trail = dataList.filter((e:any)=> e._id == dataChecklist[0].home_check_job);
            let transferDate = data_trail.request_by ? dayjs(data_trail.request_by.unitDetail.transfer_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
            
            // let test_date = dayjs('2022-12-12').format("YYYY-MM-DD")
            const diffInDays = dayjs(presentDate).diff(transferDate, 'day');
    
            for (let index = 0; index < dataChecklist.length; index++) {
                console.log('diffInDays', diffInDays)
                console.log('waranty', dataChecklist[index].warantee)
                if(diffInDays > dataChecklist[index].warantee){ // ถ้าวันที่ cal มากกว่า เปลี่ยนเป็นหมดประกัน
                    let data = {
                        "is_warantee": false,
                    }
                    let res_data_img = await axios.put(
                        majorCareUrl + `/majorcare/updateHomecheckjobChecklist?id=` + dataChecklist[index]._id , data ,
                        { headers: {  } }).then((res) => {
                            // console.log('updated checklist', res)
                        }
                    );
                }
            }
        }
        

        // ================================= UPDATE STAT ======================
        let data = {
            "status": "6485bcda049e59df2850eba0",  // รับเรื่องแจ้งซ่อม
            "sla": {
                "sla": SlaVal,
                "sla_time": SlaTime,
            }
        };
        let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + id, data, { headers: { } });
        // console.log('res', res)

        let dataTrail = {
            "home_check_job" : id,
            "status": "6485bcda049e59df2850eba0", // รับเรื่องแจ้งซ่อม
            "appoint_start": "",
            "appoint_end": "",
            // "assign": {
            //     "name":"",
            //     "lastname":""
            // },
            "sla": {
                "sla": SlaVal,
                "sla_time": SlaTime
            },
            "remark": "",
            "create_by": Identity_data?.user_profile,
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        console.log('res_trail', res_trail)

        setCountInterval(0)
        setDataList([])

        successNoti('200', 'success');
    }

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 0){
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    // useEffect(() => {
    //     getDataK();
    // }, []);

    async function updateStatus(id:any, val:any){
        // console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            majorCareUrl + `/majorcare/updateHomecheckjob?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:ColumnsType<any> = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.job-no")}</span>,
            // dataIndex: 'job_id',
            key: 'job_id',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.job_id.localeCompare(b?.job_id),
            render: (value:any, record:any) => {
                //console.log("recoed",record)
                return (
                    <>
                        <div>{record?.job_id ? record?.job_id : '--'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.property")}</span>,
            // dataIndex: 'name',
            //key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.property_obj[0]?.property_name.localeCompare(b?.property_obj[0]?.property_name),
            render: (value:any, record:any) => {
                //console.log("recoed",record)
                return (
                    <>
                        <div>{record ? record.property_obj.length > 0 ? record.property_obj[0].property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.unit-no")}</span>,
            // dataIndex: 'unit',
            // key: 'unit',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.unit_obj[0]?.unit_name.localeCompare(b?.unit_obj[0]?.unit_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.unit_obj.length > 0 ? record.unit_obj[0].unit_name : '-': '-'}</div>
                        {/* <div>{record ? record.unit.attributes !== undefined ? record.unit.attributes.unit_name : '-': '-'}</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.customer-name")}</span>,
            // dataIndex: 'updateBy',
            //key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.request_by?.attributes?.first_name.localeCompare(b?.request_by?.attributes?.first_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.request_by.attributes !== undefined ? record.request_by.attributes.first_name + " " + record.request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.trans-date")}</span>,
            // dataIndex: 'transfer_date',
            // key: 'transfer_date',
            align: 'center',
            sorter: (a:any, b:any) => a?.request_by?.unitDetail?.transfer_date.localeCompare(b?.request_by?.unitDetail?.transfer_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.request_by ? record.request_by.unitDetail ? dayjs(record.request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.status")}</span>,
            // dataIndex: 'status',
            // key: 'status',
            align: 'center',
            sorter: (a:any, b:any) => a?.status.localeCompare(b?.status),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>
                            {
                                record ? 
                                    record.status !== null ?
                                        // <Tag>รอดำเนินการ</Tag> 
                                        <Tag className="tag_mjc_stat"><TextField value={'รอดำเนินการ'}  /></Tag>

                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        // {
        //     title: 'SLA',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: (value:any) => {
        //         return (
        //             <>
        //                 {/* {
        //                     calSla ? 
        //                         <div style={{color:"green"}}>On Time</div>
        //                     :
        //                         <div style={{color:"red"}}>Over Time</div>

        //                 } */}
        //                 <div>-</div>
        //             </>
        //         );
        //     },
        //     // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        // },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.create-by")}</span>,
            // dataIndex: 'createBy',
            // key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div >{record ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.created-date")}</span>,
            // dataIndex: 'create_date',
            // key: 'create_date',
            align: 'center',
            width: 150,
            sorter: (a:any, b:any) => a?.create_date.localeCompare(b?.create_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.update-by")}</span>,
            // dataIndex: 'updateBy',
            // key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : "-"}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.last-update")}</span>,
            // dataIndex: 'update_date',
            // key: 'update_date',
            align: 'center',
            width: 150,
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.accept-repair-case")}</span>,
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 180,
            // fixed: "right",
            render: (value:any, record:any) => {
                // console.log('record xxx', record)
                return (
                        record.is_active === true ? 
                            record.is_checklist_empty == true ?
                                <>
                                    <div 
                                        style={{
                                            textAlign: 'center',
                                            verticalAlign: 'center',
                                            // lineHeight: '50px',
                                            fontSize:'12px',
                                        }}
                                    >
                                        <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { }} disabled/>
                                        <br />
                                        รอดำเนินการ
                                    </div>
                                </>
                            :
                                <>
                                    <div 
                                        style={{
                                            textAlign: 'center',
                                            verticalAlign: 'center',
                                            // lineHeight: '50px',
                                            fontSize:'12px',
                                        }}
                                    >
                                        <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { showAcknowledge(record._id) }} />
                                        <br />
                                        รอดำเนินการ
                                    </div>
                                </>
                        :
                                <>
                                    <div 
                                        style={{
                                            textAlign: 'center',
                                            verticalAlign: 'center',
                                            // lineHeight: '50px',
                                            fontSize:'12px',
                                        }}
                                    >
                                        <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { }} disabled/>
                                        <br />
                                        รอดำเนินการ
                                    </div>
                                </>
                )
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 120,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record.is_active === true ? 
                                <>
                                    {
                                        record.is_checklist_empty == true &&
                                        <Tooltip title="No template">
                                            <WarningOutlined style={{ color: "#FFE58F", fontSize: '20px', background: 'white', padding: '4px', }} />
                                        </Tooltip>

                                    }
                                    <ShowButton
                                        hideText
                                        size="small"
                                        onClick={() => edit("mjc-job-newjob", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    />
                                    {/* <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record._id) }} 
                                        danger
                                    ></Button> */}
                                    
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record._id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const searchData = () => {
        let newData = dataList
        //console.log("keepJobNoNewData",newData)
        let filter = newData.filter((e:any) => e?.job_id.includes(keepJobNo.toUpperCase()));
        //console.log("filter",filter)
        setDataList(filter)
    }

    const onReset = () => {
        setKeepJobNo(null)
        formProps?.form?.resetFields();
        refreshDataSource()
    }

    return <>
        <List title="">
            <Row>
                <Col span={18}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                        {...formProps}
                    >
                        <Form.Item name="Job_no" >
                            <Input
                                onChange={(e) => setKeepJobNo(e.target.value)}
                                placeholder="Search job" 
                                prefix={<Icons.SearchOutlined />} 
                                allowClear
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => searchData()} type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <div>
                {
                    dataList.length > 0 ?
                        <Table 
                            // dataSource={dataList} 
                            dataSource={dataList.length > 0 ? dataList : dataBlank} 

                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ 'bottomRight'] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2000 }}
                        />
                    :
                        <Table  
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ 'bottomRight'] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2000 }}
                        />
                }
            </div>
            <Row style={{float:'right', paddingTop:'10px'}}>
                <Col span={24} >
                    {t("repair-stat.title")} {dataList?.length}
                </Col>
            </Row>
        </List>
    </>
}