/* eslint-disable */
import { Breadcrumb, Button, Col, Divider, Edit, Form, Icons, Input, List, RcFile, Row, Select, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useList, useNavigation, useTranslate } from "@pankod/refine-core";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";

import dayjs from "dayjs";

import { DatePicker, message, notification, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { majorCareUrl } from "../../../constants";

{/* 
    MAIN STATUS

    649859ca515b80b78fe13b84 = รอดำเนินการ
    649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
    64985a01515b80b78fe13b8a = เข้าประเมิน
    64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
    64985a29515b80b78fe13b8e = ประเมินเสร็จ
    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
    64985a3c515b80b78fe13b92 = เข้าซ่อม
    64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
    64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
    64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
    64985bcf515b80b78fe13bba = ปิดงาน *
*/}

 {/* 
    SUB STATUS
    
    ******* main = เลื่อนนัดจากลูกค้า ******* option_cust_postpone
    64985a95515b80b78fe13b98 = ลูกค้าติดธุระส่วนตัว
    64985a9c515b80b78fe13b9a = ลูกค้าเป็นโควิด/กักตัว
    64985aaa515b80b78fe13b9c = ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน
    64985ab9515b80b78fe13b9e = ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน
    64985ac3515b80b78fe13ba0 = ไม่สามารถติดต่อลูกค้าได้


    ******* main = เลื่อนนัดจากนิติ ******* option_juristic_postpone
    64985b04515b80b78fe13ba4 = ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด
    64985b0d515b80b78fe13ba6 = ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้
    64985b17515b80b78fe13ba8 = ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง
    64985b21515b80b78fe13baa = รอ Major อนุมัติ PO/PR


    ******* main = อุปกรณ์อยู่ระหว่างการผลิต ******* option_matt_in_manufacturing
    64985b5e515b80b78fe13bae = อุปกรณ์ สุขภัณฑ์
    64985b68515b80b78fe13bb0 = วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์
    64985b74515b80b78fe13bb2 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์
    64985b80515b80b78fe13bb4 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า


    ******* main = ปิดงาน ******* option_close_job
    64985be1515b80b78fe13bbc = ส่งงานเสร็จสมบูรณ์
    64985bec515b80b78fe13bbe = Customer เปิดงานซ้ำ
    64985bf6515b80b78fe13bc0 = Customer เปิดงานผิด
    64985c0c515b80b78fe13bc2 = Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า
    64985c15515b80b78fe13bc4 = ให้คำแนะนำ เพื่อจัดจ้าง
    64985c22515b80b78fe13bc6 = งานที่ไม่อยู่ในเงื่อนไขรับประกัน
    64985c2d515b80b78fe13bc8 = รายละเอียดไม่ตรงกับรายการรับประกัน
    64985c38515b80b78fe13bca = ลูกค้าเลื่อนซ่อม เกิน SLA
    64985c42515b80b78fe13bcc = รอสั่งของ
    64985c4a515b80b78fe13bce = งานซ่อมที่เกิดจากการใช้งาน
    64985c56515b80b78fe13bd0 = งานเคลมประกันภัยอาคาร

*/}

export const MjcRepairMdpcNewJobCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    // console.log('user_data', user_data?.user_profile)
    const format = 'YYYY-MM-DD HH:mm';
    const { Option } = Select;

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);

    const [vVid, setVid] = useState<any>();
    const [vVidId, setVidId] = useState<any>([]);

    const [vImgEn, setImgEn] = useState<any>();
    const [ImgId, SetImgId] = useState<any>([]);

    const token = localStorage.getItem(TOKEN_KEY);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]
    // console.log('item_id', item_id)

    // ******** MAIN STATUS ********
    const option_main_status:any = [
        {
            label: "รอดำเนินการ",
            value: "649859ca515b80b78fe13b84",
        },
        // {
        //     label: "รับเรื่องแจ้งซ่อม",
        //     value: "649859e7515b80b78fe13b86",
        // },
        // {
        //     label: "นัด วัน/เวลา ประเมินหน้างาน",
        //     value: "649859f8515b80b78fe13b88",
        // },
        // {
        //     label: "เข้าประเมิน",
        //     value: "64985a01515b80b78fe13b8a",
        // },
        // {
        //     label: "เข้าประเมิน-เกินกำหนด",
        //     value: "64985a0f515b80b78fe13b8c",
        // },
        // {
        //     label: "ประเมินเสร็จ",
        //     value: "64985a29515b80b78fe13b8e",
        // },
        // {
        //     label: "นัด วัน/เวลา เข้าซ่อม",
        //     value: "64985a35515b80b78fe13b90",
        // },
        // {
        //     label: "เข้าซ่อม",
        //     value: "64985a3c515b80b78fe13b92",
        // },
        // {
        //     label: "เข้าซ่อม-เกินกำหนด",
        //     value: "64985a47515b80b78fe13b94",
        // },
        // {
        //     label: "เลื่อนนัดจากลูกค้า",
        //     value: "64985a67515b80b78fe13b96",
        // },
        // {
        //     label: "เลื่อนนัดจากนิติ",
        //     value: "64985ae5515b80b78fe13ba2",
        // },
        // {
        //     label: "อุปกรณ์อยู่ระหว่างการผลิต",
        //     value: "64985b3b515b80b78fe13bac",
        // },
        // {
        //     label: "รออนุมัติงานซ่อม",
        //     value: "64985bbe515b80b78fe13bb6",
        // },
        // {
        //     label: "อนุมัติงานซ่อม",
        //     value: "64985bc6515b80b78fe13bb8",
        // },
        // {
        //     label: "ส่งใบเสนอราคา",
        //     value: "64ad1085f3440e02a3156fa6",
        // },
        // {
        //     label: "อนุมัติใบเสนอราคา",
        //     value: "64ad1075f3440e02a3156fa5",
        // },
        // {
        //     label: "ไม่อนุมัติใบเสนอราคา",
        //     value: "64ad1091f3440e02a3156fa7",
        // },
        // {
        //     label: "ปิดงาน",
        //     value: "64985bcf515b80b78fe13bba",
        // },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_cust_postpone:any = [
        {
            label: "ลูกค้าติดธุระส่วนตัว",
            value: "64985a95515b80b78fe13b98",
        },
        {
            label: "ลูกค้าเป็นโควิด/กักตัว",
            value: "64985a9c515b80b78fe13b9a",
        },
        {
            label: "ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน",
            value: "64985aaa515b80b78fe13b9c",
        },
        {
            label: "ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน",
            value: "64985ab9515b80b78fe13b9e",
        },
        {
            label: "ไม่สามารถติดต่อลูกค้าได้",
            value: "64985ac3515b80b78fe13ba0",
        },
    ];

    const option_juristic_postpone:any = [
        {
            label: "ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด",
            value: "64985b04515b80b78fe13ba4",
        },
        {
            label: "ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้",
            value: "64985b0d515b80b78fe13ba6",
        },
        {
            label: "ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง",
            value: "64985b17515b80b78fe13ba8",
        },
        {
            label: "รอ Major อนุมัติ PO/PR",
            value: "64985b21515b80b78fe13baa",
        },
    ];

    const option_matt_in_manufacturing:any = [
        {
            label: "อุปกรณ์ สุขภัณฑ์",
            value: "64985b5e515b80b78fe13bae",
        },
        {
            label: "วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์",
            value: "64985b68515b80b78fe13bb0",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์ที่แจ้ง",
            value: "64985b74515b80b78fe13bb2",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า",
            value: "64985b80515b80b78fe13bb4",
        },
    ];

    const option_close_job:any = [
        {
            label: "ส่งงานเสร็จสมบูรณ์",
            value: "64985be1515b80b78fe13bbc",
        },
        {
            label: "Customer เปิดงานซ้ำ",
            value: "64985bec515b80b78fe13bbe",
        },
        {
            label: "Customer เปิดงานผิด",
            value: "64985bf6515b80b78fe13bc0",
        },
        {
            label: "Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า",
            value: "64985c0c515b80b78fe13bc2",
        },
        {
            label: "ให้คำแนะนำ เพื่อจัดจ้าง",
            value: "64985c15515b80b78fe13bc4",
        },
        {
            label: "งานที่ไม่อยู่ในเงื่อนไขรับประกัน",
            value: "64985c22515b80b78fe13bc6",
        },
        {
            label: "รายละเอียดไม่ตรงกับรายการรับประกัน",
            value: "64985c2d515b80b78fe13bc8",
        },
        {
            label: "ลูกค้าเลื่อนซ่อม เกิน SLA",
            value: "64985c38515b80b78fe13bca",
        },
        {
            label: "รอสั่งของ",
            value: "64985c42515b80b78fe13bcc",
        },
        {
            label: "งานซ่อมที่เกิดจากการใช้งาน",
            value: "64985c4a515b80b78fe13bce",
        },
        {
            label: "งานเคลมประกันภัยอาคาร",
            value: "64985c56515b80b78fe13bd0",
        },
    ];

    // const [Inspector, SetInspector] = useState<any>([]); // user-profile
    // Data ส่วนของ รายละเอียดการแจ้ง
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [TransferDateForCal, SetTransferDateForCal] = useState<any>();

    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    // Data แก้ไขสถานะรายการ ทำวันนัด
    const [Stat, setStat] = useState<any>(undefined);
    const [SubStat, setSubStat] = useState<any>(undefined);
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [Inspector, SetInspector] = useState<any>();
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [ vPropertyID, setPropertyID ] = useState<any>([]);
    const [ PropUnder, SetPropUnder ] = useState<any>();
    const [ vPropertyName, setPropertyName ] = useState<any>([]);
    const [ UnitId, setUnitId ] = useState<any>([]);
    const [ UnitName, setUnitName ] = useState<any>();
    const [ vCustomerID, setCustomerID] = useState<any>("");
    const [ CustTel, SetCustTel] = useState<any>("");
    const [ CustType, SetCustType] = useState<any>("");
    const [ CustEmail, SetCustEmail] = useState<any>("");

    // MASTER REPAIR DATA
    const [ DataZone, SetDataZone] = useState<any>([]);
    const [ DataCategory, SetDataCategory] = useState<any>([]);
    const [ DataCatDetail, SetDataCatDetail] = useState<any>([]);
    

    // SELECTED DATA
    const [ SelectedCateDetail, SetSelectedCateDetail] = useState<any>([]);
    const [ SelectedCategory, SetSelectedCategory] = useState<any>();
    const [ SelectedZone, SetSelectedZone] = useState<any>();
    const [ RemarkStat, SetRemarkStat] = useState<any>("");
    const [ RemarkDetail, SetRemarkDetail] = useState<any>("");

    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const [IsWaranty, SetIsWaranty] = useState<any>(true);

    const [ PropType, SetPropType ] = useState<any>();

    // let sla_days:any
    // let sla_hours:any

    let initF : any;
    initF = {
        field       : 'deleteStatus',
        operator    : 'contains',
        value       :  'N',
    };

    // console.log('Inspector', Inspector)
    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        // getRepairStatus();
        getRepairZone();
        getRepairCategory();
        getRepairCateDetail(); // ย้ายไป get ตอน select category
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-setting-mdpcs",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            // console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            // {
            //     field: "role_management][id]",
            //     operator: "eq",
            //     value: 19 // role Inspector
            // },
            // {
            //     field: "property][id]",
            //     operator: "in",
            //     // value: PropertyId ? PropertyId : null
            //     value: PropertyIdJob ? PropertyIdJob : null 

            // },
            // {
            //     field: "user_profile][id]",
            //     operator: "in",
            //     value: InspectorSettingId ? InspectorSettingId : null // role Inspector
            // }
            {
                field: "role_management][id]",
                operator: "eq",
                value: 23 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const FindInspectorUserId = async (e:any) => {    
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        let inspector_profile = k?.data?.data;
        SetInspector(inspector_profile[0].attributes.user_profile?.data.id)
        SetInspectorProfile(inspector_profile)
    }
  
    const handleDatePickerChange = (date: Dayjs | null, dateString: string, type: any) => {
        // console.log('dateString', dateString)
        if(type == "start"){
            setAppointStart(dateString);
        }else{
            setAppointEnd(dateString);
        }
    };

    const getRepairZone = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairZone`,
        { headers: {  } }).then((res) => {
            let dataZone: any = res?.data;
            // console.log('dataZone', dataZone)
            dataZone = dataZone.filter((e:any)=> e.is_active == true && e.property_type.some((type:any) => type.property_type_name_en === PropType));
            // console.log('DataZone', dataZone)
            SetDataZone(dataZone);
        });
    }
    // console.log('PropType', PropType)
    // console.log('DataZone', DataZone)

    const getRepairCategory = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategory`,
        { headers: {  } }).then((res) => {
            let dataCate: any = res?.data;
            // console.log('dataCate', dataCate)
            dataCate = dataCate.filter((e:any)=> e.is_active == true);

            SetDataCategory(dataCate);
        });
    }

    const getRepairCateDetail = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategoryDetail`,
        { headers: {  } }).then((res) => {
            let dataCateDetail: any = res?.data;
            // console.log('dataCateDetail', dataCateDetail)
            SetDataCatDetail(dataCateDetail);
        });
    }

    async function createJobAndTrailMajor(){
        let dataAssign:any
        let data:any

        let userData = vUserData?.data?.find((item:any) => item?.user_profile.id == vCustomerID)
        console.log('userData', userData)
        console.log('userData.user_sub_types', userData.user_sub_types)

        let data_sub_type = {
            "data" : [{
                "attributes": userData.user_sub_type
            }]
        }
        
        let user_profile_data = {
            "first_name": userData ? userData.user_profile.first_name : '-',
            "last_name":userData ? userData.user_profile.last_name : '-' ,
            "gender": userData ? userData.user_profile.gender : '-',
            "nick_name": userData ? userData.user_profile.nick_name : '-',
            "tel": userData ? userData.user_profile.tel : '-',
            "user_sub_types": userData ? data_sub_type : null,
            "email": CustEmail ? CustEmail : '-',
            "username": CustEmail ? CustEmail : '-'
        }

        let MajorProperty = await axios.get(majorCareUrl + "/majorcare/findAllProperty", { headers: { } });
        let FindProp:any = MajorProperty?.data.find((item:any) => item?.property_id == vPropertyID);

        let MajorUnit = await axios.get(majorCareUrl + "/majorcare/findAllUnit", { headers: { } });
        let FindUnit:any = MajorUnit?.data.find((item:any) => item?.unit_id == UnitId);


        // ****************** 1. CREATE JOB ******************
        // ***************************************************
        // 649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
        // 64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
        if(Stat.value == "649859f8515b80b78fe13b88" || Stat.value == "649859f8515b80b78fe13b88"){
            dataAssign = {
                "id": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.id : null,
                "first_name":InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.first_name : null,
                "last_name": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.last_name : null,
                "tel": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.tel : null,
                "email": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.email : null,
            }
            data = {
                "status_id": Stat.value,
                "status_name": Stat.label,
                "sub_status_id": SubStat !== undefined ? SubStat.value : '',
                "sub_status_name": SubStat !== undefined ? SubStat.label : '',
                "is_major": true,
                "is_delegate": true,  // admin create job == true
                "property": FindProp ? FindProp._id : '',
                "unit": FindUnit ? FindUnit._id : '',
                "assign": InspectorProfile ? dataAssign : null,
                "appoint_start": AppointStart ? AppointStart : "",
                "appoint_end": AppointEnd ? AppointEnd : "",
                "approve": {},
                "eva_form": [],
                "create_by": user_data?.user_profile,
                "tc": {},
                "request_by": {
                    // "attributes": userData ? userData.user_profile : null,
                    // "id": userData?.id,
                    "id": userData?.user_profile?.id,
                    "first_name": userData ? userData?.user_profile.first_name : '-',
                    "last_name":userData ? userData?.user_profile.last_name : '-' ,
                    "gender": userData ? userData?.user_profile.gender : '-',
                    "nick_name": userData ? userData?.user_profile.nick_name : '-',
                    "tel": userData ? userData?.user_profile.tel : '-',
                    // "email": userData ? userData?.email : '-',
                    "email": CustEmail ? CustEmail : '-',
                    "attributes": userData ? user_profile_data : null,
                    "unit_detail": {
                        "unit_id": UnitId,
                        "unit_name": UnitName,
                        "unit_name_en": UnitName,
                        "unit_number": UnitName,
                        "transfer_date": TransferDate ? TransferDate : '',
                        "unit_type": '',
                        "property_id": vPropertyID,
                        "property_name": vPropertyName
                    }
                },
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            };
        } else {
            data = {
                "status_id": Stat.value,
                "status_name": Stat.label,
                "sub_status_id": SubStat !== undefined ? SubStat.value : '',
                "sub_status_name": SubStat !== undefined ? SubStat.label : '',
                "is_major": true,
                "is_warantee": true,
                "is_delegate": true,  // admin create job == true
                "property": FindProp ? FindProp._id : '',
                "unit": FindUnit ? FindUnit._id : '',
                "assign": {},
                "appoint_stat": "",
                "appoint_end": "",
                "approve": {},
                "eva_form": [],
                "create_by": user_data?.user_profile,
                "tc": {},
                "request_by": {
                    "id": userData?.id,
                    "first_name": userData ? userData?.user_profile.first_name : '-',
                    "last_name":userData ? userData?.user_profile.last_name : '-' ,
                    "gender": userData ? userData?.user_profile.gender : '-',
                    "nick_name": userData ? userData?.user_profile.nick_name : '-',
                    "tel": userData ? userData?.user_profile.tel : '-',
                    // "email": userData ? userData?.email : '-',
                    "email": CustEmail ? CustEmail : '-',
                    "attributes": userData ? user_profile_data : null,
                    "unitDetail": {
                        "unit_id": UnitId,
                        "unit_name": UnitName,
                        "unit_name_en": UnitName,
                        "unit_number": UnitName,
                        "transfer_date": TransferDate ? TransferDate : '',
                        "unit_type": '',
                        "property_id": vPropertyID,
                        "property_name": vPropertyName
                    }
                },
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            };
        }


        let res = await axios.post(majorCareUrl + "/majorcare/repairJob", data, { headers: { } }).then(async response => {
            let resJobId = response.data._id
            let dataTrail = {
                "repair_job":resJobId ? resJobId : '',
                "status": Stat.value,
                "status_name": Stat.label,
                "sub_status": SubStat !== undefined ? SubStat.value : null,
                // "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                "remark": RemarkStat !== undefined ? RemarkStat : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });

            // // ****************** 3. CREATE JOB DETAIL ******************
            // // **********************************************************
            let dataJobDetail = {
                "job_id": resJobId ? resJobId : '',
                "zone": SelectedZone ? SelectedZone.value : null,
                "category": SelectedCategory ? SelectedCategory.value : null ,
                "category_detail": SelectedCateDetail ? SelectedCateDetail.value : null ,
                "pic": ImgId,
                "vdo": vVidId[0],
                "detail": RemarkDetail !== undefined ? RemarkDetail : "",
                "contact": CustTel ? CustTel : "",
                "create_by": Identity_data?.user_profile,
            }
            let res_detail = await axios.post(majorCareUrl + "/majorcare/repairJobDetail", dataJobDetail, { headers: { } });

            successNoti('200', 'success');
            replace("/repair-mdpc/repair-mdpc-job");

        })
    }

    async function createJobAndTrail(){
        let dataAssign:any
        let data:any

        // console.log('vPropertyID', vPropertyID)
        // console.log('UnitId', UnitId)

        // console.log('vPropertyName', vPropertyName)
        // console.log('UnitName', UnitName)

        console.log('SelectedZone', SelectedZone)
        console.log('SelectedCategory', SelectedCategory)
        console.log('SelectedCateDetail', SelectedCateDetail)

        let userData = vUserData?.data?.find((item:any) => item?.user_profile.id == vCustomerID)
        console.log('userData', userData)
        console.log('userData.user_sub_types', userData.user_sub_types)

        let data_sub_type = {
            "data" : [{
                "attributes": userData.user_sub_type
            }]
        }
    
        let user_profile_data = {
            "first_name": userData ? userData.user_profile.first_name : '-',
            "last_name":userData ? userData.user_profile.last_name : '-' ,
            "gender": userData ? userData.user_profile.gender : '-',
            "nick_name": userData ? userData.user_profile.nick_name : '-',
            "tel": userData ? userData.user_profile.tel : '-',
            // "user_sub_types": userData ? userData.user_sub_type : null,
            "user_sub_types": userData ? data_sub_type : null,
        }

        // find property in majorcare db
        let MajorProperty = await axios.get(majorCareUrl + "/majorcare/findAllProperty", { headers: { } });
        // console.log('MajorProperty', MajorProperty)
        let FindProp:any = MajorProperty?.data.find((item:any) => item?.property_id == vPropertyID);
        // console.log('FindProp', FindProp)

        // find unit in majorcare db
        let MajorUnit = await axios.get(majorCareUrl + "/majorcare/findAllUnit", { headers: { } });
        // console.log('MajorUnit', MajorUnit)
        let FindUnit:any = MajorUnit?.data.find((item:any) => item?.unit_id == UnitId);
        // console.log('FindUnit', FindUnit)


        // ****************** 1. CREATE JOB ******************
        // ***************************************************
        // 649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
        // 64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
        if(Stat.value == "649859f8515b80b78fe13b88" || Stat.value == "649859f8515b80b78fe13b88"){
            dataAssign = {
                "id": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.id : null,
                "first_name":InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.first_name : null,
                "last_name": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.last_name : null,
                "tel": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.tel : null,
                "email": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.email : null,
            }
            data = {
                "status_id": Stat.value,
                "status_name": Stat.label,
                "sub_status_id": SubStat !== undefined ? SubStat.value : '',
                "sub_status_name": SubStat !== undefined ? SubStat.label : '',
                "is_major": true,
                "is_delegate": true,  // admin create job == true
                "property": FindProp ? FindProp._id : '',
                "unit": FindUnit ? FindUnit._id : '',
                "assign": InspectorProfile ? dataAssign : null,
                "appoint_start": AppointStart ? AppointStart : "",
                "appoint_end": AppointEnd ? AppointEnd : "",
                "approve": {},
                "eva_form": [],
                "create_by": user_data?.user_profile,
                "tc": {},
                "request_by": {
                    // "attributes": userData ? userData.user_profile : null,
                    "attributes": userData ? user_profile_data : null,
                    "unit_detail": {
                        "unit_id": UnitId,
                        "unit_name": UnitName,
                        "unit_name_en": UnitName,
                        "unit_number": UnitName,
                        "transfer_date": TransferDate ? TransferDate : '',
                        "unit_type": '',
                        "property_id": vPropertyID,
                        "property_name": vPropertyName
                    }
                },
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            };
        } else {
            data = {
                "status_id": Stat.value,
                "status_name": Stat.label,
                "sub_status_id": SubStat !== undefined ? SubStat.value : '',
                "sub_status_name": SubStat !== undefined ? SubStat.label : '',
                "is_major": true,
                "is_warantee": true,
                "is_delegate": true,  // admin create job == true
                "property": FindProp ? FindProp._id : '',
                "unit": FindUnit ? FindUnit._id : '',
                "assign": {},
                "appoint_stat": "",
                "appoint_end": "",
                "approve": {},
                "eva_form": [],
                "create_by": user_data?.user_profile,
                "tc": {},
                "request_by": {
                    // "attributes": userData ? userData.user_profile : null,
                    "attributes": userData ? user_profile_data : null,
                    "unitDetail": {
                        "unit_id": UnitId,
                        "unit_name": UnitName,
                        "unit_name_en": UnitName,
                        "unit_number": UnitName,
                        "transfer_date": TransferDate ? TransferDate : '',
                        "unit_type": '',
                        "property_id": vPropertyID,
                        "property_name": vPropertyName
                    }
                },
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            };
        }

        console.log('data', data)

        let res = await axios.post(majorCareUrl + "/majorcare/repairJobMDPC", data, { headers: { } }).then(async response => {
            // Handle successful response
            console.log(response.data);
            // successNoti('200', 'success');
            let resJobId = response.data._id
            console.log('resJobId', resJobId)
            // ****************** 2. CREATE TRAIL ******************
            // *****************************************************
            // let dataTrail = {
            //     "home_check_job" : item_id,
            //     "status": Stat, // หน้านี้ควรจะเป็นรอตรวจ
            //     "appoint_start": AppointStart ? AppointStart : null,
            //     "appoint_end": AppointEnd ? AppointEnd : null ,
            //     "assign": InspectorProfile ? InspectorProfile[0] : null,
            //     "sla": {
            //         "sla": "-",
            //         "sla_time": "-"
            //     },
            //     "remark": Remark !== undefined ? Remark : "",
            //     "create_by": Identity_data?.user_profile,
            //     // "create_date": dayjs()
            // }


            let dataTrail = {
                "repair_job":resJobId ? resJobId : '',
                "status": Stat.value,
                "status_name": Stat.label,
                "sub_status": SubStat !== undefined ? SubStat.value : null,
                // "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                "remark": RemarkStat !== undefined ? RemarkStat : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailMDPC", dataTrail, { headers: { } });
            // console.log('res_trail', res_trail)

            // // ****************** 3. CREATE JOB DETAIL ******************
            // // **********************************************************
            let dataJobDetail = {
                "job_id": resJobId ? resJobId : '',
                "zone": SelectedZone ? SelectedZone.value : null,
                "category": SelectedCategory ? SelectedCategory.value : null ,
                "category_detail": SelectedCateDetail ? SelectedCateDetail.value : null ,
                "pic": ImgId,
                "vdo": vVidId[0],
                // "detail": "จะซ่อมก็ซ่อม",
                "detail": RemarkDetail !== undefined ? RemarkDetail : "",
                "contact": CustTel ? CustTel : "",
                "create_by": Identity_data?.user_profile,
            }
            let res_detail = await axios.post(majorCareUrl + "/majorcare/repairJobDetailMdpc", dataJobDetail, { headers: { } });

            // // setCountInterval(0)
            successNoti('200', 'success');
            replace("/repair-mdpc/repair-mdpc-job");

        })
        // let res2 = await axios.post(majorCareUrl + "/majorcare/test", data, { headers: { } }).then(async response => {
        //     // Handle successful response
        //     console.log(response.data,'------------------------------');
        //     // successNoti('200', 'success');


        // })
        // console.log('res', res)
    }

    const successNoti = (stat:any, msg:any) => {

        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });

        notification['success']({
            message: "Successful",
            description:
              'Successfully created',
        });
        
    };


    const { selectProps: PropertySelectProps } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        metaData: {
            populate: ["pp_type"],
        },
        filters: [
            initF,
            // {
            //     field : 'id',
            //     operator : 'eq',
            //     value :  data_property
            // },
        ],
        pagination:{
            pageSize: 100
        },
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;
            // console.log(">>> dataLoad Property", dataLoad)

            SetPropType(dataLoad[0]?.pp_type?.pp_name)
            SetPropUnder(dataLoad[0]?.property_under)
            // setCustomname(dataLoad?.custom_role_name)
        }}
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource    : "units",
        optionLabel : "unit_name",
        optionValue : "id",
        filters: [
            initF,
            {
                field : 'property][id]',
                operator : vPropertyID ? 'eq' : 'nin',
                value :  vPropertyID ? vPropertyID : 'xxxxxx',
            },
        ],
        pagination:{
            pageSize: 1000
        },
    });

    const UserHasUnit = useList<any>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile', 'user_profile.first_name', 'user_profile.last_name', 'user_sub_type','unit','unit.property'],
        },
        config:{
            filters: [
                {
                    field   : "unit][id]",
                    operator: UnitId ? "eq" : 'nin',
                    value   : UnitId ? UnitId : 'xxxxxxx'
                },
                {
                    field   : "unit][id]",
                    operator: UnitId ? "eq" : 'nin',
                    value   : UnitId ? UnitId : 'xxxxxxx'
                },
            ],
            pagination: { current: 1, pageSize: 1000 },
        }
    });

    let vUserData:any = []
    vUserData = UserHasUnit?.data? UserHasUnit?.data : [];

    // console.log('vUserData', vUserData)
    // console.log('vCustomerID', vCustomerID)

    // GET CUSTOMER DATA
    const { listProps: userData, queryResult: { refetch: refetch_user } } = useSimpleList({
        resource: "user-has-units",
        metaData: {
            populate: ['user_profile', 'user_profile.first_name', 'user_profile.last_name','user_sub_type','unit','unit.property'],
        },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter: [
            {
                field: "unit][id]",
                operator: "in",
                value: UnitId,
            },
            {
                field: "user_profile][id]",
                operator: "in",
                value: vCustomerID,
            }
        ],
        queryOptions: {
          onSuccess: (data:any) => {
            let dataLoad = data?.data
            console.log('dataLoad user selected', dataLoad)

            SetTransferDate(dataLoad[0] ? dataLoad[0].unit ? dataLoad[0].unit.owner_in_date ? dayjs(dataLoad[0].unit.owner_in_date).format("DD/MM/YYYY") : '-' : '-': '-');
            SetTransferDateForCal(dataLoad[0] ? dataLoad[0].unit ? dataLoad[0].unit.owner_in_date ? dataLoad[0].unit.owner_in_date : '-' : '-': '-');
            SetCustTel(dataLoad[0] ? dataLoad[0].user_profile ? dataLoad[0].user_profile.tel ? dataLoad[0].user_profile.tel : '-' : '-': '-');
            SetCustType(dataLoad[0] ? dataLoad[0].user_sub_type ? dataLoad[0].user_sub_type.sub_type_name ? dataLoad[0].user_sub_type.sub_type_name : '-' : '-': '-');
            SetCustEmail(dataLoad[0] ? dataLoad[0].user_profile ? dataLoad[0]?.user_profile?.user ? dataLoad[0].user_profile?.user?.email : '-' : '-': '-');

          },
        },
    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }

        console.log('isJpgOrPng', isJpgOrPng)
        console.log('isLt2M', isLt2M)

        // if(isJpgOrPng && isLt2M){
        //     return Dragger.ignore
        // }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const beforeUploadVid = (file: RcFile) => {
        const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/quicktime';
        if (!isJpgOrPng) {
            message.error('You can only upload MP4 file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
            message.error('Image must smaller than 25MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange_vid(e: any) {
        console.log('event vid', e)
        if(e.file.status === "removed") {
            // setImgEn((pre:any) => ({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [],
            // }));

            // setVidId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));
            setVidId([]);

            setVid((prev: any) => ({
                ...prev,
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));

            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setVidId((pre:any) => ([
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setVid((pre:any) => ([{
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    function fileChange_EN(e: any) {
        console.log('event', e)
        if(e.file.status === "removed") {
            // setImgEn((pre:any) => ({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [],
            // }));

            SetImgId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));

            if(vImgEn.defaultFileList.length == 1){
                setImgEn((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: []
                }));
            }else{
                setImgEn((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
                }));
            }
            // setImgEn((prev: any) => ({
            //     ...prev,
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
            // }));

            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                SetImgId((pre:any) => ([
                    ...pre,
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setImgEn((pre:any) => ([
                    // ...pre,
                    {
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    // const calWarranty = (e:any) => {
    //     console.log('e', e?.data?.warantee)
    //     const presentDay = dayjs() // Get the present day using dayjs

    //     // console.log('TransferDate', TransferDate)
    //     // const tfDate = dayjs(TransferDate).toISOString()

    //     // const transferDate = dayjs(TransferDateForCal, "Asia/Bangkok");

    //     // console.log('e?.data?.warantee', e?.data?.warantee)
    //     // console.log('tfDate', transferDate)
    //     // console.log('TransferDateForCal', TransferDateForCal)
    //     const transferDate = dayjs(TransferDateForCal);

    //     // console.log('transferDate', transferDate)

    //     const calDate = transferDate.add(e?.data?.warantee, 'day'); // Add one day to appoint_end
    //     // console.log('calDate',calDate)
    //     // transfer_date + warrantee > today()
    //     // setIsWarranty(false)
    //     // console.log('calDate', dayjs(calDate).format("YYYY MM DD"))
    //     console.log('calDate.isBefore(presentDay)', calDate.isBefore(presentDay))
    //     if(calDate.isBefore(presentDay)){
    //         // ถ้าวันโอน + จำนวนวันประกัน เป็นวันก่อนหน้าวันนี้คือหมดประกัน
    //         // หมดประกัน
    //         SetIsWaranty(false)
    //     }else{
    //         // อยู่ในประกัน
    //         SetIsWaranty(true)
    //     }

    //     SetSelectedCateDetail(e)
    // }

    const calWarranty = (e:any) => {
        // console.log('e', e?.data?.warantee)
        const presentDay = dayjs() // Get the present day using dayjs
        const transferDate = dayjs(TransferDateForCal);
        // const calDate = transferDate.add(e?.data?.warantee, 'day'); // Add one day to appoint_end
        const calDate = presentDay.diff(transferDate, 'day'); // Add one day to appoint_end

        // console.log('calDate', calDate)
        // console.log('e?.data?.warantee', e?.data?.warantee)

        // if(calDate.isBefore(presentDay)){
        if(calDate < e?.data?.warantee){
           // อยู่ในประกัน
           console.log('in warranty')
           SetIsWaranty(true)
        }else{
            console.log('out warranty')
            // ถ้าวันโอน + จำนวนวันประกัน เป็นวันก่อนหน้าวันนี้คือหมดประกัน
            // หมดประกัน
            SetIsWaranty(false)
        }
        SetSelectedCateDetail(e)
    }

    // console.log('SelectedCateDetail', SelectedCateDetail)

    // console.log('TransferDate', TransferDate)
    // console.log('CustTel', CustTel)
    // console.log('CustType', CustType)

    // console.log('selected_zone', SelectedZone)
    // console.log('selected_cate', SelectedCategory)
    // console.log('selected_cate_detail', SelectedCateDetail)
    console.log('IsWaranty', IsWaranty)

    // console.log('DataZone', DataZone)
    // console.log('DataCategory', DataCategory)
    // console.log('DataCatDetail', DataCatDetail)

    // console.log('SelectedCategory.value',SelectedCategory ? SelectedCategory.value : 'xxx')
    // console.log('DataCatDetail', DataCatDetail)

    // console.log('CustomerDetail', CustomerDetail)

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="Create รายการแจ้งซ่อม"
        pageHeaderProps={{
            extra : <></>,
            onBack(e?) {
                replace("/repair-mdpc/repair-mdpc-job")
            },
        }}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>MDPC แจ้งซ่อม</Breadcrumb.Item>
                    <Breadcrumb.Item>รายการแจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Create รายการแจ้งซ่อม</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>,
                // onBack(e?) {
                //     replace("/repair-mdpc/repair-mdpc-job")
                // },
            }}
        />
            <Form
                form = {form}
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);

                    if(IsWaranty && PropUnder == "Major"){ // อยู่ในประกัน ไป create majorcare
                        console.log('create major')
                        createJobAndTrailMajor();

                    }else{
                        console.log('create mdpc')
                        createJobAndTrail();
                    }
                }}
            >
                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียด
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label="Job no."
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input 
                                        placeholder="autorun" 
                                        disabled
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="Property"
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Select 
                                        {...PropertySelectProps}
                                        // onChange={(e:any, label:any) => setPropertyID(e)}
                                        // onSelect={(label:any) => setPropertyName(label)}
                                        // onSelect={(e:any, value:any) => {console.log('label >>>>>>> ', value.label)}}
                                        onSelect={(e:any, value:any) => {setPropertyName(value.label)}}
                                        // onSelect={(value:any) => setPropertyName(value.label)}
                                        // onChange={(e:any) => setPropertyID(e)}
                                        onChange={(e:any) => (setPropertyID(e), getRepairZone()) }

                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="ผู้แจ้ง"
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Select placeholder={t("select.sel")} 
                                        onSelect={(value: any, record: any) => {
                                            setCustomerID(value);
                                            // SetCustomerDetail(record);
                                        }}
                                    >
                                        {
                                            vUserData?.data?.length > 0 ?
                                                vUserData?.data?.map((item:any,key:any) => {
                                                    if(item.user_profile){
                                                        return <>
                                                            <Option 
                                                                value={item.user_profile.id}
                                                                key={item.id}
                                                            >
                                                                {item.user_profile ? item.user_profile.first_name : ''}{' '}{item.user_profile ? item.user_profile.last_name : ''}
                                                            </Option>
                                                        </>
                                                    }
                                                })
                                            : <></>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่แจ้ง"
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input 
                                        value={dayjs(CreateDate).format("DD/MM/YYYY HH:mm")} 
                                        disabled
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="Unit" 
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Select 
                                        {...SelUnit} 
                                        // onChange={(e:any) => setUnitId(e)}
                                        // onSelect={(value:any) => setUnitName(value.label)}
                                        onSelect={(e:any, value:any) => {setUnitName(value.label)}}
                                        onChange={(e:any) => setUnitId(e)}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="ประเภท" 
                                    // name="user_type"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input 
                                        value={CustType} 
                                        defaultValue={CustType} 
                                        disabled
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่โอน" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>

                                <Form.Item 
                                    label="เบอร์โทร" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={CustTel} defaultValue={CustTel} disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียดการแจ้งซ่อม
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="โซน" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Select
                                onChange={(e:any, record:any) => SetSelectedZone(record)}
                            >
                                {
                                    DataZone?.length > 0 ?
                                        DataZone?.map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                >
                                                    {item.zone_name ? item.zone_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="หมวด" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Select
                                // onChange={(e:any, record:any) => SetSelectedCategory(record)}
                                // onSelect={() => getRepairCateDetail()}
                                onChange={(e:any, record:any) => { SetSelectedCategory(record)} }
                            >
                                {
                                    DataCategory?.length > 0 ?
                                        DataCategory?.map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                >
                                                    {item.category_name ? item.category_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label="รายการ" 
                            // name="details"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Select
                                // onChange={(e:any, record:any, data:any) => {console.log('e', e)}}
                                // onChange={(e:any, record:any) => 
                                //     console.log('e', record)
                                // }
                                // onChange={(e:any, record:any) => SetSelectedCateDetail(record)}
                                onChange={(e:any, record:any) => {calWarranty(record)}}
                            >
                                
                                {/* {
                                    DataCatDetail?.length > 0 ?
                                        DataCatDetail?.map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                    data={item}
                                                >
                                                    {item.category_detail_name ? item.category_detail_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                } */}
                                {
                                    DataCatDetail?.length > 0 ?
                                        DataCatDetail?.filter((item:any) => item.category_name == SelectedCategory?.value).map((item:any,key:any) => {
                                            return <>
                                                <Option 
                                                    value={item._id}
                                                    key={item._id}
                                                    data={item}
                                                >
                                                    {item.category_detail_name ? item.category_detail_name : 'NODATA'}
                                                </Option>
                                            </>
                                        })
                                    : <></>
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="ประกัน" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <div>{SelectedCateDetail? SelectedCateDetail.data ? SelectedCateDetail.data.warantee + ' วัน' : '-' : '-' }</div>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="อยู่ในประกัน" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <div>{SelectedCateDetail? SelectedCateDetail.data ? SelectedCateDetail.data.status == "Y" ? 'ใช่' : 'ไม่ใช่' : '-' : '-' }</div> */}
                            <div>
                                {
                                    IsWaranty ? 'ใช่' : 'ไม่ใช่'
                                }
                            </div>
                        </Form.Item>
                    </Col>                    
                </Row>

                <Row>
                    <Col span={16}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                onChange={(e) => SetRemarkDetail(e.target.value)}
                                // disabled={Stat == "6485bd6e681aef755a4f279b" ? true : false}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        {/* รูปประกอบ: */}
                        <Form.Item
                            label="รูปประกอบ (สูงสุด 10 รูป)"
                            name="pic2"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vImgEn}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={5}
                                beforeUpload={beforeUpload}
                                onChange={fileChange_EN}
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="ไฟล์วิดีโอ (1 คลิป)"
                            name="pic"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vVid}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={1}
                                multiple={false}
                                beforeUpload={beforeUploadVid}
                                onChange={fileChange_vid}
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            สถานะการทำงาน
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="สถานะ" 
                            // name="status"
                            style={{width:"90%"}}
                        >

                            {/* 
                                MAIN STATUS

                                649859ca515b80b78fe13b84 = รอดำเนินการ
                                649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
                                649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
                                64985a01515b80b78fe13b8a = เข้าประเมิน
                                64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
                                64985a29515b80b78fe13b8e = ประเมินเสร็จ
                                64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
                                64985a3c515b80b78fe13b92 = เข้าซ่อม
                                64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
                                64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
                                64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
                                64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
                                64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
                                64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
                                64985bcf515b80b78fe13bba = ปิดงาน *
                            */}

                            {/* 
                                SUB STATUS

                                ******* main = เลื่อนนัดจากลูกค้า *******
                                64985a95515b80b78fe13b98 = ลูกค้าติดธุระส่วนตัว
                                64985a9c515b80b78fe13b9a = ลูกค้าเป็นโควิด/กักตัว
                                64985aaa515b80b78fe13b9c = ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน
                                64985ab9515b80b78fe13b9e = ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน
                                64985ac3515b80b78fe13ba0 = ไม่สามารถติดต่อลูกค้าได้


                                ******* main = เลื่อนนัดจากนิติ *******
                                64985b04515b80b78fe13ba4 = ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด
                                64985b0d515b80b78fe13ba6 = ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้
                                64985b17515b80b78fe13ba8 = ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง
                                64985b21515b80b78fe13baa = รอ Major อนุมัติ PO/PR


                                ******* main = อุปกรณ์อยู่ระหว่างการผลิต *******
                                64985b5e515b80b78fe13bae = อุปกรณ์ สุขภัณฑ์
                                64985b68515b80b78fe13bb0 = วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์
                                64985b74515b80b78fe13bb2 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์
                                64985b80515b80b78fe13bb4 = อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า


                                ******* main = ปิดงาน *******
                                64985be1515b80b78fe13bbc = ส่งงานเสร็จสมบูรณ์
                                64985bec515b80b78fe13bbe = Customer เปิดงานซ้ำ
                                64985bf6515b80b78fe13bc0 = Customer เปิดงานผิด
                                64985c0c515b80b78fe13bc2 = Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า
                                64985c15515b80b78fe13bc4 = ให้คำแนะนำ เพื่อจัดจ้าง
                                64985c22515b80b78fe13bc6 = งานที่ไม่อยู่ในเงื่อนไขรับประกัน
                                64985c2d515b80b78fe13bc8 = รายละเอียดไม่ตรงกับรายการรับประกัน
                                64985c38515b80b78fe13bca = ลูกค้าเลื่อนซ่อม เกิน SLA
                                64985c42515b80b78fe13bcc = รอสั่งของ
                                64985c4a515b80b78fe13bce = งานซ่อมที่เกิดจากการใช้งาน
                                64985c56515b80b78fe13bd0 = งานเคลมประกันภัยอาคาร

                            */}
                            
                            <Select 
                                placeholder={t("select.sel")}
                                style={{ width: '100%' }}
                                onSelect={(value: any, record:any) => {
                                    // console.log(value);
                                    // console.log('record', record);
                                    // setStat(value);
                                    setStat(record);
                                }}
                                options={option_main_status}
                            />
                        </Form.Item>
                    </Col>


                    {
                        Stat !== undefined ?
                        Stat.value == "64985a67515b80b78fe13b96" ||  Stat.value == "64985ae5515b80b78fe13ba2" ||  Stat.value == "64985b3b515b80b78fe13bac" ||  Stat.value == "64985bcf515b80b78fe13bba" ?
                            <Col span={8}>
                                {/* 
                                    4.2.3.กรณีเลือก สถานะที่มี สถานะย่อย ให้แสดงสถานะย่อยให้เลือกด้วย บังคับเลือก
                                    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า   option_cust_postpone
                                    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ          option_juristic_postpone
                                    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต  option_matt_in_manufacturing
                                    64985bcf515b80b78fe13bba = ปิดงาน                option_close_job
                                */}
                                <Form.Item 
                                    label="สถานะย่อย" 
                                    // name="status"
                                    style={{width:"90%"}}
                                >
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            // setSubStat(value);
                                            setSubStat(record);
                                        }}
                                        options={
                                            Stat.value == "64985a67515b80b78fe13b96" ? option_cust_postpone
                                            : Stat.value == "64985ae5515b80b78fe13ba2" ? option_juristic_postpone
                                            : Stat.value == "64985b3b515b80b78fe13bac" ? option_matt_in_manufacturing
                                            : Stat.value == "64985bcf515b80b78fe13bba" ? option_close_job
                                            : null
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        : <></>
                        : <></>
                    }
                    
                </Row>

                {/* 
                    CONDITION
                    4.2.1.กรณีเลือก สถานะ นัด วัน / เวลา ประเมินหน้างาน จะต้องมี ตั้งแต่วันที ่ให้เลือกวันเวลา , ถึงวันที่ ต้องให้เลือกวัน เวลา และเลือก ช่างผู้รับผิดชอบ
                    4.2.2.กรณีเลือก สถานะ นัด วัน / เวลา เข้าซ่อม จะต้องมี  ตั้งแต่วันที ่ให้เลือกวันเวลา , ถึงวันที่ ต้องให้เลือกวัน เวลา และเลือก ช่างผู้รับผิดชอบ

                    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
                    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
                */}
                {
                    Stat !== undefined ?
                    Stat.value == "649859f8515b80b78fe13b88" || Stat.value == "64985a35515b80b78fe13b90" ?
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                        label="วันที่นัด" 
                                        // name="appointment_date"
                                        style={{
                                            width:"95%",
                                            // marginTop:'-3%'
                                        }}
                                    >
                                    <DatePicker
                                        style={{width: "100%"}}
                                        className="ant-picker-mjc" 
                                        onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "start")} 
                                        format={format}
                                        showTime
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="ถึงวันที่" 
                                    // name="status"
                                    style={{
                                        width:"95%",
                                        // marginTop:'27%', 
                                    }}
                                >
                                    <DatePicker
                                        style={{width: "100%"}}
                                        className="ant-picker-mjc" 
                                        onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "end")} 
                                        showTime
                                        format={format}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="ช่างผู้รับผิดชอบ" 
                                    // name="inspector"
                                    style={{
                                        width:"95%", 
                                        // marginTop:'-3%'
                                    }}
                                >
                                    <Select 
                                        placeholder={t("select.sel")} 
                                        {...selInspector}
                                        onSelect={(value: any, record:any) => {
                                            FindInspectorUserId(value)
                                        }}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    : <></>
                    : <></>
                }
                
            

                <Row>
                    <Col span={18}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            {/* <TextArea></TextArea> */}
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                // defaultValue={RemarkFromLastTrail}
                                // value={Remark}
                                onChange={(e) => SetRemarkStat(e.target.value)}
                                // disabled={Stat == "6485bd6e681aef755a4f279b" ? true : false}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                    <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                </Row>

            </Form>
    </Edit>   
    );
};