/* eslint-disable */
import { Breadcrumb, Card, Checkbox, Col, DatePicker, Edit, Form, Image, Input, List, Row, Select, useForm, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useList, useNavigation, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { IParcelList, IUserhunit, IUserProfile } from "interfaces";
import { useEffect, useState } from "react";
// import moment from "moment";


import { TOKEN_KEY } from "../../constants";
export const ParcelListEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();


    // const { mutate: CreateLog } = useCreate<IEventlog>();
    // const log_endpoint = "even-logs";
    const receiverID = localStorage.getItem("receiverID");
    // console.log('receiverID', receiverID)
    const { replace } = useNavigation();

    const userQuery = useGetIdentity(); // Get User Profile
    const format = 'YYYY-MM-DD HH:mm';
    const { data: user_data, isLoading } = userQuery;
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { form, formProps, saveButtonProps } = useForm<IParcelList, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
            }, redirect: "list"
        }
    );

    form.setFieldsValue({
        received_date: dayjs(),
    })

    const { mutate: updateParcelList } = useUpdate<IParcelList>();

    const [disabled, setDisabled] = useState(false);
    const [selProp, setProp] = useState<any>(0);
    const [vUnitID, setUnitID] = useState<any>(0);
    const [vProfileID, setProfileID] = useState<any>(0);
    const [vNote1, setNote1] = useState<any>(0);

    const [vReceiverFirstName, setReceiverFirstName] = useState<any>('');
    const [vReceiverLastName, setReceiverLastName] = useState<any>('');
    const [vAnotherCustomer, setAnotherCustomer] = useState<any>('');

    const [vAnotherParcel, setAnotherParcel] = useState<any>([]);
    const [vAnotherParcelID, setAnotherParcelID] = useState<any>([]);
    const [vAnotherParcelStat, setAnotherParcelStat] = useState<any>([]);
    const [vAnotherParcelStatTotal, setAnotherParcelStatTotal] = useState<any>([]);
    const [allChecked, setAllChecked] = useState(false);


    const [vSelStat, setSelStat] = useState<any>("");

    const [vReturnDate, setReturnDate] = useState<any>("");
    // const [ vReceivedDate, setReceivedDate ] = useState<any>("");
    const [vReceivedDate, setReceivedDate] = useState<any>(dayjs().format("YYYY-MM-DD HH:mm")); // 2023-06-26 21:37
    const [vRecivedToday, setRecivedToday] = useState<any>(dayjs().format("YYYY-MM-DD HH:mm"));

    const [vShowUser, setShowUser] = useState<any>('');
    const [vThisRecID, setThisRecID] = useState<any>();
    const [vThisTrackingNo, setThisTrackingNo] = useState<any>();

    const [vChecked, setChecked] = useState<any>([]);
    const [vSelected, setSelected] = useState<any>([]);
    const [ts1, setts1] = useState(false)
    const [ts2, setts2] = useState(false)
    const [ts3, setts3] = useState(false)

    console.log('vReceivedDate', vReceivedDate)

    const receiverData = useList<IUserProfile>({
        resource: "user-profiles",
        metaData: {
            populate: '*'
            // populate: ['user_profile','user_sub_type','unit','unit.property'],
        },
        config: {
            filters: [
                {
                    field: "id]",
                    operator: "eq",
                    value: receiverID
                },
            ],
            pagination: { current: 1, pageSize: 100 },
        }
    });

    let profileIdScanned: any = receiverData?.data ? receiverData?.data : [];

    // console.log('profileIdScanned', profileIdScanned)

    const UserHasUnit = useList<IUserhunit>({
        resource: "user-has-units",
        metaData: {
            populate: ['user_profile', 'user_sub_type', 'unit', 'unit.property'],
        },
        config: {
            filters: [
                // {
                //     field   : "user_profile][id]",
                //     operator: "eq",
                //     value   : recordData.user_profile ? recordData.user_profile.id + "" : null
                // },
                {
                    field: "unit][id]",
                    operator: "eq",
                    value: vUnitID
                },
                {
                    field: "isOwnerConfirmed",
                    operator: "eq",
                    value: true
                },
                {
                    field: "isAdminConfirmed",
                    operator: "eq",
                    value: true
                },
                // {
                //     field   : "unit][property][id]",
                //     operator: "eq",
                //     value   : selProp
                // },
                // {
                //     field   : "unit][property][id]",
                //     operator: vAppointmentType == 1 ? "ne" : "eq",
                //     value   : vAppointmentType == 1 ? "" : vProperty
                // },
            ],
            pagination: { current: 1, pageSize: 100 },
        }
    });

    const { queryResult } = useShow<IParcelList>({
        metaData: {
            // populate: '*'
            populate: ['parcel_in', 'parcel_in.unit', 'parcel_in.user_profile', 'parcel_in.property', 'pic', 'received_user'],
        }
    });
    const { data } = queryResult;
    // const record = data?.data;

    let recordData: any;
    recordData = queryResult?.data ? queryResult?.data : [];
    recordData = data?.data ? data?.data : [];

    // console.log('recordData', recordData)

    const parcelListData = useList<IParcelList>({
        resource: "parcel-lists",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "parcel_in][id]",
                    operator: "in",
                    value: recordData.id
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        }
    });

    const { data: parcelListDataMap } = parcelListData;
    let parcelListQuery: any = parcelListDataMap?.data ? parcelListDataMap?.data : [];

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "parcel-lists",
        metaData: {
            populate: [
                '*',
                'parcel_type',
                'parcel_box_size',
                'parcel_shipping_type',
                'delivery_company',
                'parcel_in',
                'received_user',
                'returnBy',
            ]
        },
        pagination: {
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
            onSuccess: (data) => {
                console.log('data xxxx : ', data.data)
                callFetchData();

            },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
    }, []);

    function callFetchData() {
        console.log('recordData', recordData)
        setUnitID(recordData.parcel_in ? recordData.parcel_in.unit.id : 0);
        // setProp(recordData?.unit ? recordData?.unit?.property.property_name : undefined);
        setShowUser(recordData?.user_profile !== undefined ? recordData?.user_profile?.first_name + " " + recordData?.user_profile?.last_name : '-')

        if (profileIdScanned.total > 0) {
            setSelStat("RECEIVED")
            setProfileID(profileIdScanned.data[0].id)
            // setReceivedDate(dayjs().format("YYYY-MM-DD HH:mm")) 
        } else {
            setSelStat(recordData.parcel_status)
        }

        setThisRecID(recordData.id)
        setThisTrackingNo(recordData.tracking_no)

        // setReceiverFirstName(recordData.received_user !== null ? recordData.received_user.first_name : '-')
        setReceiverFirstName(recordData.received_user !== null ? recordData.received_user.first_name : recordData.customer_name !== "" ? recordData.customer_name : '-')
        setReceiverLastName(recordData.received_user !== null ? recordData.received_user.last_name : '-')

        recordData.received_date ? setReceivedDate(dayjs(recordData.received_date).format("YYYY-MM-DD HH:mm")) : setReceivedDate(undefined)
        recordData.return_date ? setReturnDate(dayjs(recordData.return_date).format("YYYY-MM-DD HH:mm")) : setReturnDate(undefined)

    }


    useEffect(() => {

        if (recordData.parcel_status !== undefined) {
            if (recordData.parcel_status === "RETURN" || recordData.parcel_status === "RECEIVED") {
            } else {
                if (vThisRecID !== undefined) {
                    callAPI(vUnitID);
                }
            }
        }

    }, [vUnitID !== 0, vThisRecID !== undefined]);

    //  Use useEffect to check all items on initial render
     useEffect(() => {
        console.log(vAnotherParcel);
        if (recordData.parcel_status !== undefined) {
            if (recordData.parcel_status === "WAITING") {
                if (vAnotherParcel.length > 0 && vAnotherParcel && vAnotherParcel !== 'nodata') {
                    console.log(vAnotherParcel);
                    const allCheckedStatus = vAnotherParcel.map(() => false); // Make all items checked initially
                    setCheckedItems(allCheckedStatus);
                    vAnotherParcel.forEach((el: any, index : any) => onChangeCBParcel(false, el.id, index)); // Trigger onChange for each item
                }
            } 
        }
       
    }, [vAnotherParcel]); // Runs only when vAnotherParcel is available

    let resultQuery1: any = []

    async function callAPI(v: any) {
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        resultQuery1 = await axios.get(API_URL + "/like-systems/findParcelInSameUnit?unit_id=" + v + "&parcel_list=" + vThisRecID + "&tracking_no=" + vThisTrackingNo, { headers: { Authorization: AuthStr } });

        console.log('resultQuery1', resultQuery1)
        setAnotherParcel(resultQuery1?.data);

        if (resultQuery1?.data !== "nodata") {
            resultQuery1.data?.map((item: any, index: any) => {
                return vSelected.push(({
                    id: item?.id,
                    parcel_status: item?.parcel_status,
                    parcel_in: item?.parcel_in,
                    index: index
                })),
                    vChecked.push(({
                        id: item?.id,
                        index: index,
                        checked: false
                    }))
            });
        }

    }
    let vUserData: any = UserHasUnit?.data ? UserHasUnit?.data : [];

    if (vUserData.data !== null || vUserData.data !== undefined) {
        if (vUserData?.total > 0) {
            const findRenter = vUserData.data.find((e: { user_sub_type: { sub_type_name: string; }; }) => e.user_sub_type.sub_type_name == "Renter");
            const findResidentRenter = vUserData.data.find((e: { user_sub_type: { sub_type_name: string; }; }) => e.user_sub_type.sub_type_name == "Resident Renter");

            if (findRenter !== undefined || findResidentRenter !== undefined) {
                let index = vUserData.data.findIndex((i: { user_sub_type: any; }) => i.user_sub_type.id === 1); // id 1 = OWNER
                if (index !== -1) {
                    vUserData.data.splice(index, 1)
                }
            }
        }
    }

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function updateAnotherParcel() {
        // console.log(">>> vAnotherParcel",vAnotherParcel)
        // console.log('vProfileID', vProfileID)
        // console.log('profileIdScanned', profileIdScanned)
        // console.log('vAnotherParcelStatTotal', vAnotherParcelStatTotal)
        if (vAnotherParcel?.length > 0) {
            for (let i = 0; i <= vAnotherParcel?.length - 1; i++) {
                // console.log('vAnotherParcel', vAnotherParcel[i])
                if (vChecked[i]?.checked == true) {
                    updateParcelList({
                        resource: "parcel-lists",
                        id: vAnotherParcel[i]?.id,
                        values: {
                            parcel_status: vAnotherParcel[i].parcel_status,
                            // received_user : vAnotherParcel[i]?.parcel_status == "RETURN" ? null 
                            //     : vAnotherParcel[i]?.parcel_status == "WAITING" ? null 
                            //     : profileIdScanned?.total > 0 ? vProfileID 
                            //     : vAnotherParcel[i]?.parcel_status == "RECEIVED" && vAnotherParcelStatTotal[0][i].received_user !== null ? vAnotherParcelStatTotal[0][i].received_user : null,
                            received_user: vAnotherParcel[i]?.parcel_status == "RETURN" ? null
                                : vAnotherParcel[i]?.parcel_status == "WAITING" ? null
                                    : profileIdScanned?.total > 0 ? vProfileID
                                        : vAnotherParcel[i]?.parcel_status == "RECEIVED" ? profileIdScanned?.total > 0 ? vProfileID : null : null,
                            customer_name: vAnotherParcel[i]?.customer_name ? vAnotherParcel[i]?.customer_name : null,
                            received_date: vAnotherParcel[i]?.parcel_status == "RECEIVED" ? new Date() : null,
                            return_date: vAnotherParcel[i]?.parcel_status == "RETURN" ? vAnotherParcel[i]?.return_date : null,
                            returnBy: vAnotherParcel[i]?.parcel_status == "RETURN" ? user_data.user_profile.id : null
                        },
                        successNotification: false,
                    });
                }
            }
        }
    }

    const returnDateHandle = (date: Dayjs | null, dateString: string, id: number) => {
        setReturnDate(dateString);

    };

    const receivedDateHandle = (date: Dayjs | null, dateString: string, id: number) => {
        setReceivedDate(dateString);
    };

    const onChangeCBParcel = (e: any, id: any, index: any) => {
        // console.log('e', e)
        // console.log('id', id)
        // console.log('index', index)

        // console.log('vChecked', vChecked)

        let index_change = vChecked?.findIndex((el: any) => el?.id == id);
        if (index_change > -1) {
            let dataload = vChecked
            let onsplice = {
                id: vChecked[index].id,
                index: vChecked[index].index,
                checked: e,
            }
            dataload[index] = onsplice
            setts3(!ts3)
            setChecked((pre: any) => dataload)
            console.log('check list : ',dataload);
            console.log('vanother: ', vAnotherParcel);
            console.log('vSelected: ', vSelected);
        }
    };

    function handleStatChange(parcelID: any, stat: any, received_user: any, customer_name: any, index: any) {
        let index_change = vAnotherParcel?.findIndex((el: any) => el?.id == parcelID);
        if (index_change > -1) {
            if (stat == "RETURN") {
                let dataload = vAnotherParcel
                let onsplice = {
                    id: vAnotherParcel[index].id,
                    tracking_no: vAnotherParcel[index].tracking_no,
                    note: vAnotherParcel[index].note,
                    createdAt: vAnotherParcel[index].createdAt,
                    updatedAt: vAnotherParcel[index].updatedAt,
                    parcel_status: stat,
                    gen_code: vAnotherParcel[index].gen_code,
                    customer_name: vAnotherParcel[index].customer_name,
                    received_date: null,
                    return_date: null,
                    parcel_in: vAnotherParcel[index].parcel_in,
                    pic: vAnotherParcel[index].pic
                }
                dataload[index] = onsplice
                setts1(!ts1)
                setAnotherParcel((pre: any) => dataload)

                //======================================================

                let dataselect = vSelected
                let onspl = {
                    id: vSelected[index].id,
                    parcel_status: stat,
                    parcel_in: vSelected[index].parcel_in,
                    index: vSelected[index].index,
                }
                dataselect[index] = onspl
                setts2(!ts2)
                setSelected((pre: any) => dataselect)
            } else if (stat == "RECEIVED") {
                let dataload = vAnotherParcel
                let onsplice = {
                    id: vAnotherParcel[index].id,
                    tracking_no: vAnotherParcel[index].tracking_no,
                    note: vAnotherParcel[index].note,
                    createdAt: vAnotherParcel[index].createdAt,
                    updatedAt: vAnotherParcel[index].updatedAt,
                    parcel_status: stat,
                    gen_code: vAnotherParcel[index].gen_code,
                    customer_name: vAnotherParcel[index].customer_name,
                    received_date: new Date(),
                    return_date: null,
                    parcel_in: vAnotherParcel[index].parcel_in,
                    pic: vAnotherParcel[index].pic
                }
                dataload[index] = onsplice
                setts1(!ts1)
                setAnotherParcel((pre: any) => dataload)

                //======================================================

                let dataselect = vSelected
                let onspl = {
                    id: vSelected[index].id,
                    parcel_status: stat,
                    parcel_in: vSelected[index].parcel_in,
                    index: vSelected[index].index,
                }
                dataselect[index] = onspl
                setts2(!ts2)
                setSelected((pre: any) => dataselect)
            } else if (stat == "WAITING") {
                let dataload = vAnotherParcel
                let onsplice = {
                    id: vAnotherParcel[index].id,
                    tracking_no: vAnotherParcel[index].tracking_no,
                    note: vAnotherParcel[index].note,
                    createdAt: vAnotherParcel[index].createdAt,
                    updatedAt: vAnotherParcel[index].updatedAt,
                    parcel_status: stat,
                    gen_code: vAnotherParcel[index].gen_code,
                    customer_name: vAnotherParcel[index].customer_name,
                    received_date: null,
                    return_date: null,
                    parcel_in: vAnotherParcel[index].parcel_in,
                    pic: vAnotherParcel[index].pic
                }
                dataload[index] = onsplice
                setts1(!ts1)
                setAnotherParcel((pre: any) => dataload)

                //======================================================

                let dataselect = vSelected
                let onspl = {
                    id: vSelected[index].id,
                    parcel_status: stat,
                    parcel_in: vSelected[index].parcel_in,
                    index: vSelected[index].index,
                }
                dataselect[index] = onspl
                setts2(!ts2)
                setSelected((pre: any) => dataselect)
            }
        }
    }

    function removeDup() {
        let newArr: any[] = [];
        vAnotherParcelStat.forEach((parcel: { parcel_id: any; }) => {
            let index = newArr.findIndex(p => p.parcel_id === parcel.parcel_id);
            if (index !== -1) {
                newArr[index] = parcel;
            } else {
                newArr.push(parcel);
            }
        });
        vAnotherParcelStatTotal.push(newArr)
        setAnotherParcelStatTotal(vAnotherParcelStatTotal)
    }

    const onreturnanotherparcel = (date: Dayjs | null, dateString: string, index: any) => {
        let dataload = vAnotherParcel
        let onsplice = {
            id: vAnotherParcel[index].id,
            tracking_no: vAnotherParcel[index].tracking_no,
            note: vAnotherParcel[index].note,
            createdAt: vAnotherParcel[index].createdAt,
            updatedAt: vAnotherParcel[index].updatedAt,
            parcel_status: vAnotherParcel[index].parcel_status,
            gen_code: vAnotherParcel[index].gen_code,
            customer_name: vAnotherParcel[index].customer_name,
            received_date: null,
            return_date: dateString,
            parcel_in: vAnotherParcel[index].parcel_in,
            pic: vAnotherParcel[index].pic
        }
        dataload[index] = onsplice
        setts1(!ts1)
        setAnotherParcel((pre: any) => dataload)
    };

    const [checkedItems, setCheckedItems] = useState(
        vAnotherParcel.length > 0 && recordData.parcel_status === 'WAITING' && vAnotherParcel !== 'nodata' ? vAnotherParcel.map(() => false) : []
      );

    const handleCheckAll = (e: { target: { checked: any; }; }) => {
        const isChecked = e.target.checked;
        setAllChecked(isChecked);
        console.log(isChecked);
        setCheckedItems(vAnotherParcel.map(() => isChecked));
        vAnotherParcel.forEach((el: { id: any; }, index: any) => onChangeCBParcel(isChecked, el.id, index)); // Update individual checkboxes
    };


    const handleCheckboxChange = (isChecked: any, id :any, index: any) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = isChecked;
        setCheckedItems(newCheckedItems);
        console.log(vAnotherParcel);

        // If all checkboxes are checked individually, set the "Check All" to true
        setAllChecked(newCheckedItems.every(item => item === true));

        console.log(newCheckedItems.every(item => item === true));
        
        // Pass the change to the original handler
        onChangeCBParcel(isChecked, id, index);
    };


    const handleParcelStatusChange = (e : any) => {
        // Assuming AnotherParcel is part of the state
        const updatedParcel = vAnotherParcel.map((el: any) => {
          return { ...el, parcel_status: e }; // Creating a new object for each parcel to maintain immutability
        });
        setAnotherParcel((pre: any) => updatedParcel);
        vAnotherParcel((pre: any) => updatedParcel);
        console.log('parcel list after change status',vAnotherParcel);
      };

      const updateParcelStatus = (boolArray : any, newStatus : any) => {
        vAnotherParcel.forEach((el : any, index : any) => {
          if (boolArray[index] == true) {
            if (newStatus == "RETURN") {
                let dataload = vAnotherParcel
                let onsplice = {
                    id: vAnotherParcel[index].id,
                    tracking_no: vAnotherParcel[index].tracking_no,
                    note: vAnotherParcel[index].note,
                    createdAt: vAnotherParcel[index].createdAt,
                    updatedAt: vAnotherParcel[index].updatedAt,
                    parcel_status: newStatus,
                    gen_code: vAnotherParcel[index].gen_code,
                    customer_name: vAnotherParcel[index].customer_name,
                    received_date: null,
                    return_date: null,
                    parcel_in: vAnotherParcel[index].parcel_in,
                    pic: vAnotherParcel[index].pic
                }
                dataload[index] = onsplice
                setts1(!ts1)
                setAnotherParcel((pre: any) => dataload)

                //======================================================

                let dataselect = vSelected
                let onspl = {
                    id: vSelected[index].id,
                    parcel_status: newStatus,
                    parcel_in: vSelected[index].parcel_in,
                    index: vSelected[index].index,
                }
                dataselect[index] = onspl
                setts2(!ts2)
                setSelected((pre: any) => dataselect)
            } else if (newStatus == "RECEIVED") {
                let dataload = vAnotherParcel
                let onsplice = {
                    id: vAnotherParcel[index].id,
                    tracking_no: vAnotherParcel[index].tracking_no,
                    note: vAnotherParcel[index].note,
                    createdAt: vAnotherParcel[index].createdAt,
                    updatedAt: vAnotherParcel[index].updatedAt,
                    parcel_status: newStatus,
                    gen_code: vAnotherParcel[index].gen_code,
                    customer_name: vAnotherParcel[index].customer_name,
                    received_date: new Date(),
                    return_date: null,
                    parcel_in: vAnotherParcel[index].parcel_in,
                    pic: vAnotherParcel[index].pic
                }
                dataload[index] = onsplice
                setts1(!ts1)
                setAnotherParcel((pre: any) => dataload)

                //======================================================

                let dataselect = vSelected
                let onspl = {
                    id: vSelected[index].id,
                    parcel_status: newStatus,
                    parcel_in: vSelected[index].parcel_in,
                    index: vSelected[index].index,
                }
                dataselect[index] = onspl
                setts2(!ts2)
                setSelected((pre: any) => dataselect)
            } else if (newStatus == "WAITING") {
                let dataload = vAnotherParcel
                let onsplice = {
                    id: vAnotherParcel[index].id,
                    tracking_no: vAnotherParcel[index].tracking_no,
                    note: vAnotherParcel[index].note,
                    createdAt: vAnotherParcel[index].createdAt,
                    updatedAt: vAnotherParcel[index].updatedAt,
                    parcel_status: newStatus,
                    gen_code: vAnotherParcel[index].gen_code,
                    customer_name: vAnotherParcel[index].customer_name,
                    received_date: null,
                    return_date: null,
                    parcel_in: vAnotherParcel[index].parcel_in,
                    pic: vAnotherParcel[index].pic
                }
                dataload[index] = onsplice
                setts1(!ts1)
                setAnotherParcel((pre: any) => dataload)

                //======================================================

                let dataselect = vSelected
                let onspl = {
                    id: vSelected[index].id,
                    parcel_status: newStatus,
                    parcel_in: vSelected[index].parcel_in,
                    index: vSelected[index].index,
                }
                dataselect[index] = onspl
                setts2(!ts2)
                setSelected((pre: any) => dataselect)
            }

          }
        });
      

      };


    return (
        <Edit
            saveButtonProps={saveButtonProps}
            pageHeaderProps={{
                onBack(e?) {
                    localStorage.setItem("receiverID", "")
                    replace("")
                },
                extra: <></>
            }}
        >
            <List
                title={t("parcel-list.title-edit")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("parcel-list.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}

            />
            <Form {...formProps} layout="vertical"
                onAbort={() => {
                    localStorage.setItem("receiverID", "");
                }}
                onFinish={(values: any) => {
                    // removeDup();
                    values.updateBy = user_data.user_profile.id
                    values.parcel_status = vSelStat

                    updateAnotherParcel();

                    console.log('onFin received', dayjs(vRecivedToday).toISOString())

                    if (profileIdScanned?.total > 0) {
                        localStorage.setItem("receiverID", "");
                        values.parcel_status = vSelStat
                        // values.received_date = vRecivedToday
                        values.received_date = dayjs(vRecivedToday).toISOString()
                        values.received_user = vProfileID
                    } else if (vSelStat == "RETURN") {
                        values.return_date = vReturnDate
                        values.returnBy = user_data.user_profile.id
                    } else if (vSelStat == "RECEIVED") {
                        values.received_date = dayjs(vRecivedToday).toISOString()
                        values.customer_name = vAnotherCustomer
                        // console.log('values kkkkkkkkkk', values)
                    }
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={7}>
                                    <Form.Item
                                        name="tracking_no"
                                        label="Tracking No."
                                    >
                                        <Input disabled></Input>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={17}>
                                    <Form.Item
                                        // label={t("policy.policy_name")} 
                                        label="Note"
                                        name="note"
                                    // rules={[{required: true, message: 'Please enter parcel box size name (EN).'},]}
                                    >
                                        <Input onChange={(e) => setNote1(e.target.value)}  />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={7}>
                                    <Form.Item
                                        name="parcel_statusx"
                                        label="Status"
                                    >
                                        {
                                            // vSelStat === "RECEIVED" || vSelStat === "RETURN" ?

                                            profileIdScanned.total > 0 ?
                                                <>
                                                    <Select
                                                        placeholder="Parcel Status"
                                                        size="middle"
                                                        onClear={() => setSelStat(undefined)}
                                                        onChange={(e) =>{
                                                             setSelStat(e)
                                                             const newCheckedItems = [...checkedItems];
                                                                
                                                             
                                                            if(newCheckedItems.every(item => item === true) == true) {
                                                                
                                                                handleParcelStatusChange(e); 
                                                            }
                                                            }}
                                                        // value={vSelStat}
                                                        // defaultValue={vSelStat}
                                                        // defaultValue={"WAITING"}
                                                        value="RECEIVED"
                                                        defaultValue="RECEIVED"
                                                        disabled
                                                    >
                                                        <Option value="WAITING">พัสดุรอรับ</Option>
                                                        <Option value="RECEIVED">พัสดุรับแล้ว</Option>
                                                        <Option value="RETURN">พัสดุส่งกลับ</Option>
                                                    </Select>
                                                </>
                                                :

                                                recordData.parcel_status === "RECEIVED" || recordData.parcel_status === "RETURN" ?
                                                    <>
                                                        <div style={{ visibility: "hidden", height: "0px" }}>{vSelStat}</div>
                                                        <Select
                                                            placeholder="Parcel Status"
                                                            size="middle"
                                                            onClear={() => setSelStat(undefined)}
                                                            onChange={(e) => setSelStat(e)}
                                                            value={vSelStat}
                                                            defaultValue={vSelStat}
                                                            // defaultValue={receiverID ? "RECEIVED" : vSelStat}
                                                            // defaultValue={profileIdScanned.total > 0 ? "RECEIVED" : vSelStat}
                                                            // defaultValue={"WAITING"}
                                                            
                                                        >
                                                            <Option value="WAITING">พัสดุรอรับ</Option>
                                                            <Option value="RECEIVED">พัสดุรับแล้ว</Option>
                                                            <Option value="RETURN">พัสดุส่งกลับ</Option>
                                                        </Select>
                                                    </>
                                                    :
                                                    <Select
                                                        placeholder="Parcel Status"
                                                        size="middle"
                                                        // allowClear
                                                        onClear={() => setSelStat(undefined)}
                                                        onChange={(e) =>{
                                                            setSelStat(e)
                                                            const newCheckedItems = [...checkedItems];
                                                               
                                                            if(newCheckedItems.length != 0) {
                                                                if(newCheckedItems.every(item => item === true) == true) {
                                                                    console.log(newCheckedItems);
                                                                    vAnotherParcel.forEach((el: { id: any; parcel_status: string; }, index: any)=>{
                                                                     if (e == "RETURN") {
                                                                         let dataload = vAnotherParcel
                                                                         let onsplice = {
                                                                             id: vAnotherParcel[index].id,
                                                                             tracking_no: vAnotherParcel[index].tracking_no,
                                                                             note: vAnotherParcel[index].note,
                                                                             createdAt: vAnotherParcel[index].createdAt,
                                                                             updatedAt: vAnotherParcel[index].updatedAt,
                                                                             parcel_status: e,
                                                                             gen_code: vAnotherParcel[index].gen_code,
                                                                             customer_name: vAnotherParcel[index].customer_name,
                                                                             received_date: null,
                                                                             return_date: null,
                                                                             parcel_in: vAnotherParcel[index].parcel_in,
                                                                             pic: vAnotherParcel[index].pic
                                                                         }
                                                                         dataload[index] = onsplice
                                                                         setts1(!ts1)
                                                                         setAnotherParcel((pre: any) => dataload)
                                                         
                                                                         //======================================================
                                                         
                                                                         let dataselect = vSelected
                                                                         let onspl = {
                                                                             id: vSelected[index].id,
                                                                             parcel_status: e,
                                                                             parcel_in: vSelected[index].parcel_in,
                                                                             index: vSelected[index].index,
                                                                         }
                                                                         dataselect[index] = onspl
                                                                         setts2(!ts2)
                                                                         setSelected((pre: any) => dataselect)
                                                                     } else if (e == "RECEIVED") {
                                                                         let dataload = vAnotherParcel
                                                                         let onsplice = {
                                                                             id: vAnotherParcel[index].id,
                                                                             tracking_no: vAnotherParcel[index].tracking_no,
                                                                             note: vAnotherParcel[index].note,
                                                                             createdAt: vAnotherParcel[index].createdAt,
                                                                             updatedAt: vAnotherParcel[index].updatedAt,
                                                                             parcel_status: e,
                                                                             gen_code: vAnotherParcel[index].gen_code,
                                                                             customer_name: vAnotherParcel[index].customer_name,
                                                                             received_date: new Date(),
                                                                             return_date: null,
                                                                             parcel_in: vAnotherParcel[index].parcel_in,
                                                                             pic: vAnotherParcel[index].pic
                                                                         }
                                                                         dataload[index] = onsplice
                                                                         setts1(!ts1)
                                                                         setAnotherParcel((pre: any) => dataload)
                                                         
                                                                         //======================================================
                                                         
                                                                         let dataselect = vSelected
                                                                         let onspl = {
                                                                             id: vSelected[index].id,
                                                                             parcel_status: e,
                                                                             parcel_in: vSelected[index].parcel_in,
                                                                             index: vSelected[index].index,
                                                                         }
                                                                         dataselect[index] = onspl
                                                                         setts2(!ts2)
                                                                         setSelected((pre: any) => dataselect)
                                                                     } else if (e == "WAITING") {
                                                                         let dataload = vAnotherParcel
                                                                         let onsplice = {
                                                                             id: vAnotherParcel[index].id,
                                                                             tracking_no: vAnotherParcel[index].tracking_no,
                                                                             note: vAnotherParcel[index].note,
                                                                             createdAt: vAnotherParcel[index].createdAt,
                                                                             updatedAt: vAnotherParcel[index].updatedAt,
                                                                             parcel_status: e,
                                                                             gen_code: vAnotherParcel[index].gen_code,
                                                                             customer_name: vAnotherParcel[index].customer_name,
                                                                             received_date: null,
                                                                             return_date: null,
                                                                             parcel_in: vAnotherParcel[index].parcel_in,
                                                                             pic: vAnotherParcel[index].pic
                                                                         }
                                                                         dataload[index] = onsplice
                                                                         setts1(!ts1)
                                                                         setAnotherParcel((pre: any) => dataload)
                                                         
                                                                         //======================================================
                                                         
                                                                         let dataselect = vSelected
                                                                         let onspl = {
                                                                             id: vSelected[index].id,
                                                                             parcel_status: e,
                                                                             parcel_in: vSelected[index].parcel_in,
                                                                             index: vSelected[index].index,
                                                                         }
                                                                         dataselect[index] = onspl
                                                                         setts2(!ts2)
                                                                         setSelected((pre: any) => dataselect)
                                                                     }
                                                                    });
                                                                 //    vSelected.forEach((el: { id: any; parcel_status: string; }, index: any) => el.parcel_status = e); 
                                                                }else{
                                                                     console.log('not all true : ', newCheckedItems);
                                                                     updateParcelStatus(newCheckedItems, e);
                                                                }
                                                            }
                                                          
                                                           }}
                                                        // value={vSelStat}
                                                        // defaultValue={vSelStat}
                                                        defaultValue={"WAITING"}
                                                        value={"WAITING"}
                                                    // defaultValue="RECEIVED"
                                                    >
                                                        <Option value="WAITING">พัสดุรอรับ</Option>
                                                        <Option value="RECEIVED">พัสดุรับแล้ว</Option>
                                                        <Option value="RETURN">พัสดุส่งกลับ</Option>
                                                    </Select>
                                            // <>xxxxxxxxxx</>
                                        }

                                    </Form.Item>
                                </Col>

                                {profileIdScanned.total > 0 ?
                                    <>
                                        <Col span={7}>
                                            <Form.Item label="Received Date">
                                                <Input value={vRecivedToday} defaultValue={vRecivedToday} disabled></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                label="Receiver Name"
                                                name="user_profiles"
                                            // rules={[{required: true, message: 'Please select receiver'},]}
                                            >
                                                {
                                                    <Select placeholder={t("select.sel")}
                                                        onSelect={(value: any, label: any) => {
                                                            setProfileID(value)
                                                        }}
                                                        defaultValue={profileIdScanned.data[0].id}
                                                        disabled
                                                    >
                                                        {
                                                            profileIdScanned !== undefined ?
                                                                profileIdScanned.data !== undefined ?
                                                                    <>
                                                                        {
                                                                            profileIdScanned.data.map((item: any, key: any) => {
                                                                                // console.log("user data xxxxxxx", item);
                                                                                return <>
                                                                                    <Option
                                                                                        value={item.id}
                                                                                    // key={item.user_profile.id}
                                                                                    >
                                                                                        {/* {item.user_profile ? item.user_profile.first_name : ''}{' '}{item.user_profile ? item.user_profile.last_name : ''} */}
                                                                                        {item.first_name}{' '}{item.last_name}
                                                                                    </Option>
                                                                                </>
                                                                            })
                                                                        }
                                                                    </>
                                                                    : <></>
                                                                : <></>
                                                        }
                                                    </Select>
                                                }
                                            </Form.Item>
                                        </Col>
                                    </>
                                    :

                                    // ถ้าข้อมูลหลังบ้านเป็น received แล้ว โชว์ textbox บอกวันเวลาที่รับไปแทน
                                    recordData.parcel_status == "RECEIVED" ?
                                        <>
                                            <Col span={7}>
                                                <Form.Item label="Received Date">
                                                    <Input value={vReceivedDate} defaultValue={vReceivedDate} disabled></Input>
                                                </Form.Item>
                                            </Col>

                                            <Col span={7}>
                                                <Form.Item
                                                    label="Receiver Name"
                                                    name="Receiver_name"
                                                >
                                                    <div style={{ visibility: "hidden", height: "0px" }}>{vReceiverFirstName}</div>
                                                    <Input
                                                        // value={vReceiverFirstName + " " + vReceiverLastName}
                                                        // defaultValue={vReceiverFirstName + " " + vReceiverLastName}
                                                        value={vReceiverFirstName}
                                                        defaultValue={vReceiverFirstName}
                                                        // value={recordData.received_user !== null ? recordData.received_user.first_name + " " +  recordData.received_user.last_name : '-'}
                                                        // value={recordData.received_user !== null ? recordData.received_user?.first_name : '-'}
                                                        disabled
                                                    >
                                                    </Input>
                                                </Form.Item>
                                            </Col>
                                        </>
                                        :
                                        vSelStat == "RECEIVED" ?
                                            <>
                                                <Col span={7}>
                                                    <Form.Item
                                                        label="Received Date"
                                                        // name = "date"
                                                        name="received_date"
                                                        rules={[
                                                            { required: true, message: 'Please select date and time.' },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            style={{ width: "100%" }}
                                                            onChange={(date, dateString) => receivedDateHandle(date, dateString, 1)}
                                                            showTime
                                                            format={format}
                                                            defaultValue={dayjs()}
                                                            disabledDate={(current) => {
                                                                // return (
                                                                //     moment().add(-4, "days") >= current 
                                                                //     ||
                                                                //     moment().add(0, "month") <= current
                                                                // );
                                                                return (
                                                                    dayjs().subtract(4, "days").isAfter(current) ||
                                                                    dayjs().add(0, "month").isBefore(current)
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}>
                                                    {profileIdScanned?.total > 0 ?
                                                        <Form.Item
                                                            label="Receiver Name"
                                                            name="user_profiles"
                                                            rules={[{ required: true, message: 'Please select receiver' },]}
                                                        >

                                                            <Select placeholder={t("select.sel")}
                                                                onSelect={(value: any, label: any) => {
                                                                    setProfileID(value)
                                                                }}
                                                                defaultValue={profileIdScanned.data[0].id}
                                                                disabled
                                                            >
                                                                {
                                                                    profileIdScanned !== undefined ?
                                                                        profileIdScanned.data !== undefined ?
                                                                            <>
                                                                                {
                                                                                    profileIdScanned.data.map((item: any, key: any) => {
                                                                                        // console.log("user data xxxxxxx", item);
                                                                                        return <>
                                                                                            <Option
                                                                                                value={item.id}
                                                                                            // key={item.user_profile.id}
                                                                                            >
                                                                                                {/* {item.user_profile ? item.user_profile.first_name : ''}{' '}{item.user_profile ? item.user_profile.last_name : ''} */}
                                                                                                {item.first_name}{' '}{item.last_name}
                                                                                            </Option>
                                                                                        </>
                                                                                    })
                                                                                }
                                                                            </>
                                                                            : <></>
                                                                        : <></>
                                                                }
                                                            </Select>

                                                        </Form.Item>
                                                        :
                                                        <Form.Item
                                                            label="Receiver Name"
                                                        >
                                                            <Input placeholder="Another name"
                                                                value={vAnotherCustomer}
                                                                onChange={(e) => setAnotherCustomer(e.target.value)}
                                                            // value={vReasonRejectOwner} 
                                                            />
                                                        </Form.Item>
                                                    }
                                                </Col>
                                            </>
                                            :
                                            recordData.parcel_status == "RETURN" ?
                                                <>
                                                    <Col span={7}>
                                                        <Form.Item label="Return Date">
                                                            <Input value={vReturnDate} disabled></Input>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                                :
                                                vSelStat == "RETURN" &&
                                                <>
                                                    <Col span={7}>
                                                        <Form.Item label="Return Date"
                                                            name="date"
                                                            rules={[
                                                                { required: true, message: 'Please select date and time.' },
                                                            ]}
                                                        >
                                                            <DatePicker
                                                                onChange={(date, dateString) => {
                                                                    returnDateHandle(date, dateString, 1);
                                                                    // const newCheckedItems = [...checkedItems];
                                                               
                                                                    // if(newCheckedItems.length != 0) {
                                                                    //     if(newCheckedItems.every(item => item === true) == true) {
                                                                    //         console.log('true');
                                                                    //         setReturnDate(date); // Update the state here
                                                                    //         returnDateHandle(date, dateString, 1);
                                                                    //         vAnotherParcel.forEach((el: { id: any; parcel_status: string; }, index: any)=>{
                                                                    //             onreturnanotherparcel(date, dateString, index);
                                                                    //         });
                                                                    //     }else{
                                                                            
                                                                    //         returnDateHandle(date, dateString, 1);
                                                                    //     }
                                                                    
                                                                    // }else{
                                                                    //     returnDateHandle(date, dateString, 1);
                                                                    // }
                                                                }}
                                                                showTime
                                                                format={format}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                }
                            </Row>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Image preview={true} src={recordData?.pic === null || recordData?.pic === undefined ? "https://lscmain.s3.ap-southeast-1.amazonaws.com/parcel_icon_69b8555a2b.png?updated_at=2022-12-13T03:51:38.670Z" : recordData?.pic[0].url} width={"100%"} height={"19vh"} style={{ height: "100%", objectFit: "cover", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius: "10px" }} />

                        </Card>
                    </Col>
                </Row>
                <div style={{ marginBottom: '1%' }} />
                {
                    vAnotherParcel !== "nodata" && vAnotherParcel && vAnotherParcel.length > 0 ?
                        <>
                            <div style={{ fontSize: '20px' }}>รายการพัสดุอื่น ๆ</div>
                            <div style={{ fontSize: '20px' }}> <Checkbox style={{ marginRight: '10px' }} checked={allChecked}
                                onChange={handleCheckAll} />เลือกทั้งหมด</div>


                            {(vAnotherParcel || []).map((el: any, index: any) => {
                                return <div key={index}>
                                    <div style={{ marginBottom: '1%' }} />
                                    <Row>
                                        <Col span={12}>
                                            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                                <Row>
                                                    <Col span={6} />
                                                    <Col span={4}>
                                                        Tracking no:
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col span={4}>
                                                        Note:
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col span={4}>
                                                        Receiver name:
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={1}>
                                                        <Checkbox checked={checkedItems[index]} key={el.id} value={el.id} onChange={(e) => handleCheckboxChange(e?.target?.checked, el.id, index)} />
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col span={3}>
                                                        <Image
                                                            src={el?.pic === null || el?.pic === undefined ? "https://lscmain.s3.ap-southeast-1.amazonaws.com/parcel_icon_69b8555a2b.png?updated_at=2022-12-13T03:51:38.670Z" : el?.pic[0].url}
                                                            width={"100%"}
                                                            style={{ objectFit: "cover", borderRadius: "10px" }}
                                                        />
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col span={4} style={{ wordWrap: 'break-word' }}>
                                                        {el.tracking_no}
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col span={4}>
                                                        {el.note}
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col span={4}>
                                                        {
                                                            el.parcel_in.user_profile ?
                                                                <>
                                                                    {el.parcel_in.user_profile.first_name}{' '}{el.parcel_in.user_profile.last_name}
                                                                </>
                                                                :
                                                                <>
                                                                    {el.parcel_in.customer_name}
                                                                </>
                                                        }
                                                    </Col>
                                                </Row>

                                                <div style={{ float: "right", width: vAnotherParcel[index]?.parcel_status == "RETURN" && vSelected[index]?.parcel_status == "RETURN" ? "300px" : "130px" }}>
                                                    <Row gutter={6}>
                                                        <Col span={vAnotherParcel[index]?.parcel_status == "RETURN" && vSelected[index]?.parcel_status == "RETURN" ? 10 : 24}>
                                                            <Select
                                                                placeholder="Parcel Status"
                                                                size="middle"
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => (handleStatChange(el.id, e, el.parcel_in.user_profile, el.parcel_in.customer_name, index))}
                                                                // defaultValue={"WAITING"}
                                                                value={el.parcel_status} // add for change data when change status
                                                            >
                                                                <Option value="WAITING">พัสดุรอรับ</Option>
                                                                <Option value="RECEIVED">พัสดุรับแล้ว</Option>
                                                                <Option value="RETURN">พัสดุส่งกลับ</Option>
                                                            </Select>
                                                        </Col>
                                                        {vAnotherParcel[index]?.parcel_status == "RETURN" && vSelected[index]?.parcel_status == "RETURN" &&
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    name="returndate"
                                                                    rules={[
                                                                        { required: true, message: 'select date & time.' },
                                                                    ]}
                                                                >
                                                                    <DatePicker
                                                                        onChange={(date, dateString) => onreturnanotherparcel(date, dateString, index)}
                                                                        showTime
                                                                        format={format}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            })
                            }



                        </> : null
                }
            </Form>
        </Edit>
    );
};