/* eslint-disable */
import {
  Breadcrumb,
  Col,
  Edit,
  Create,
  Form,
  Input,
  List,
  Row,
  Select,
  useForm,
  useSelect,
  Table,
  Button,
  Icons,
  Modal,
  Checkbox,
  Divider,
} from "@pankod/refine-antd";
import {
  HttpError,
  IResourceComponentsProps,
  useCreate,
  useGetIdentity,
  useNavigation,
  useTranslate,
  useCreateMany,
  useList,
} from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IProperties, IUnitType } from "interfaces";
import { Children, useEffect, useState } from "react";

import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import { majorCareUrl } from "../../../constants";
import { get } from "http";
import dayjs from "dayjs";

let zoneID = "64813acf678934ea3e0edff1";
let structureID = "6481f77fad20334eef199be2";
let checklistID = "6481f7ebad20334eef199be3";

let resaddZone: any = null;
let resaddStructure: any = null;
let resaddChecklist: any = null;

export const HcutempMasteredit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    let id: any;
    let arr = window.location.href.split("/");
    let templateID = arr[arr.length - 1];
    const userQuery = useGetIdentity();

    const { confirm } = Modal;
    const { replace, list} = useNavigation();
    const {data : user_data ,isLoading} = userQuery;

    const [templateName, settemplateName] = useState("");
    const [templateName_en, settemplateName_en] = useState("");

    const [tsker, settsker] = useState<boolean>(true);

    const [ismodalProp, setmodalProp] = useState(false);
    const [ismodalUnitType, setmodalUnitType] = useState(false);
    const [ismodalZone, setmodalZone] = useState(false);
    const [ismodalStructure, setmodalStructure] = useState(false);
    const [ismodalChecklist, setmodalChecklist] = useState(false);
    const [ismodaldupChecklist, setmodaldupChecklist] = useState(false);
    const [ismodaldupStructure, setmodaldupStructure] = useState(false);

    const [anotherTemplate, setanotherTemplate] = useState<any>([]);
    const [dataProp, setdataProp] = useState<any>();
    const [dataZone, setdataZone] = useState<any>();
    const [dataStructure, setdataStructure] = useState<any>();
    const [dataZonefordup, setdataZonefordup] = useState<any>();
    const [dataStructurefordup, setdataStructurefordup] = useState<any>();
    const [dataChecklist, setdataChecklist] = useState<any>();
    const [datadupStructure, setdatadupStructure] = useState<any>([]);
    const [datadupChecklist, setdatadupChecklist] = useState<any>([]);

    const [datacheckZone, setdatacheckZone] = useState<any>([]);
    const [datacheckStructure, setdatacheckStructure] = useState<any>([]);
    const [datacheckChecklist, setdatacheckChecklist] = useState<any>([]);

    const [tickerName, settickerName] = useState<boolean>(false);
    const [tickerName_en, settickerName_en] = useState<boolean>(false);
    const [waranteeAll, setwaranteeAll] = useState<any>(365);
    
    const [selProp, setselProp] = useState<any>();
    const [selPropArray, setselPropArray] = useState<any>([]);
    const [selUnittypeArray, setselUnittypeArray] = useState<any>([]);
    const [selZone, setselZone] = useState<any>();
    const [selStructure, setselStructure] = useState<any>();

    const [searchZone, setsearchZone] = useState<any>("");
    const [searchStructure, setsearchStructure] = useState<any>("");
    const [searchChecklist, setsearchChecklist] = useState<any>("");
    const [searchdupStructure, setsearchdupStructure] = useState<any>("");
    const [searchdupChecklist, setsearchdupChecklist] = useState<any>("");

    const [checkZonefordup, setcheckZonefordup] = useState<any>([]);
    const [checkStructurefordup, setcheckStructurefordup] = useState<any>([]);

    const [formCustomI] = Form.useForm();

    const Loaddata = useList<any>({ //CYBERPUNK
        resource: "user-has-units", 
        metaData: {populate: ['unit','unit.property'],},
        queryOptions:{onSuccess(data) {
        MergeData();
        getstructureAPI();
        getchecklistAPI();
        getDataTemplate();
        },}
    });

    async function MergeData(){
        let resultQuery = await axios.get(majorCareUrl + "/majorcare/getItemByType?tid=" + zoneID , { headers: { } });
        let resultloadZone = await axios.get(majorCareUrl +  `/majorcare/findAllChecklistInTemplate?id=${templateID}`,{ headers: { } });
        let datazone: any = resultQuery?.data;
        let dataLoad: any = resultloadZone?.data;
        let newData: any = [];

        for (let i = 0; i < datazone?.length; i++) { //ทำ DATA ลง TABLE
        let findZone = dataLoad?.findIndex((item:any) => item.item == datazone[i]?._id);
        let findCheckZone = datacheckZone.findIndex((item:any) => item.item_id == dataLoad[findZone]?.item); // หา ZONE ว่ามีใน ARRAY หรือเปล่า
        if(findZone !== -1 && findCheckZone == -1){
            newData.push({
                key: i + 1,
                zone: datazone[i]?.item_name,
                type: datazone[i]?.item_type_detail,
                checked: true,
                _id : dataLoad[findZone]?._id,
                item_id: dataLoad[findZone]?.item,
            })

            datacheckZone?.push({
                _id: dataLoad[findZone]?._id, //ID ของ ZONE ที่ได้จาก API หลังสร้างเสร็จ
                item_id: dataLoad[findZone]?.item //ID ของ ZONE ที่ได้จาก MASTER
            });
            setdatacheckZone((pre: any) => datacheckZone);
        }else{
            newData.push({
                key: i + 1,
                zone: datazone[i]?.item_name,
                type: datazone[i]?.item_type_detail,
                checked: false,
                _id : datazone[i]?._id,
                item_id: datazone[i]?._id,
            })
        }
        }

        let resultloadStructure = await axios.get(majorCareUrl + "/majorcare/getItemByType?tid=" + structureID , { headers: { } });
        let dataStt: any = resultloadStructure?.data;
        for (let i = 0; i < dataLoad?.length; i++) {// PUSH STRUCTURE
            if(dataLoad[i]?.type == "STRUCTURE"){
                console.log(">>> newData",newData)
                console.log(">>> dataLoad[i]",dataLoad[i])
                let findZone = newData?.findIndex((item:any) => item._id == dataLoad[i]?.parent); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                let findStructure = datacheckStructure?.findIndex((item:any) => item.item_id == dataLoad[i]?._id && item.parent == dataLoad[i]?.parent); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                console.log(">>> findStructure",findStructure)
                if(findZone !== -1 && findStructure == -1){
                    if(newData[findZone].children){
                        newData[findZone]?.children.push({
                            key: newData[findZone]?.zone + dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?._id + newData[findZone]?._id + i,//Structure
                            zone: dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_name,
                            type: dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_type_detail,
                            checked: true,
                            item_id: dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?._id,
                            _id : dataLoad[i]?._id,
                            zoneID: newData[findZone]?._id,
                            zoneName: newData[findZone]?.zone,
                            newId: String(new Date())
                        });
            
                        datacheckStructure?.push({
                            _id: dataLoad[i]?._id,
                            item_id: dataLoad[i]?.item,
                            parent: newData[findZone]?._id
                        })
                        setdatacheckStructure((pre: any) => datacheckStructure);
                    }else{
                        console.log(">>> newData[findZone]",newData[findZone])
                        newData[findZone].children = []
                        newData[findZone]?.children.push({
                            key: newData[findZone]?.zone + dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?._id + newData[findZone]?._id + i,//Structure
                            zone: dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_name,
                            type: dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_type_detail,
                            checked: true,
                            item_id: dataStt?.find((item:any) => item?._id == dataLoad[i]?.item)?._id,
                            _id : dataLoad[i]?._id,
                            zoneID: newData[findZone]?._id,
                            zoneName: newData[findZone]?.zone,
                            newId: String(new Date())
                        });
            
                        datacheckStructure?.push({
                            _id: dataLoad[i]?._id,
                            item_id: dataLoad[i]?.item,
                            parent: newData[findZone]?._id
                        })
                        setdatacheckStructure((pre: any) => datacheckStructure);
                    }
                }
            }
        }

        let resultloadChecklist = await axios.get(majorCareUrl + "/majorcare/getItemByType?tid=" + checklistID , { headers: { } });
        let dataChl: any = resultloadChecklist?.data;

        for (let ix = 0; ix < newData.length; ix++) {
        if(newData[ix]?.children){
            for (let i = 0; i < dataLoad?.length; i++) {// PUSH CHECKLIST
            if(dataLoad[i]?.type == "CHECKLIST"){
                let findStructure = newData[ix]?.children?.findIndex((item:any) => item._id == dataLoad[i]?.parent); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                // console.log(">>> newData[ix]?.children",newData[ix]?.children)
                // console.log(">>> dataLoad[i]?.parent",dataLoad[i]?.parent)
                // console.log(">>> findStructure",findStructure)
                if(findStructure !== -1){
                // console.log(">>> dataLoad[i]?.item",dataLoad[i])
                // console.log(">>> dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)",dataChl?.find((item:any) => item?._id == dataLoad[i]?.item))
                // console.log(">>> dataStt",dataStt)
                // console.log(">>> dataLoad[i]?.parent",dataLoad[i]?.parent)
                if(newData[ix].children[findStructure].children){
                    newData[ix].children[findStructure].children.push({
                        key: dataStt?.find((item:any) => item?._id == dataLoad[i]?.parent)?.item_name + dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?._id + newData[ix]?.zone + i,//Checklist
                        zone: dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_name,
                        type: dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_type_detail,
                        checked: true,
                        item_id : dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?._id,
                        _id : dataLoad[i]?._id,
                        zoneID: newData[ix]?._id,
                        zoneName: newData[ix]?.zone,
                        structureID: newData[ix]?.children[findStructure]?._id,
                        structureName: newData[ix]?.children[findStructure]?.zone,
                        warantee: dataLoad[i]?.warantee ? dataLoad[i]?.warantee : null,
                        newId: newData[ix]?._id + newData[ix].children[findStructure]?._id
                    })
        
                    datacheckChecklist?.push({
                        _id: dataLoad[i]?._id,
                        item_id: dataLoad[i]?.item,
                        parent: newData[ix]?.children[findStructure]?._id,
                        in_zone: newData[ix]?._id,
                    })
                    setdatacheckChecklist((pre: any) => datacheckChecklist);
                }else{
                    newData[ix].children[findStructure].children = [];
                    newData[ix].children[findStructure].children.push({
                        key: dataStt?.find((item:any) => item?._id == dataLoad[i]?.parent)?.item_name + dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?._id + newData[ix]?.zone + i,//Checklist
                        zone: dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_name,
                        type: dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?.item_type_detail,
                        checked: true,
                        item_id : dataChl?.find((item:any) => item?._id == dataLoad[i]?.item)?._id,
                        _id : dataLoad[i]?._id,
                        zoneID: newData[ix]?._id,
                        zoneName: newData[ix]?.zone,
                        structureID: newData[ix]?.children[findStructure]?._id,
                        structureName: newData[ix]?.children[findStructure]?.zone,
                        warantee: dataLoad[i]?.warantee ? dataLoad[i]?.warantee : null,
                        newId: newData[ix]?._id + newData[ix].children[findStructure]?._id
                    })
        
                    datacheckChecklist?.push({
                        _id: dataLoad[i]?._id,
                        item_id: dataLoad[i]?.item,
                        parent: newData[ix]?.children[findStructure]?._id,
                        in_zone: newData[ix]?._id,
                    })
                    setdatacheckChecklist((pre: any) => datacheckChecklist);
                }
                }
            }
            }
        }
        }
        // console.log(">>> newData",newData)
        setdataZone((pre: any) => newData);

        setdataZonefordup(resultQuery?.data?.map((item:any,index:any) => {
        return {
            key: index + 1,
            zone: item?.item_name,
            type: item?.item_type_detail,
            _id : item?._id,
            item_id: item?._id,
        }
        }));

        let countTemplate: any = await axios.get(majorCareUrl + "/majorcare/findAllTemplate" , { headers: { }});

        setanotherTemplate(countTemplate?.data?.map((item:any,index:any) => {
            return {
                key: index,
                template_name: item?.template_name
            }
        }));
    }

    const getDataTemplate = async () => {
        let newData: any = [];

        let dataProperty: any = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: majorCareUrl +  `/majorcare/findAllProperty`,
        headers: {},
        });

        // API PROPERTY ที่ผูกกับ Template
        let dataTemplate: any = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: majorCareUrl +  `/majorcare/findOneTemplate?id=${templateID}`,
        headers: {},
        data: "",
        });

        

        if (!!dataTemplate?.data && !!dataTemplate?.data?.template_name) {
        settemplateName(dataTemplate?.data?.template_name);
        settemplateName_en(dataTemplate?.data?.template_name_en ? dataTemplate?.data?.template_name_en : "");
        formCustomI?.setFieldsValue({
            name_th: dataTemplate?.data?.template_name,
            name_en: dataTemplate?.data?.template_name_en ? dataTemplate?.data?.template_name_en : "",
        })
        }

        for (let index = 0; index < dataProperty?.data?.length; index++) {
        let findProp = dataTemplate?.data?.property?.findIndex((item:any) => item == dataProperty?.data[index]?._id);
        if(findProp !== -1){
            newData.push({
            property_id : dataProperty?.data[index]?.property_id,
            property_name : dataProperty?.data[index]?.property_name,
            property_name_en: dataProperty?.data[index]?.property_name_en,
            is_active: dataProperty?.data[index]?.is_active,
            is_default: dataProperty?.data[index]?.is_default,
            __v: dataProperty?.data[index]?.__v,
            _id: dataProperty?.data[index]?._id,
            checked: true
            })

            selPropArray?.push({id: dataProperty?.data[index]?._id, property_id: dataProperty?.data[index]?.property_id});
        }else{
            newData.push({
            property_id : dataProperty?.data[index]?.property_id,
            property_name : dataProperty?.data[index]?.property_name,
            property_name_en: dataProperty?.data[index]?.property_name_en,
            is_active: dataProperty?.data[index]?.is_active,
            is_default: dataProperty?.data[index]?.is_default,
            __v: dataProperty?.data[index]?.__v,
            _id: dataProperty?.data[index]?._id,
            checked: false
            })
        }
        }
        setselPropArray((pre: any) => selPropArray);
        
        if(newData){
            let unitType = dataTemplate?.data?.unit_type || [];
            let dataunitarrray:any = [];
            for (let i = 0; i < newData.length; i++) {
                if(newData[i]?.checked == true){
                    let resAllUnitTypeByProp: any = await axios.request({
                        method: "get",
                        maxBodyLength: Infinity,
                        url: majorCareUrl + `/majorcare/findAllUnitTypeByProp?pid=${newData[i]?._id}`,
                        headers: {},
                    });
        
                    if(unitType.length > 0){
                        for (let ix = 0; ix < unitType?.length; ix++) {
                            let findProp = resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]});
                            if(findProp){
                                if(newData[i].unit_type){
                                    newData[i].unit_type?.push({
                                        property: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.property,
                                        unit_type_id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_id,
                                        unit_type_name: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_name,
                                        unit_type_name_en: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_name_en,
                                        is_active: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.is_active,
                                        is_default: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.is_default,
                                        __v: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.__v,
                                        _id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?._id,
                                        checked: true
                                    });
            
                                    dataunitarrray?.push({
                                        id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?._id,
                                        unitType_id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_id
                                    });
                                }else{
                                    newData[i].unit_type = [];
                                    newData[i].unit_type?.push({
                                        property: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.property,
                                        unit_type_id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_id,
                                        unit_type_name: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_name,
                                        unit_type_name_en: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_name_en,
                                        is_active: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.is_active,
                                        is_default: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.is_default,
                                        __v: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.__v,
                                        _id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?._id,
                                        checked: true
                                    });
            
                                    dataunitarrray?.push({
                                        id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?._id,
                                        unitType_id: resAllUnitTypeByProp?.data?.find((item: any) => {return item?._id === unitType[ix]})?.unit_type_id
                                    });
                                }
                            }
                        }
                    }
        
                    for (let ix = 0; ix < resAllUnitTypeByProp?.data?.length; ix++) {
                        if(newData[i]?.unit_type){
                            let findProp = newData[i]?.unit_type?.findIndex((item: any) => {return item?._id === resAllUnitTypeByProp?.data[ix]?._id});
                            if(findProp == -1){
                                newData[i]?.unit_type?.push({
                                    property: resAllUnitTypeByProp?.data[ix]?.property,
                                    unit_type_id: resAllUnitTypeByProp?.data[ix]?.unit_type_id,
                                    unit_type_name: resAllUnitTypeByProp?.data[ix]?.unit_type_name,
                                    unit_type_name_en: resAllUnitTypeByProp?.data[ix]?.unit_type_name_en,
                                    is_active: resAllUnitTypeByProp?.data[ix]?.is_active,
                                    is_default: resAllUnitTypeByProp?.data[ix]?.is_default,
                                    __v: resAllUnitTypeByProp?.data[ix]?.__v,
                                    _id: resAllUnitTypeByProp?.data[ix]?._id,
                                    checked: false
                                })
                            }
                        }else{
                            let findProp = newData?.findIndex((item: any) => {return item?._id === resAllUnitTypeByProp?.data[ix]?.property});
                            if(findProp !== -1){
                                newData[i].unit_type = [];
                                newData[i]?.unit_type?.push({
                                    property: resAllUnitTypeByProp?.data[ix]?.property,
                                    unit_type_id: resAllUnitTypeByProp?.data[ix]?.unit_type_id,
                                    unit_type_name: resAllUnitTypeByProp?.data[ix]?.unit_type_name,
                                    unit_type_name_en: resAllUnitTypeByProp?.data[ix]?.unit_type_name_en,
                                    is_active: resAllUnitTypeByProp?.data[ix]?.is_active,
                                    is_default: resAllUnitTypeByProp?.data[ix]?.is_default,
                                    __v: resAllUnitTypeByProp?.data[ix]?.__v,
                                    _id: resAllUnitTypeByProp?.data[ix]?._id,
                                    checked: false
                                })
                            }
                        }
                    }

                    setselUnittypeArray((pre: any) => dataunitarrray);
                }
            }
        }
        setdataProp((pre:any)=> newData);
    };
    
    async function ZoneAPI(idzone:any, mode:any){
        if(mode == "ADD"){
            let data = {
                "template": templateID,
                "type":"ZONE",
                "item":idzone
            }

            let res = await axios.post(majorCareUrl + "/majorcare/createTemplateChecklist", data , { headers: { } });
            console.log(">>> เพิ่ม ZONE",res)
            return res?.data
        }else if(mode == "DEL"){
            let res = await axios.put(majorCareUrl + "/majorcare/removeTemplateChecklist?id=" + idzone , { headers: { } });
            console.log(">>> ลบ ZONE",res)
        }
    }

    async function StructureAPI(idstructure:any, parent:any, mode:any){
        if(mode == "ADD"){
            let data = {
                "template": templateID,
                "type":"STRUCTURE",
                "item":idstructure,
                "parent":parent,
            }

            let res = await axios.post(majorCareUrl + "/majorcare/createTemplateChecklist", data , { headers: { } });
            console.log(">>> เพิ่ม STRUCTURE",res)
            resaddStructure = res?.data;
            return res?.data
        }else if(mode == "DEL"){
            let res = await axios.put(majorCareUrl + "/majorcare/removeTemplateChecklist?id=" + idstructure , { headers: { } });
            console.log(">>> ลบ STRUCTURE",res)
        }
    }

    async function ChecklistAPI(idchecklist:any, parent:any, mode:any, warantee:any){
        if(mode == "ADD"){
            let data = {
                "template": templateID,
                "type":"CHECKLIST",
                "item":idchecklist,
                "parent":parent,
                "warantee":warantee,
            }
            // console.log("warantee",warantee)
            let res = await axios.post(majorCareUrl + "/majorcare/createTemplateChecklist", data , { headers: { } });
            console.log(">>> เพิ่ม CHECKLIST",res)
            return res?.data
        }else if(mode == "DEL"){
            let res = await axios.put(majorCareUrl + "/majorcare/removeTemplateChecklist?id=" + idchecklist , { headers: { } });
            console.log(">>> ลบ CHECKLIST",res)
        }
    }

    async function getstructureAPI(){
        let resultQuery = await axios.get(majorCareUrl + "/majorcare/getItemByType?tid=" + structureID , { headers: { } });

        setdataStructure(resultQuery?.data?.map((item:any,index:any) => {
            return {
                    key: index + 1,
                    item_desc: item?.item_desc,
                    item_name: item?.item_name,
                    item_type: item?.item_type,
                    item_type_detail: item?.item_type_detail,
                    __v: item?.__v,
                    _id: item?._id,
                    is_active: item?.is_active,
                    is_default: item?.is_default,
                }
        }))

        setdataStructurefordup(resultQuery?.data?.map((item:any,index:any) => {
            return {
                    key: index + 1,
                    item_desc: item?.item_desc,
                    item_name: item?.item_name,
                    item_type: item?.item_type,
                    item_type_detail: item?.item_type_detail,
                    __v: item?.__v,
                    _id: item?._id,
                    is_active: item?.is_active,
                    is_default: item?.is_default,
                }
        }))
    }
    
    async function getchecklistAPI(){
        let resultQuery = await axios.get(majorCareUrl + "/majorcare/getItemByType?tid=" + checklistID , { headers: { } });

        setdataChecklist(resultQuery?.data?.map((item:any,index:any) => {
            return {
                    key: index + 1,
                    item_desc: item?.item_desc,
                    item_name: item?.item_name,
                    item_type: item?.item_type,
                    item_type_detail: item?.item_type_detail,
                    __v: item?.__v,
                    _id: item?._id,
                    is_active: item?.is_active,
                    is_default: item?.is_default,
                }
        }))
    }

    async function getUnitTypeAPI(pid:any){
        let resultQuery = await axios.get(majorCareUrl + "/majorcare/findAllUnitTypeByProp?pid=" + pid , { headers: { } });

        let newData:any = [];
        for (let i = 0; i < dataProp.length; i++) {
            if(dataProp[i]._id === pid){
                if(dataProp[i]?.unit_type){
                    newData.push(dataProp[i])
                }else{
                    newData.push({
                        property_id : dataProp[i]?.property_id,
                        property_name : dataProp[i]?.property_name,
                        property_name_en: dataProp[i]?.property_name_en,
                        is_active: dataProp[i]?.is_active,
                        is_default: dataProp[i]?.is_default,
                        __v: dataProp[i]?.__v,
                        _id: dataProp[i]?._id,
                        checked: dataProp[i]?.checked,
                        unit_type: 
                            resultQuery?.data?.map((item:any,index:any) => {
                                return {
                                    property: item?.property,
                                    unit_type_id: item?.unit_type_id,
                                    unit_type_name: item?.unit_type_name,
                                    unit_type_name_en: item?.unit_type_name_en,
                                    is_active: item?.is_active,
                                    is_default: item?.is_default,
                                    __v: item?.__v,
                                    _id: item?._id,
                                    checked: true
                                }
                            })
                    })
                }
            }else{
                newData.push(dataProp[i])
            }
        }

        setdataProp((pre: any) => newData);

        resultQuery?.data?.map((item:any,index:any) => {
            return selUnittypeArray?.push({
                id : item?._id,
                unitType_id: item?.unit_type_id
            })
        })
        setselUnittypeArray((pre: any) => selUnittypeArray);
    }

    async function tickernameTemplate(e:any){
        let findtemp = anotherTemplate?.filter((i:any)=> i?.template_name == String(e))
        
        if(findtemp?.length > 0){
            settickerName(true)
        }else{
            settickerName(false)
        }
        settemplateName(e)
    }

    async function tickernameTemplate_en(e:any){
        let findtemp = anotherTemplate?.filter((i:any)=> i?.template_name_en == String(e))

        if(findtemp?.length > 0){
            settickerName_en(true)
        }else{
            settickerName_en(false)
        }
        settemplateName_en(e)
    }

    interface DataType {
        key: React.ReactNode;
        zone: string;
        addOn: any;
        children?: DataType[];
        action: any;
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Zone',
            dataIndex: 'zone',
            key: 'zone',
        },
        {
            title: '',
            dataIndex: 'addOn',
            key: 'addOn',
            width: '50%',
            align: 'right',
            render: (value:any, record:any) => {
                return (
                    (record?.type?.type_name === "ZONE" || record?.type?.type_name === "STRUCTURE") ?
                    <Button style={{borderRadius: "5px",border: "1px solid #4B647E",color: "#4B647E"}}
                        onClick={()=> { 
                            {record?.type?.type_name === "ZONE" ?
                                onclickinTable("ZONE", record) :
                            record?.type?.type_name === "STRUCTURE" &&
                                onclickinTable("STRUCTURE", record) 
                            }
                        }}
                    >
                        {record?.type?.type_name === "ZONE" ?
                            "Add structure" :
                        record?.type?.type_name === "STRUCTURE" &&
                            "Add checklist"
                        }
                    </Button>
                    : record?.type?.type_name === "CHECKLIST" &&
                    <Input 
                        defaultValue={record?.warantee} 
                        value={record?.warantee} 
                        style={{width: "70px", textAlign: "center"}}
                        onChange={(e) => changeWarantee(record?.item_id, e?.target?.value, record)}
                        // onChange={(e) => setwaranteeAll(e?.target?.value)}
                        onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                    />
                );
            },
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '50px',
            render: (value:any, record:any) => {
                return (
                    <Button 
                        size="small" 
                        title="Delete" 
                        icon={<Icons.DeleteOutlined style={{color: "#DE4C4C"}}/>} 
                        style={{border: "1px solid #DE4C4C"}} 
                        onClick={() => 
                            {record?.type?.type_name === "ZONE" ?
                                showdelzoneConfirm(record?._id, record) :
                            record?.type?.type_name === "STRUCTURE" ?
                                showdelstructureConfirm(record?._id, record) :
                            record?.type?.type_name === "CHECKLIST" &&
                                showdelchecklistConfirm(record?._id, record)
                            }
                        }
                    />
                );
            },
        },
    ];

    const showdelzoneConfirm = (id: any,data:any) => {
        confirm({
            title: <div><span style={{marginRight: "5px"}}>คุณต้องการลบ</span><span style={{color: "#4B78AF"}}>{data?.zone}</span></div>,
            icon: <Icons.ExclamationCircleFilled />,
            content: <div style={{color: "rgba(0, 0, 0, 0.5)"}}>Structure type และ Checklist ด้านใน จะถูกลบด้วย</div>,
            okText: 'ลบ',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                checkedZone(id,false);
            },
            onCancel() {},
        });
    };

    const showdelstructureConfirm = (id: any,data:any) => {
        confirm({
            title: <div><span style={{marginRight: "5px"}}>คุณต้องการลบ</span><span style={{color: "#4B78AF"}}>{data?.zone}</span></div>,
            icon: <Icons.ExclamationCircleFilled />,
            content: <div style={{color: "rgba(0, 0, 0, 0.5)"}}>Checklist ด้านใน จะถูกลบด้วย</div>,
            okText: 'ลบ',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                console.log(">>> data",data)
                checkedStructure(data?.item_id, data?.zoneID, false);
            },
            onCancel() {},
        });
    };

    const showdelchecklistConfirm = (id: any,data:any) => {
        confirm({
            title: <div><span style={{marginRight: "5px"}}>คุณต้องการลบ</span><span style={{color: "#715C45"}}>{data?.zone}</span></div>,
            icon: <Icons.ExclamationCircleFilled />,
            content: <></>,
            okText: 'ลบ',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                console.log(">>> data",data)
                checkedChecklist(data?.item_id, data?.zoneID, data?.structureID, false);
                // checkedChecklist(id, false, data, "OTB");
            },
            onCancel() {},
        });
    };

    function checkedProp(id: any, checked: any, _id: any) {// ทำงานตอนเลือก PROPERTY
        let newData:any = [];

        let findProp = selPropArray?.findIndex((item:any) => item.id == _id); //หา INDEX ของ PROPERTY

        for (let i = 0; i < dataProp?.length; i++) {
            if(checked == true){
                if(dataProp[i].property_id == id){// ID ที่ส่งมาตรงกับ PROPERTY
                    dataProp[i].checked = checked;
                    newData.push(dataProp[i]);
                    getUnitTypeAPI(_id);// ยิง API ดึง DATA UNIT_TYPE
                    if(findProp == -1){//ค้นหาว่าเคยกด PROPERTY นี้รึยัง ถ้ายังให้ทำการเพิ่มเข้า ARRAY
                        selPropArray?.push({id: _id, property_id: id});
                        setselPropArray((pre: any) => selPropArray);
                    }
                }else{
                    newData.push(dataProp[i]);
                }
            }else if(checked == false){
                if(dataProp[i].property_id == id){// ID ที่ส่งมาตรงกับ PROPERTY
                    dataProp[i].checked = checked;
                    if(dataProp[i]?.unit_type){
                        for (let index = 0; index < dataProp[i]?.unit_type.length; index++) {
                            let findUnittype = selUnittypeArray.findIndex((item:any) => item.id == dataProp[i]?.unit_type[index]?._id); //หา INDEX ของ UNIT_TYPE
                            if(findUnittype !== -1){//ค้นหาว่าเคยกด PROPERTY นี้รึยัง ถ้ามีให้ลบออกจาก ARRAY
                                selUnittypeArray?.splice(findUnittype, 1);
                                setselUnittypeArray((pre: any) => selUnittypeArray);
                            }
                        }
                    }

                    newData.push(dataProp[i]);
                    if(findProp !== -1){//ค้นหาว่าเคยกด PROPERTY นี้รึยัง ถ้ามีให้ลบออกจาก ARRAY
                        selPropArray?.splice(findProp, 1);
                        setselPropArray((pre: any) => selPropArray);
                    }
                }else{
                    newData.push(dataProp[i]);
                }
            }
        }

        setdataProp((pre: any) => newData);
    }

    function checkedUnitType(id: any, idProp: any, checked: any, data: any) {// ทำงานตอนเลือก UNIT_TYPE
        let newData:any = [];
        let findUnittype = selUnittypeArray.findIndex((item:any) => item.id == id); //หา INDEX ของ UNIT_TYPE

        for (let i = 0; i < dataProp.length; i++) {
            if(checked == true){
                if(dataProp[i]._id == idProp){// ID ที่ส่งมาตรงกับ PROPERTY
                    for (let is = 0; is < dataProp[i]?.unit_type?.length; is++) {
                        if(dataProp[i].unit_type[is]._id == id){// ID ที่ส่งมาตรงกับ UNIT_TYPE
                            dataProp[i].unit_type[is].checked = checked

                            if(findUnittype == -1){//ค้นหาว่าเคยกด UNIT_TYPE นี้รึยัง ถ้ายังให้ทำการเพิ่มเข้า ARRAY
                                selUnittypeArray?.push({id: id, unitType_id: data?.unit_type_id})
                                setselUnittypeArray((pre: any) => selUnittypeArray);
                            }
                        }
                    }
                    newData.push(dataProp[i])
                }else{
                    newData.push(dataProp[i])
                }
            }else if(checked == false){
                if(dataProp[i]._id == idProp){// ID ที่ส่งมาตรงกับ PROPERTY
                    for (let is = 0; is < dataProp[i]?.unit_type?.length; is++) {
                        if(dataProp[i].unit_type[is]._id == id){// ID ที่ส่งมาตรงกับ UNIT_TYPE
                            dataProp[i].unit_type[is].checked = checked

                            if(findUnittype !== -1){//ค้นหาว่าเคยกด PROPERTY นี้รึยัง ถ้ามีให้ลบออกจาก ARRAY
                                selUnittypeArray?.splice(findUnittype, 1);
                                setselUnittypeArray((pre: any) => selUnittypeArray);
                            }
                        }
                    }
                    newData.push(dataProp[i])
                }else{
                    newData.push(dataProp[i])
                }
            }
        }

        setdataProp((pre: any) => newData);
    }

    async function checkedZone(id: any, checked: any) {//FINISH
        let newData:any = [];
        resaddZone = null;

        for (let i = 0; i < dataZone?.length; i++) {
            if(checked == true){
                let findZone = datacheckZone.findIndex((item:any) => item.item_id == id); // หา ZONE ว่ามีใน ARRAY หรือเปล่า
                if(findZone == -1 && dataZone[i]._id == id){
                    resaddZone = await ZoneAPI(id,"ADD"); // ยิง API เพื่อสร้าง ZONE
                    dataZone[i].item_id = dataZone[i]?.item_id;
                    dataZone[i]._id = resaddZone?._id; // แทนที่ค่าจาก ID ที่ดึงจาก MASTER เป็น ID จาก API
                    dataZone[i].checked = checked; // CHECKED TRUE OR FALSE
                    
                    datacheckZone?.push({
                        _id: resaddZone?._id, //ID ของ ZONE ที่ได้จาก API หลังสร้างเสร็จ
                        item_id: id //ID ของ ZONE ที่ได้จาก MASTER
                    });
                    setdatacheckZone((pre: any) => datacheckZone);

                    newData.push(dataZone[i])
                }else{
                    newData.push(dataZone[i])
                }
            }else if(checked == false){
                let findZone = datacheckZone.findIndex((item:any) => item._id == id);
                if(findZone !== -1 && dataZone[i]._id == id){
                    ZoneAPI(id,"DEL"); // ยิง API เพื่อลบ ZONE
                    
                    if(dataZone[i]?.children && dataZone[i]?.children.length > 0){ // หาก ZONE ที่ลบ มี STRUCTURE จะทำการค้นหาและลบทั้งหมด
                        for (let ix = dataZone[i]?.children?.length -1; ix >= 0; ix--) { // LOOP ลบ STRUCTURE ตามจำนวน STRUCTURE ที่มี
                            checkedStructure(dataZone[i]?.children[ix]?.item_id, dataZone[i]?.children[ix]?.zoneID, false); //เรียกฟังก์ชั่น เพื่อลบ STRUCTURE // ยิง API เพื่อลบ STRUCTURE
                        }
                    }

                    dataZone[i].item_id = dataZone[i]?.item_id;
                    dataZone[i]._id = dataZone[i]?.item_id; // แทนที่ค่าจาก ID ที่ดึงจาก API เป็น ID จาก MASTER
                    dataZone[i].checked = checked; // CHECKED TRUE OR FALSE

                    datacheckZone?.splice(findZone, 1);
                    setdatacheckZone((pre: any) => datacheckZone);

                    newData.push(dataZone[i])
                }else{
                    newData.push(dataZone[i])
                }
            }
        }

        setdataZone((pre: any) => newData);
    }

    async function checkedStructure(id: any, zoneid: any,checked: any) {//FINISH
        let newData:any = [];
        resaddStructure = null;

        for (let i = 0; i < dataZone?.length; i++) {
            if(checked == true){
                let findStructure = datacheckStructure?.findIndex((item:any) => item.item_id == id && item.parent == zoneid); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                // let findStructure = datacheckStructure?.findIndex((item:any) => item.item_id == id); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                if(findStructure == -1 && dataZone[i]._id == zoneid){
                    resaddStructure = await StructureAPI(id,dataZone[i]?._id,"ADD"); // ยิง API เพื่อสร้าง STRUCTURE
                    if(dataZone[i]?.children){
                        dataZone[i]?.children.push({
                            key: selZone?.zone + dataStructure?.find((item:any) => item?._id == id)?._id + selZone?._id + i + "mm",//Structure
                            zone: dataStructure?.find((item:any) => item?._id == id)?.item_name,
                            type: dataStructure?.find((item:any) => item?._id == id)?.item_type_detail,
                            checked: checked,
                            item_id: dataStructure?.find((item:any) => item?._id == id)?._id,
                            _id : resaddStructure?._id,
                            zoneID: dataZone[i]?._id,
                            zoneName: dataZone[i]?.zone,
                            newId: String(new Date())
                        })

                        datacheckStructure?.push({
                            _id: resaddStructure?._id,
                            item_id: id,
                            parent: dataZone[i]?._id
                        })
                        setdatacheckStructure((pre: any) => datacheckStructure);

                        //====================================================================================//

                        datadupStructure.push({
                            key: selZone?.zone + dataStructure?.find((item:any) => item?._id == id)?._id + selZone?._id + i,//Structure
                            zone: dataStructure?.find((item:any) => item?._id == id)?.item_name,
                            type: dataStructure?.find((item:any) => item?._id == id)?.item_type_detail,
                            checked: checked,
                            item_id: dataStructure?.find((item:any) => item?._id == id)?._id,
                            _id : resaddStructure?._id,
                            zoneID: dataZone[i]?._id,
                            zoneName: dataZone[i]?.zone,
                            newId: String(new Date())
                        })
                        setdatadupStructure((pre: any) => datadupStructure);
                        newData.push(dataZone[i])
                    }else{
                        dataZone[i].children = [];
                        dataZone[i].children.push({
                            key: selZone?.zone + dataStructure?.find((item:any) => item?._id == id)?._id + selZone?._id + i,//Structure
                            zone: dataStructure?.find((item:any) => item?._id == id)?.item_name,
                            type: dataStructure?.find((item:any) => item?._id == id)?.item_type_detail,
                            checked: checked,
                            item_id: dataStructure?.find((item:any) => item?._id == id)?._id,
                            _id : resaddStructure?._id,
                            zoneID: dataZone[i]?._id,
                            zoneName: dataZone[i]?.zone,
                            newId: String(new Date())
                        });

                        datacheckStructure?.push({
                            _id: resaddStructure?._id,
                            item_id: id,
                            parent: dataZone[i]?._id
                        })
                        setdatacheckStructure((pre: any) => datacheckStructure);

                        //====================================================================================//

                        datadupStructure.push({
                            key: selZone?.zone + dataStructure?.find((item:any) => item?._id == id)?._id + selZone?._id + i,//Structure
                            zone: dataStructure?.find((item:any) => item?._id == id)?.item_name,
                            type: dataStructure?.find((item:any) => item?._id == id)?.item_type_detail,
                            checked: checked,
                            item_id: dataStructure?.find((item:any) => item?._id == id)?._id,
                            _id : resaddStructure?._id,
                            zoneID: dataZone[i]?._id,
                            zoneName: dataZone[i]?.zone,
                            newId: String(new Date())
                        })
                        setdatadupStructure((pre: any) => datadupStructure);
                        newData.push(dataZone[i])
                    }
                }else{
                    newData.push(dataZone[i])
                }
            }else if(checked == false){
                let findStructure = datacheckStructure?.findIndex((item:any) => item.item_id == id && item.parent == zoneid); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                if(findStructure !== -1 && dataZone[i]._id == zoneid){
                    let structureID = datacheckStructure?.find((item:any) => item.item_id == id && item.parent == zoneid)?._id
                    StructureAPI(structureID,dataZone[i]?._id,"DEL")

                    let findIndex = dataZone[i]?.children.findIndex((item:any) => item.item_id === id);
                    if(dataZone[i]?.children[findIndex]?.children && dataZone[i]?.children[findIndex]?.children.length > 0){
                        for (let ix = dataZone[i]?.children[findIndex]?.children?.length -1; ix >= 0; ix--) {
                            checkedChecklist(
                                dataZone[i]?.children[findIndex]?.children[ix].item_id, 
                                dataZone[i]?.children[findIndex]?.children[ix].zoneID, 
                                dataZone[i]?.children[findIndex]?.children[ix].structureID,
                                false
                            )
                        }
                    }

                    dataZone[i]?.children?.splice(findIndex, 1);
                    if(dataZone[i]?.children?.length == 0){
                        dataZone[i]={
                            key: dataZone[i]?.key,
                            zone: dataZone[i]?.zone,
                            type: dataZone[i]?.type,
                            checked: dataZone[i]?.checked,
                            item_id: dataZone[i]?.item_id,
                            _id : dataZone[i]?._id,
                            newId: String(new Date())
                        }
                    }

                    datacheckStructure?.splice(findStructure, 1);
                    setdatacheckStructure((pre: any) => datacheckStructure);

                    //====================================================================================//
                    let findIndexdup = datadupStructure.findIndex((item:any) => item._id === id);
                    datadupStructure.splice(findIndexdup, 1);
                    setdatadupStructure((pre: any) => datadupStructure);

                    newData.push(dataZone[i])
                }else{
                    newData.push(dataZone[i])
                }
            }
        }

        setdataZone((pre: any) => newData);
    }

    async function checkedChecklist(id: any, zoneid: any, structureid: any, checked: any) {//FINISH
        let newData:any = [];
        resaddChecklist = null;

        for (let i = 0; i < dataZone?.length; i++) {
            if(checked == true){
                let findChecklist = datacheckChecklist?.findIndex((item:any) => item.item_id == id && item.in_zone == zoneid && item.parent == structureid); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                if(findChecklist == -1 && dataZone[i]._id == zoneid){
                    resaddChecklist = await ChecklistAPI(id,structureid,"ADD",365);
                    let findStructure = dataZone[i]?.children?.findIndex((item:any) => item._id == structureid);
                    if(findStructure !== -1){
                        if(dataZone[i]?.children[findStructure]?.children){
                            dataZone[i]?.children[findStructure].children.push({
                                key: selStructure?.zoneName + dataChecklist?.find((item:any) => item?._id == id)?._id + selStructure?.zone + i,//Checklist
                                zone: dataChecklist?.find((item:any) => item?._id == id)?.item_name,
                                type: dataChecklist?.find((item:any) => item?._id == id)?.item_type_detail,
                                checked: checked,
                                item_id : dataChecklist?.find((item:any) => item?._id == id)?._id,
                                _id : resaddChecklist?._id,
                                zoneID: dataZone[i]?._id,
                                zoneName: dataZone[i]?.zone,
                                structureID: dataZone[i]?.children[findStructure]?._id,
                                structureName: dataZone[i]?.children[findStructure]?.zone,
                                warantee: 365,
                                newId: dataZone[i]._id + dataZone[i].children[findStructure]?._id
                            })

                            datacheckChecklist?.push({
                                _id: resaddChecklist?._id,
                                item_id: id,
                                parent: structureid,
                                in_zone: zoneid,
                            })
                            setdatacheckChecklist((pre: any) => datacheckChecklist);

                            //====================================================================================//

                            datadupChecklist.push({
                                key: selStructure?.zoneName + dataChecklist?.find((item:any) => item?._id == id)?._id + selStructure?.zone + i,//Checklist
                                zone: dataChecklist?.find((item:any) => item?._id == id)?.item_name,
                                type: dataChecklist?.find((item:any) => item?._id == id)?.item_type_detail,
                                checked: checked,
                                item_id : dataChecklist?.find((item:any) => item?._id == id)?._id,
                                _id : resaddChecklist?._id,
                                zoneID: dataZone[i]?._id,
                                zoneName: dataZone[i]?.zone,
                                structureID: dataZone[i]?.children[findStructure]?._id,
                                structureName: dataZone[i]?.children[findStructure]?.zone,
                                warantee: 365,
                                newId: dataZone[i]._id + dataZone[i].children[findStructure]?._id
                            })
                            setdatadupChecklist((pre: any) => datadupChecklist);
                            newData.push(dataZone[i])
                        }else{
                            dataZone[i].children[findStructure].children = [];
                            dataZone[i].children[findStructure].children.push({
                                key: selStructure?.zoneName + dataChecklist?.find((item:any) => item?._id == id)?._id + selStructure?.zone + i,//Checklist
                                zone: dataChecklist?.find((item:any) => item?._id == id)?.item_name,
                                type: dataChecklist?.find((item:any) => item?._id == id)?.item_type_detail,
                                checked: checked,
                                item_id : dataChecklist?.find((item:any) => item?._id == id)?._id,
                                _id : resaddChecklist?._id,
                                zoneID: dataZone[i]?._id,
                                zoneName: dataZone[i]?.zone,
                                structureID: dataZone[i]?.children[findStructure]?._id,
                                structureName: dataZone[i]?.children[findStructure]?.zone,
                                warantee: 365,
                                newId: dataZone[i]._id + dataZone[i].children[findStructure]?._id
                            })

                            datacheckChecklist?.push({
                                _id: resaddChecklist?._id,
                                item_id: id,
                                parent: structureid,
                                in_zone: zoneid,
                            })
                            setdatacheckChecklist((pre: any) => datacheckChecklist);

                            //====================================================================================//
                            datadupChecklist.push(
                                {
                                    key: selStructure?.zoneName + dataChecklist?.find((item:any) => item?._id == id)?._id + selStructure?.zone + i,//Checklist
                                    zone: dataChecklist?.find((item:any) => item?._id == id)?.item_name,
                                    type: dataChecklist?.find((item:any) => item?._id == id)?.item_type_detail,
                                    checked: checked,
                                    item_id : dataChecklist?.find((item:any) => item?._id == id)?._id,
                                    _id : resaddChecklist?._id,
                                    zoneID: dataZone[i]?._id,
                                    zoneName: dataZone[i]?.zone,
                                    structureID: dataZone[i]?.children[findStructure]?._id,
                                    structureName: dataZone[i]?.children[findStructure]?.zone,
                                    warantee: 365,
                                    newId: dataZone[i]._id + dataZone[i].children[findStructure]?._id
                                }
                            )
                            setdatadupChecklist((pre: any) => datadupChecklist);
                            newData.push(dataZone[i])
                        }
                    }
                }else{
                    newData.push(dataZone[i])
                }
            }else if(checked == false){
                let findChecklist = datacheckChecklist?.findIndex((item:any) => item.item_id == id && item.in_zone == zoneid && item.parent == structureid); // หา STRUCTURE ว่ามีใน ARRAY หรือเปล่า
                if(findChecklist !== -1 && dataZone[i]._id == zoneid){
                    let checklistID = datacheckChecklist?.find((item:any) => item.item_id == id && item.in_zone == zoneid && item.parent == structureid)?._id;
                    ChecklistAPI(checklistID,structureid,"DEL",null)
                    
                    let indexStructure = dataZone[i]?.children?.findIndex((item:any) => item.zoneID == zoneid && item._id === structureid);
                    let indexChecklist = dataZone[i]?.children[indexStructure]?.children?.findIndex((item:any) => item.zoneID == zoneid && item.structureID == structureid && item.item_id == id);
                    dataZone[i]?.children[indexStructure]?.children?.splice(indexChecklist, 1);
                    
                    if(dataZone[i]?.children[indexStructure]?.children?.length == 0){
                        dataZone[i].children[indexStructure]={
                            key: dataZone[i].children[indexStructure]?.key,//Structure
                            zone: dataZone[i].children[indexStructure]?.zone,
                            type: dataZone[i].children[indexStructure]?.type,
                            checked: dataZone[i].children[indexStructure]?.checked,
                            item_id : dataZone[i].children[indexStructure]?.item_id,
                            _id : dataZone[i].children[indexStructure]?._id,
                            zoneID: dataZone[i].children[indexStructure]?.zoneID,
                            zoneName: dataZone[i].children[indexStructure]?.zoneName,
                            newId: dataZone[i]._id + dataZone[i].children[indexStructure]?._id
                        }
                    }

                    datacheckChecklist?.splice(findChecklist, 1);
                    setdatacheckChecklist((pre: any) => datacheckChecklist);

                    //====================================================================================//
                    let findIndexdup = datadupChecklist.findIndex((item:any) => item._id === id);
                    datadupChecklist.splice(findIndexdup, 1);
                    setdatadupChecklist((pre: any) => datadupChecklist);

                    newData.push(dataZone[i])
                }else{
                    newData.push(dataZone[i])
                }
            }
        }

        setdataZone((pre: any) => newData);
    }

    const erroronSelzone = () => {
        Modal.error({
        title: 'Create is Faild',
        content: 'กรุณาเลือก zone อย่างน้อย 1 zone ก่อนทำการ create',
        });
    };

    const erroronSelprop = () => {
        Modal.error({
            title: 'Create is Faild',
            content: 'กรุณาเลือก Property ก่อนทำการ create',
        });
    };

    const erroronName = () => {
        Modal.error({
        title: 'Create is Faild',
        content: 'กรุณาเปลี่ยนชื่อ Template',
        });
    };

    function oncheckedZonefordup(id: any, checked: any) {//FINISH
        let newData:any = [];
        if(checked == true){
            let findIndex = checkZonefordup?.findIndex((item:any)=> item?._id == id);
            if(findIndex == -1){
                checkZonefordup.push({_id:id});
                newData.push(...checkZonefordup);
                setcheckZonefordup((pre: any) => newData);
            }
        }else if(checked == false){
            let findIndex = checkZonefordup?.findIndex((item:any) => item._id === id);
            if(findIndex !== -1){
                checkZonefordup?.splice(findIndex, 1);
                newData.push(...checkZonefordup);
                setcheckZonefordup((pre: any) => newData);
            }
        }
    }

    function oncheckedStructurefordup(id: any, checked: any) {//FINISH
        let newData:any = [];
        if(checked == true){
            let findIndex = checkStructurefordup?.findIndex((item:any)=> item?._id == id);
            if(findIndex == -1){
                checkStructurefordup.push({_id:id});
                newData.push(...checkStructurefordup);
                setcheckStructurefordup((pre: any) => newData);
            }
        }else if(checked == false){
            let findIndex = checkStructurefordup?.findIndex((item:any) => item._id === id);
            if(findIndex !== -1){
                checkStructurefordup?.splice(findIndex, 1);
                newData.push(...checkZonefordup);
                setcheckStructurefordup((pre: any) => newData);
            }
        }
    }

    async function onfinishZonefordup(){//FINISH
        resaddZone = null;
        resaddStructure = null;
        for (let index = 0; index < checkZonefordup.length; index++) {
            let findIndex = dataZone?.findIndex((item:any) => item.item_id == checkZonefordup[index]?._id);
            if(findIndex !== -1){
                for (let i = 0; i < datadupStructure.length; i++) {
                    if(dataZone[findIndex]?.children){
                        let findStructure = dataZone[findIndex]?.children?.findIndex((item:any) => item.item_id.includes(String(datadupStructure[i]?.item_id)));
                        if(findStructure == -1){//มี Structureนี้อยู่ใน Array แล้ว
                            resaddStructure = await StructureAPI(datadupStructure[i]?.item_id,dataZone[findIndex]?._id,"ADD"); // ยิง API เพื่อสร้าง STRUCTURE
                            dataZone[findIndex].children.push({
                                key: dataZone[findIndex].zone + dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id + dataZone[findIndex]?._id + i,//Structure
                                zone: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?.item_name,
                                type: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?.item_type_detail,
                                checked: true,
                                item_id : dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id,
                                _id : resaddStructure?._id,
                                zoneID: dataZone[findIndex]?._id,
                                zoneName: dataZone[findIndex]?.zone,
                            })

                            // //====================================================================================//

                            datacheckStructure?.push({
                                _id: resaddStructure?._id,
                                item_id: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id,
                                parent: dataZone[findIndex]?._id
                            })
                            setdatacheckStructure((pre: any) => datacheckStructure);
                        }
                    }else{
                        if(dataZone[findIndex].checked == true){
                            resaddStructure = await StructureAPI(datadupStructure[i]?.item_id,dataZone[findIndex]?._id,"ADD"); // ยิง API เพื่อสร้าง STRUCTURE
                            
                            dataZone[findIndex].children = [];
                            dataZone[findIndex].children.push({
                                key: dataZone[findIndex].zone + dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id + dataZone[findIndex]?._id + i,//Structure
                                zone: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?.item_name,
                                type: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?.item_type_detail,
                                checked: true,
                                item_id : dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id,
                                _id : resaddStructure?._id,
                                zoneID: dataZone[findIndex]?._id,
                                zoneName: dataZone[findIndex]?.zone,
                            })
                        }else if(dataZone[findIndex].checked == false){
                            resaddZone = await ZoneAPI(checkZonefordup[index]?._id,"ADD") // ยิง API เพื่อสร้าง ZONE
                            resaddStructure = await StructureAPI(datadupStructure[i]?.item_id,resaddZone?._id,"ADD"); // ยิง API เพื่อสร้าง STRUCTURE

                            dataZone[findIndex]._id = resaddZone?._id;
                            dataZone[findIndex].checked = true;
                            dataZone[findIndex].children = [];
                            dataZone[findIndex].children.push({
                                key: dataZone[findIndex].zone + dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id + dataZone[findIndex]?._id + i,//Structure
                                zone: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?.item_name,
                                type: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?.item_type_detail,
                                checked: true,
                                item_id : dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id,
                                _id : resaddStructure?._id,
                                zoneID: dataZone[findIndex]?._id,
                                zoneName: dataZone[findIndex]?.zone,
                            })
                        }

                        //====================================================================================//

                        datacheckStructure?.push({
                            _id: resaddStructure?._id,
                            item_id: dataStructure?.find((item:any) => item?._id == datadupStructure[i]?.item_id)?._id,
                            parent: dataZone[findIndex]?._id
                        })
                        setdatacheckStructure((pre: any) => datacheckStructure);
                    }
                }

                let findZone = datacheckZone.findIndex((item:any) => item.item_id == checkZonefordup[index]?._id); // หา ZONE ว่ามีใน ARRAY หรือเปล่า
                if(findZone == -1){
                    datacheckZone?.push({
                        _id: resaddZone?._id,
                        item_id: dataZone[findIndex]?.item_id
                    })
                    setdatacheckZone((pre: any) => datacheckZone);
                }
                settsker(!tsker)
                setdataZone((pre:any)=> dataZone)
            }
        }
    }

    async function onfinishStructurefordup(inzone:any){
        resaddStructure = null;
        resaddChecklist = null;

        for (let i = 0; i < checkStructurefordup.length; i++) {
            let indexZone = dataZone?.findIndex((item:any) => item._id == inzone?.zoneID);
            if(indexZone !== -1){
                let indexSturcture = dataZone[indexZone]?.children?.findIndex((item:any) => item.item_id.includes(String(checkStructurefordup[i]?._id)));
                if(indexSturcture !== -1){//มี Structure อยู่ใน Array แล้ว
                    for (let ix = 0; ix < datadupChecklist.length; ix++) {
                        if(dataZone[indexZone]?.children[indexSturcture]?.children){// มี CHECKLIST อื่น ภายใน Araay แล้ว
                            let findChecklist = dataZone[indexZone]?.children[indexSturcture]?.children?.findIndex((item:any) => item.item_id.includes(String(datadupChecklist[ix]?.item_id)));
                            if(findChecklist == -1){
                                resaddChecklist = await ChecklistAPI(datadupChecklist[ix]?.item_id,dataZone[indexZone]?.children[indexSturcture]?._id,"ADD",365);

                                dataZone[indexZone].children[indexSturcture].children.push({
                                    key: dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?.item_name + dataChecklist?.find((item:any) => item?._id == item?._id)?._id + dataZone[indexZone]?.zone + checkStructurefordup[i]?._id + ix,//Checklist
                                    zone: datadupChecklist[ix]?.zone,
                                    type: datadupChecklist[ix]?.type,
                                    checked: datadupChecklist[ix]?.checked,
                                    item_id : datadupChecklist[ix]?.item_id,
                                    _id : resaddChecklist?._id,
                                    zoneID: dataZone[indexZone]?._id,
                                    zoneName: dataZone[indexZone]?.zone,
                                    structureID: dataZone[indexZone]?.children[indexSturcture]?._id,
                                    structureName: dataZone[indexZone]?.children[indexSturcture]?.zone,
                                    warantee : datadupChecklist[ix]?.warantee
                                })

                                datacheckChecklist?.push({
                                    _id: resaddChecklist?._id,
                                    item_id: datadupChecklist[ix]?.item_id,
                                    parent: dataZone[indexZone]?.children[indexSturcture]?._id,
                                    in_zone: dataZone[indexZone]?._id,
                                })
                                setdatacheckChecklist((pre: any) => datacheckChecklist);
                            }
                        }else{ // ยังไม่มี CHECKLIST อื่น ภายใน Araay
                            resaddChecklist = await ChecklistAPI(datadupChecklist[ix]?.item_id,dataZone[indexZone]?.children[indexSturcture]?._id,"ADD",365);

                            dataZone[indexZone].children[indexSturcture].children = [];
                            dataZone[indexZone].children[indexSturcture].children.push({
                                key: dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?.item_name + dataChecklist?.find((item:any) => item?._id == item?._id)?._id + dataZone[indexZone]?.zone + checkStructurefordup[i]?._id + ix,//Checklist
                                zone: datadupChecklist[ix]?.zone,
                                type: datadupChecklist[ix]?.type,
                                checked: datadupChecklist[ix]?.checked,
                                item_id : datadupChecklist[ix]?.item_id,
                                _id : resaddChecklist?._id,
                                zoneID: dataZone[indexZone]?._id,
                                zoneName: dataZone[indexZone]?.zone,
                                structureID: dataZone[indexZone]?.children[indexSturcture]?._id,
                                structureName: dataZone[indexZone]?.children[indexSturcture]?.zone,
                                warantee : datadupChecklist[ix]?.warantee
                            })

                            datacheckChecklist?.push({
                                _id: resaddChecklist?._id,
                                item_id: datadupChecklist[ix]?.item_id,
                                parent: dataZone[indexZone]?.children[indexSturcture]?._id,
                                in_zone: dataZone[indexZone]?._id,
                            })
                            setdatacheckChecklist((pre: any) => datacheckChecklist);
                        }
                    }
                }else if(indexSturcture == -1){//ไม่มี Structure ใน Array
                    resaddStructure = await StructureAPI(checkStructurefordup[i]?._id,dataZone[indexZone]?._id,"ADD"); // ยิง API เพื่อสร้าง STRUCTURE
                    let newChecklist: any = [];
                    for (let index = 0; index < datadupChecklist?.length; index++) {
                        // resaddChecklist = await ChecklistAPI(datadupChecklist[index]?.item_id,datadupChecklist[index]?.structureID,"ADD",null);
                        resaddChecklist = await ChecklistAPI(datadupChecklist[index]?.item_id,resaddStructure?._id,"ADD",365);
                        newChecklist.push({
                            key: dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?.item_name + dataChecklist?.find((item:any) => item?._id == item?._id)?._id + dataZone[indexZone]?.zone + checkStructurefordup[i]?._id + index,//Checklist
                            zone: datadupChecklist[index]?.zone,
                            type: datadupChecklist[index]?.type,
                            checked: datadupChecklist[index]?.checked,
                            item_id : datadupChecklist[index]?.item_id,
                            _id : resaddChecklist?._id,
                            zoneID: dataZone[indexZone]?._id,
                            zoneName: dataZone[indexZone]?.zone,
                            structureID: resaddStructure?._id,
                            structureName: dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?.item_name,
                            warantee : datadupChecklist[index]?.warantee
                        })
                    }

                    let newStructure = {
                        key: dataZone[indexZone]?.zone + dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?._id + dataZone[indexZone]?._id + i,//Structure
                        zone: dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?.item_name,
                        type: dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?.item_type_detail,
                        checked: true,
                        item_id : dataStructure?.find((item:any) => item?._id.includes(String(checkStructurefordup[i]?._id)))?._id,
                        _id : resaddStructure?._id,
                        zoneID: dataZone[indexZone]?._id,
                        zoneName: dataZone[indexZone]?.zone,
                        children: newChecklist
                    }

                    dataZone[indexZone].children.push(newStructure)

                    datacheckStructure?.push({
                        _id: resaddStructure?._id,
                        item_id: resaddStructure?.item,
                        parent: dataZone[indexZone]?._id
                    })
                    setdatacheckStructure((pre: any) => datacheckStructure);

                    datacheckChecklist?.push({
                        _id: resaddChecklist?._id,
                        item_id: resaddChecklist?.item,
                        parent: resaddStructure?._id,
                        in_zone: dataZone[indexZone]?._id,
                    })
                    setdatacheckChecklist((pre: any) => datacheckChecklist);
                }
                settsker(!tsker)
                setdataZone((pre:any)=> dataZone)
            }
        }
    }

    async function changeWarantee(id: any, value: any, datalist: any) {
        let newData:any = [];
        for (let i = 0; i < dataZone.length; i++) {
            if(dataZone[i]?.children){
                let findStructure: any = dataZone[i]?.children?.findIndex((item: any) => item?._id == datalist?.structureID && item?.zoneID == datalist?.zoneID);
                if(findStructure !== -1 && dataZone[i]?.children[findStructure]?.children){
                    let findChecklist: any = dataZone[i]?.children[findStructure]?.children?.findIndex((item: any) => item?.item_id == id  && item?.structureID == datalist?.structureID  && item?.zoneID == datalist?.zoneID);
                    if(findChecklist !== -1){
                        dataZone[i].children[findStructure].children[findChecklist].warantee = value;
                        
                        let data = {"warantee":value,}
                        let res = await axios.put(majorCareUrl + "/majorcare/updateTemplateChecklist?id=" + dataZone[i]?.children[findStructure]?.children[findChecklist]?._id, data , { headers: { } });
                        console.log(">>> UPDATE WARANTEE",res?.data)
                    }
                }
                newData.push(dataZone[i])
            }else{
                newData.push(dataZone[i])
            }
        }

        setdataZone((pre: any) => newData);
    }

    async function oncheckallWarantee(value: any){
        let newData:any = [];
        for (let i = 0; i < dataZone.length; i++) {
            if(dataZone[i]?.children){
                for (let ix = 0; ix < dataZone[i]?.children.length; ix++) {
                    if(dataZone[i]?.children[ix]?.children){
                        for (let iz = 0; iz < dataZone[i]?.children[ix]?.children.length; iz++) {
                            dataZone[i].children[ix].children[iz].warantee = value;
                            let data = {
                                "warantee":value,
                            }
                            let res = await axios.put(majorCareUrl + "/majorcare/updateTemplateChecklist?id=" + dataZone[i]?.children[ix]?.children[iz]?._id, data , { headers: { } });
                        }
                    }
                }
                newData.push(dataZone[i])
            }else{
                newData.push(dataZone[i])
            }
        }
        setdataZone((pre: any) => newData);
    }

    function onclickinTable(mode: any, data: any){
        if(mode == "ZONE"){
            setselZone(data);
            setmodalStructure(true);
        }else if(mode == "STRUCTURE"){
            setselStructure(data);
            setmodalChecklist(true);
        }
    }

    async function onfinishSAVE(value:any){
        let data = {
        "template_name": templateName,
        "template_name_en": templateName_en,
        "property": selPropArray?.map((item:any,index:any) => {return item?.id}),
        "unit_type" : selUnittypeArray?.map((item:any,index:any) => {return item?.id}),
        "wanrantee": waranteeAll,
        "update_date": dayjs().toISOString(),
        "update_by":{
            "user_id":user_data.user_profile.id,"user_name":user_data.username
        },
        }

        let res = await axios.put(majorCareUrl + "/majorcare/updateTemplate?id=" + templateID, data , { headers: { } });
        console.log(">>> UPDATE TEMPLATE",res?.data)
        replace("/majorcare/setup-hcu");
    }

  return (
    <Edit
      pageHeaderProps={{
        title: "Edit",
        extra: <></>,
      }}
      saveButtonProps={{hidden: true}}
    >
      <List
        title=""
        pageHeaderProps={{
          breadcrumb: (
            <Breadcrumb>
              <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
              <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <span className="last_breadcrumb">{t("hcu-setup-template.edit")}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          ),
          extra: <></>,
        }}
      />
      {/* <Form layout="vertical" fields={templateName}> */}
      <Form form={formCustomI} layout="vertical" onFinish={(values:any) => {
          dataProp?.filter((e:any)=> e.checked == true)?.length > 0 ? 
          dataZone?.filter((e:any)=> e.checked == true)?.length > 0 ? 
          tickerName == false && tickerName_en == false ? onfinishSAVE(values) : 
          erroronName() :
          erroronSelzone() : 
          erroronSelprop()
      }}>
        <div className="divider-container">
          <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
            {/* TEMPLATE LEFT ZONE */}<Col span={6} className="gutter-row" style={{borderRight: "1px solid #dedede", paddingTop: "2.5rem", paddingBottom: "2.5rem"}} id="templateee_custom_i_zone">
              <div style={{fontSize: "18px", fontWeight: "400", color: "#000",marginBottom: "1.5rem"}}>{t("hcu-setup-template.setup-template")}</div>

              <Form.Item
                  name="name_th"
                  label={t("hcu-setup-template.name-th")}
                  rules={[
                      {
                          required: true, message: "Please input template name(TH)"
                      },
                  ]}
              >
                <Input style={{border: tickerName == true ? "1px solid #f5222d" : "1px solid #595f6b"}} onChange={(e)=> tickernameTemplate(e?.target?.value)} defaultValue={templateName} value={templateName}/>
              </Form.Item>

              {/* ตัวตรวจกัน ชื่อ TEMPLATE ซ้ำ */}{tickerName == true && <div style={{color: "#f5222d",position: "absolute",transform: "translate(0, -1.4rem)"}}>ชื่อ Template ซ้ำกรุณาเปลี่ยนชื่อ</div>}

              <Form.Item
                  name="name_en"
                  label={t("hcu-setup-template.name-en")}
                  rules={[
                      {
                          required: true, message: "Please input template name(EN)"
                      },
                  ]}
              >
                <Input style={{border: tickerName_en == true ? "1px solid #f5222d" : "1px solid #595f6b"}} onChange={(e)=> tickernameTemplate_en(e?.target?.value)} defaultValue={templateName_en} value={templateName_en}/>
              </Form.Item>

              {/* ตัวตรวจกัน ชื่อ TEMPLATE ซ้ำ */}{tickerName_en == true && <div style={{color: "#f5222d",position: "absolute",transform: "translate(0, -1.4rem)"}}>ชื่อ Template ซ้ำกรุณาเปลี่ยนชื่อ</div>}

                <div style={{display: "flex",marginTop: "5rem"}}>
                    <div style={{fontSize: "18px", fontWeight: "400", color: "#000"}}>{t("property.title")}</div>
                    <div style={{width: "100%"}}><Button style={{color: "#fff", backgroundColor: "#4B78AF",borderRadius: "5px", float: "right"}} onClick={() => setmodalProp(true)}>{t("buttons.add")}</Button></div>
                </div>
                <Row gutter={8} id="propperty_custom_i_zone"> {/* PROPERTY ADD - REMOVE ZONE */}
                    {/* <Col span={19} className="gutter-row">
                        <div style={{fontSize: "18px", fontWeight: "400", color: "#000"}}>Property</div>
                    </Col>
                    <Col span={5} className="gutter-row">
                    <Button style={{color: "#fff", backgroundColor: "#4B78AF", width: "100%",borderRadius: "5px"}} onClick={() => setmodalProp(true)}>Add</Button>
                    </Col> */}
                    
                    <Col span={24} className="gutter-row">
                        <div style={{height: "auto"}}>
                            {dataProp?.filter((e:any)=> e.checked == true).map((item:any,index:any) => {
                                return <div key={index} style={{border: "1px solid #A4B9DC" , backgroundColor: "#A4B9DC",borderRadius: 5, height: "83px",paddingLeft: "0.5rem", marginTop: "0.5rem", cursor: "pointer"}} >
                                    <span style={{float: "right", position: "absolute", right: "10px", transform: "translate(0, 0.4rem)"}}><Button icon={<Icons.DeleteOutlined style={{color: "#DF6677", fontSize: "12px"}}/>} style={{width: "25px", height: "25px"}} onClick={() => checkedProp(item?.property_id, false,item?._id)}/></span>
                                    <div style={{width: "100%", height: "100%", backgroundColor: "#fff", borderRadius: "0px 5px 5px 0px",display: "grid", alignItems: "center",padding: "0.2rem 0.5rem 0.2rem 1rem"}}
                                        onClick={() => {
                                            setselProp(item);
                                            setmodalUnitType(true);
                                        }}
                                    >
                                        <div>
                                            <span style={{fontSize: "16px", fontWeight: 500}}>{item?.property_name}</span>
                                            {/* <span style={{float: "right"}}><Button icon={<Icons.DeleteOutlined style={{color: "#DF6677", fontSize: "12px"}}/>} style={{width: "25px", height: "25px"}} onClick={() => checkedProp(item?.property_id, false,item?._id)}/></span> */}
                                        </div>
                                        <div><span style={{fontSize: "13px", fontWeight: 400}}>Unit type ({item?.unit_type ? item?.unit_type.filter((e:any)=> e.checked == true)?.length : "0"})</span></div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </Col>
                </Row>       
            </Col> 

            {/* ZONE STRUCTURE CHECKLIST */}<Col span={18} className="gutter-row" style={{paddingTop: "2.5rem", paddingBottom: "2.5rem"}} id="all_stucture_custom_i_zone">
                <div style={{display: "flex", marginBottom: "1rem"}}>
                    <div className="custom_header_tool_in_template_1"><Button style={{backgroundColor: "#4B78AF", color: "#fff", width: "127px",borderRadius: "5px", float: "left"}} onClick={() => setmodalZone(true)}>{t("hcu-setup-template.add-zone")}</Button></div>
                    <div className="custom_header_tool_in_template_2" style={{marginTop: "5px"}}><span>{t("hcu-setup-template.warantee")}</span></div>
                    <div className="custom_header_tool_in_template_3" style={{display: "flex", justifyContent: "center"}}><Input defaultValue={waranteeAll} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} style={{textAlign: "center"}} onChange={(e)=> setwaranteeAll(e?.target?.value)}/></div>
                    <div className="custom_header_tool_in_template_4"><Button style={{backgroundColor: "#465D78", color: "#fff", width: "120px",borderRadius: "5px",float: "right"}} onClick={()=> oncheckallWarantee(waranteeAll)}>{t("hcu-setup-template.apply-all")}</Button></div>
                </div>

                <Table id="table_custom_hcu"
                    columns={columns}
                    // rowSelection={{ ...rowSelection }}
                    dataSource={dataZone?.filter((e:any)=> e.checked == true)}
                    pagination={false}
                />
            </Col>

          </Row>
          <Row style={{float: 'right',marginTop: "1.2rem"}}>
              <Button type="primary" onClick={() => formCustomI?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
          </Row>
        </div>

        <Modal //Property
                title="Add Property"
                visible={ismodalProp} 
                width={"600px"}
                onCancel={() => setmodalProp(false)}
                footer={<Button type="primary" onClick={()=> setmodalProp(false)} style={{borderRadius: "5px", width: "90px"}}>บันทึก</Button>}
                afterClose={()=> {}}
            >
                {dataProp?.map((item:any,index:any) => {
                    return <div style={{marginBottom: dataProp.length - 1 == index ? "0" : "1rem"}}><Checkbox key={index} style={{fontSize: "15px", fontWeight: 400, color: "#000"}} checked={item?.checked} onChange={(e) => checkedProp(item?.property_id, e?.target?.checked,item?._id)}>{item?.property_name}</Checkbox></div>
                })}
        </Modal>

        <Modal //Unit Type
            title={selProp?.property_name}
            visible={ismodalUnitType} 
            width={"600px"}
            onCancel={()=> setmodalUnitType(false)}
            footer={<Button type="primary" onClick={()=> setmodalUnitType(false)} style={{borderRadius: "5px"}}>บันทึก</Button>}
            afterClose={()=> {}}
        >
            {dataProp?.find((item:any) => item?._id == selProp?._id)?.unit_type?.map((items:any,index:any) => {
                return <div style={{marginBottom: dataProp?.find((item:any) => item?._id == selProp?._id)?.unit_type?.length - 1 == index ? "0" : "1rem"}}>
                    <Checkbox key={index} style={{fontSize: "15px", fontWeight: 400, color: "#000"}} checked={items?.checked} onChange={(e) => checkedUnitType(items?._id, selProp?._id, e?.target?.checked, items)}>
                        {items?.unit_type_name}
                    </Checkbox>
                </div>
            })}
            {/* {dataProp?.map((item:any,index:any) => {
                return <div style={{marginBottom: dataProp.length - 1 == index ? "0" : "1rem"}}><Checkbox key={index} style={{fontSize: "15px", fontWeight: 400, color: "#000"}} checked={item?.checked} onChange={(e) => checkedProp(item?.property_id, e?.target?.checked)}>{item?.property_name}</Checkbox></div>
            })} */}
        </Modal>

        {/* ============================================================================================================= */}

        <Modal //Zone
                title={"Zone"}
                visible={ismodalZone} 
                width={"600px"}
                onCancel={()=> setmodalZone(false)}
                footer={<Button type="primary" onClick={()=> setmodalZone(false)} style={{borderRadius: "5px", width: "90px"}}>บันทึก</Button>}
                afterClose={()=> {
                    setsearchZone("");
                    formCustomI?.resetFields(["inputsearchZone"]);
                }}
            >
                <Form.Item  label="" name="inputsearchZone">
                    <Input 
                        onChange={e => {
                            if(e.target.value.length > 0){
                                setsearchZone(e.target.value)
                            }else{
                                setsearchZone("")
                            }
                        }}
                        placeholder="Search" 
                        allowClear
                    />
                </Form.Item>

                <Divider />

                <div className="scrolling_checklist" style={{height: "auto",maxHeight: "500px", overflowY: "auto"}}>
                    {(searchZone !== "" ? dataZone?.filter((a:any) =>a?.zone?.includes(String(searchZone))) : dataZone)?.map((item:any,index:any) => {
                        return <div style={{marginBottom: dataZone?.length - 1 == index ? "0" : "1rem"}}><Checkbox key={index} style={{fontSize: "15px", fontWeight: 400, color: "#000"}} checked={item?.checked} onChange={(e) => checkedZone(item?._id, e?.target?.checked)}>{item?.zone}</Checkbox></div>
                    })}
                </div>
        </Modal>

        <Modal //Structure
          title={"Zone : " + selZone?.zone}
          visible={ismodalStructure} 
          width={"600px"}
          onCancel={()=> setmodalStructure(false)}
          footer={
              <Row gutter={8} style={{width: "100%"}}>
                  <Col span={12} className="gutter-row" style={{textAlign: "left"}}>
                      <Button type="primary" onClick={()=> setmodaldupStructure(true)} style={{background: "#4B78AF",borderRadius: "5px"}}>เพิ่มใน Zone อื่น ๆ</Button>
                  </Col>
                  <Col span={12} className="gutter-row">
                      <Button type="primary" onClick={()=> setmodalStructure(false)} style={{borderRadius: "5px", width: "90px"}}>บันทึก</Button>
                  </Col>
              </Row>
          }
          afterClose={()=> {
              setsearchStructure("");
              setdatadupStructure([]);
              // setdatacheckStructure([]);
              formCustomI?.resetFields(["inputsearchStructure"]);
          }}
      >
          <Form.Item  label="" name="inputsearchStructure">
              <Input 
                  onChange={e => {
                      if(e.target.value.length > 0){
                          setsearchStructure(e.target.value)
                      }else{
                          setsearchStructure("")
                      }
                  }}
                  placeholder="Search" 
                  allowClear
              />
          </Form.Item>

          <Divider />

          <div className="scrolling_checklist" style={{height: "auto",maxHeight: "500px", overflowY: "auto"}}>
              {(searchStructure !== "" ? dataStructure?.filter((a:any) =>a?.item_name?.includes(String(searchStructure))) : dataStructure)?.map((item:any,index:any) => {
                  return <div style={{marginBottom: dataStructure?.length - 1 == index ? "0" : "1rem"}}>
                      <Checkbox 
                          key={index} 
                          style={{fontSize: "15px", fontWeight: 400, color: "#000"}} 
                          // checked={item?.checked}
                          checked={dataZone?.find((i1:any) => i1?.zone == selZone?.zone)?.children?.find((i2:any) => i2?.zone == item?.item_name)?.checked}
                          onChange={(e) => checkedStructure(item?._id, selZone?._id, e?.target?.checked)}
                      >{item?.item_name}</Checkbox>
                  </div>
              })}
          </div>
      </Modal>

      <Modal //Checklist
          title={
              <div>
                  <div>{"Zone : " + selStructure?.zoneName}</div>
                  <div>{"Structure type : " + selStructure?.zone}</div>
              </div>
          }
          visible={ismodalChecklist} 
          width={"600px"}
          // style={{top: "20rem"}}
          onCancel={()=> setmodalChecklist(false)}
          footer={
              <Row gutter={8} style={{width: "100%"}}>
                  <Col span={12} className="gutter-row" style={{textAlign: "left"}}>
                      <Button type="primary" onClick={()=> setmodaldupChecklist(true)} style={{background: "#4B78AF", borderRadius: "5px"}}>เพิ่มใน Structure type อื่น</Button>
                  </Col>
                  <Col span={12} className="gutter-row">
                      <Button type="primary" onClick={()=> setmodalChecklist(false)} style={{borderRadius: "5px", width: "90px"}}>บันทึก</Button>
                  </Col>
              </Row>
          }
          afterClose={()=> {
              setsearchChecklist("");
              setdatadupChecklist([]);
              // setdatacheckChecklist([]);
              formCustomI?.resetFields(["inputsearchChecklist"]);
          }}
      >
          <Form.Item  label="" name="inputsearchChecklist">
              <Input 
                  onChange={e => {
                      if(e.target.value.length > 0){
                          setsearchChecklist(e.target.value)
                      }else{
                          setsearchChecklist("")
                      }
                  }}
                  placeholder="Search" 
                  allowClear
              />
          </Form.Item>

          <Divider />

          <div className="scrolling_checklist" style={{height: "auto",maxHeight: "500px", overflowY: "auto"}}>
              {(searchChecklist !== "" ? dataChecklist?.filter((a:any) =>a?.item_name?.includes(String(searchChecklist))) : dataChecklist)?.map((item:any,index:any) => {
                  return <div style={{marginBottom: dataChecklist?.length - 1 == index ? "0" : "1rem"}}>
                      <Checkbox 
                          key={index} 
                          style={{fontSize: "15px", fontWeight: 400, color: "#000"}} 
                          // checked={dataChecklist?.find((i1:any) => i1?.zone == selZone?.zone)?.children?.find((i2:any) => i2?.zone == item?.item_name)?.checked}
                          checked={dataZone?.find((i1:any) => i1?._id == selStructure?.zoneID)?.children?.find((i2:any) => i2?._id == selStructure?._id)?.children?.find((i3:any) => i3?.zone == item?.item_name)?.checked}
                          onChange={(e) => checkedChecklist(item?._id, selStructure?.zoneID, selStructure?._id, e?.target?.checked)}
                      >{item?.item_name}</Checkbox>
                  </div>
              })}
          </div>
      </Modal>

      <Modal //dupStructure
          title={"Zone : " + selZone?.zone}
          visible={ismodaldupStructure} 
          width={"600px"}
          onCancel={()=> setmodaldupStructure(false)}
          footer={<Button type="primary" onClick={()=> {
              onfinishZonefordup();
              setmodaldupStructure(false);
          }} style={{borderRadius: "5px", width: "90px"}}>เสร็จสิ้น</Button>}
          afterClose={()=> {
              setsearchdupStructure("");
              setcheckZonefordup([]);
              formCustomI?.resetFields(["inputsearchdupStructure"]);
          }}
      >
          <div style={{fontSize: "18px", color: "#4B647E"}}>เลือก Zone ที่ต้องการเพิ่ม</div>
          <Form.Item  label="" name="inputsearchdupStructure">
              <Input onChange={e => {if(e.target.value.length > 0){setsearchdupStructure(e.target.value)}else{setsearchdupStructure("")}}} placeholder="Search" allowClear/>
          </Form.Item>

          <Divider />

          <div className="scrolling_checklist" style={{height: "auto",maxHeight: "500px", overflowY: "auto"}}>
              {(searchdupStructure !== "" ? dataZonefordup?.filter((a:any) =>a?.zone?.includes(String(searchdupStructure))) : dataZonefordup)?.map((item:any,index:any) => {
                  return <div style={{marginBottom: dataZonefordup?.length - 1 == index ? "0" : "1rem"}}>
                      <Checkbox 
                          key={index}
                          style={{fontSize: "15px", fontWeight: 400, color: "#000"}}
                          disabled={item?._id === selZone?.item_id ? true:false}
                          onChange={(e) => {oncheckedZonefordup(item?._id, e?.target?.checked)}}
                          checked={checkZonefordup?.find((i:any) => i?._id == item?._id) ? true : false}
                      >
                          {item?.zone}
                      </Checkbox>
                  </div>
              })}
          </div>
      </Modal>

      <Modal //dupChecklist
          title={
              <div>
                  <div>{"Zone : " + selStructure?.zoneName}</div>
                  <div>{"Structure type : " + selStructure?.zone}</div>
              </div>
          }
          visible={ismodaldupChecklist} 
          width={"600px"}
          onCancel={()=> setmodaldupChecklist(false)}
          footer={<Button type="primary" onClick={()=> {
              onfinishStructurefordup(selStructure);
              setmodaldupChecklist(false);
          }} style={{borderRadius: "5px", width: "90px"}}>{t("hcu-setup-template.save")}</Button>}
          afterClose={()=> {
              setsearchChecklist("");
              setcheckStructurefordup([]);
              formCustomI?.resetFields(["inputsearchdupChecklist"]);
          }}
      >
          <div style={{fontSize: "18px", color: "#4B647E"}}>เลือก Structure type ที่ต้องการเพิ่ม</div>
          <Form.Item  label="" name="inputsearchdupChecklist">
              <Input onChange={e => {if(e.target.value.length > 0){setsearchdupChecklist(e.target.value)}else{setsearchdupChecklist("")}}} placeholder="Search" allowClear/>
          </Form.Item>

          <Divider />

          <div className="scrolling_checklist" style={{height: "auto",maxHeight: "500px", overflowY: "auto"}}>
              {(searchdupChecklist !== "" ? dataStructurefordup?.filter((a:any) =>a?.item_name?.includes(String(searchdupChecklist))) : dataStructurefordup)?.map((item:any,index:any) => {
                  return <div style={{marginBottom: dataStructurefordup?.length - 1 == index ? "0" : "1rem"}}>
                      <Checkbox 
                          key={index} 
                          style={{fontSize: "15px", fontWeight: 400, color: "#000"}} 
                          checked={checkStructurefordup?.find((i:any) => i?._id == item?._id) ? true : false}
                          onChange={(e) => oncheckedStructurefordup(item?._id, e?.target?.checked)}
                          disabled={item?._id === selStructure?.item_id ? true:false}
                      >{item?.item_name}</Checkbox>
                  </div>
              })}
          </div>
      </Modal>
      </Form>
    </Edit>
  );
};
