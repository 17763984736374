import { useApiUrl, useCustom, useCustomMutation, useLogin, useTranslate } from "@pankod/refine-core";

import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, Modal, Image, useModalForm, useModal, Icons, TextField, notification, Checkbox } from "@pankod/refine-antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { VERSION } from "../../constants";
import "./css/style.css";

import { TOKEN_KEY } from "../../constants";
import axios from "axios";
import { useState } from "react";

const { Text, Title } = Typography;

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const LoginPage: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const t = useTranslate();
    localStorage.clear();
    const API_URL = useApiUrl();

    const { modalProps, show, close } = useModal();
    const [inputEmail, setinputEmail] = useState<any>();


    const { mutate: login, isLoading } = useLogin<ILoginForm>();
    const { mutate: clogin } = useCustomMutation<any>();

    const errornoti = () => {
        notification.error({
            message: 'Login Failed',
            description: 'Incorrect username or password',
            duration: 2,
        });
    };

    const errorUserInActive = () => {
        notification.error({
            message: 'Login Failed',
            description: 'Incorrect username or password',
            duration: 2,
        });
    };

    const CardTitle = (
        <Title level={3} className="layout-title"><div id="logo_life"></div></Title>
    );

    async function callAPI(emailinput:any){
        axios
        .put( API_URL + "/users-permissions/user/forgot_password",
            {
                email: emailinput
            },
            { headers: { Authorization: `Bearer ` + TOKEN_KEY }}
        )
        .then((res) => {
            successModal();
            close();
        })
        .catch(error => {
            errorModal();
            close();
        });
    } 

    const successModal = () => {
        Modal.success({
            title: <span>{t("msg-vld-mail.regis-title-success")}</span>,
            content: <span>{t("msg-vld-mail.regis-title-success-desc")}</span>,
        });
    };

    const errorModal = () => {
        Modal.error({
          title: <span>{t("msg-vld-mail.regis-title-error")}</span>,
          content: <span>{t("msg-vld-mail.regis-title-error-desc")}</span>,
        });
    };

    let resultQuery:any = []

    async function callChkUserIsActive(email:any, password : any){
        // const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        resultQuery = await axios.post(API_URL + "/like-systems/loginPortal" , {"email": email, "password": password}  );
        return resultQuery
    }


    return (<>
        <AntdLayout>
            <div id="bg_web">
                <div className="col_1_cus_i"></div>
                <div className="col_2_cus_i">
                    <Card bordered={false} id="form_logi">
                        <Image preview={false} src={"../images/login/logo_news.svg"} className="logo_cus_i"/>
                        <div style={{marginTop: "5rem"}}>
                            <div className="font_size_30_cus_i" style={{textAlign: "left",color: "#fff"}}>Welcome</div>
                            <div className="font_size_15_cus_i" style={{textAlign: "left",color: "#45B2FF"}}>Please enter username and password</div>
                            <Form<ILoginForm>                 
                                layout="vertical"
                                form={form}
                                onFinish={async (values) => {
                                    //codeเก่า
                                    // let result = await callChkUserIsActive(values.username);
                                    // if(result){
                                    //         clogin({
                                    //         url:  API_URL + `/auth/local`,
                                    //         method: "post",
                                    //         values: {
                                    //         identifier: values.username,
                                    //         password: values.password,
                                    //         },
                                    //         errorNotification : false
                                    //     },
                                    //     {
                                    //         onError: (error, variables, context) => {
                                    //             errornoti();
                                    //         },
                                    //         onSuccess: (data:any, variables, context) => {
                                    //             login(values)                                   
                                    //         },
                                    //     } 
                                    //     )
                                    // }else{
                                    //     errorUserInActive();
                                    // }


                                    let result = await callChkUserIsActive(values.username,values.password);
                                    console.log('result', result);
                                    if(result){
                                         login(values);
                                    }else{
                                        errorUserInActive();
                                    }
                                }}
                                style={{marginTop: "1rem"}}
                            >
                                <Form.Item
                                    name="username"
                                    className="disable_req"
                                    // label={<label style={{ color: "#fff",fontSize:20,marginTop:4,marginBottom:0,fontWeight:400 }}>{t("pages.login.email", "Username")}</label>}
                                    rules={[{ required: true, message: "Please Enter Username" }]} 
                                >
                                    <Input
                                        type="text"
                                        size="large"
                                        id="input_logi"
                                        placeholder="Username"
                                    />
                                </Form.Item>
                                
                                <Form.Item
                                    name="password"
                                    className="disable_req cus_input_pwd"
                                    // label={<label style={{ color: "#fff",fontSize:20,marginTop:4,marginBottom:0,fontWeight:400 }}>{t("pages.login.password", "Password")}</label>}
                                    rules={[{ required: true, message: "Please Enter Password" }]}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="Password"
                                        iconRender={visible => (visible ? <Icons.EyeOutlined style={{color: "#fff"}}/> : <Icons.EyeInvisibleOutlined style={{color: "#fff"}}/>)}
                                        size="large"
                                        id="input_logi_pwd"
                                        style={{borderRadius: "5px"} }                
                                    />
                                </Form.Item>

                                <div style={{display: "flex"}}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox style={{color: "#fff"}} className="checkbox_lgn font_size_15_cus_i">
                                            {t("pages.login.remember", "Remember me")}
                                        </Checkbox>
                                    </Form.Item>
                                </div>

                                <div style={{display: "flex", marginTop: "3rem", marginBottom: "5rem"}}>
                                    <div className="col_3_cus_i">
                                        <Button
                                            size="large"
                                            htmlType="submit"
                                            loading={isLoading}
                                            block
                                            id="btn_signin"
                                        >
                                            {t("pages.login.lgn", "Login")}
                                        </Button>
                                    </div>
                                    <div className="col_4_cus_i">
                                        <Button
                                            size="large"
                                            loading={isLoading}
                                            block
                                            id="btn_forgotpwd"
                                            onClick={show}
                                            onMouseUp={(e => setinputEmail(undefined))}
                                        >
                                            <img src="../images/login/key_fgpwd.svg" className="icn_forgotpwd" style={{marginRight: "5%" }} />
                                            {t(
                                                "pages.login.forgotPassword",
                                                "Forgot password"
                                            )}
                                        </Button>
                                    </div>
                                </div>

                                {/* <Row gutter={16} style={{marginTop: "3rem",marginBottom: "5rem"}}>
                                    <Col className="gutter-row" span={13}>
                                        <Button
                                            size="large"
                                            htmlType="submit"
                                            loading={isLoading}
                                            block
                                            id="btn_signin"
                                        >
                                            {t("pages.login.lgn", "Login")}
                                        </Button>
                                    </Col>
                                    <Col className="gutter-row" span={11}>
                                        <Button
                                            size="large"
                                            loading={isLoading}
                                            block
                                            id="btn_forgotpwd"
                                            onClick={show}
                                            onMouseUp={(e => setinputEmail(undefined))}
                                        >
                                            <img src="../images/login/key_fgpwd.svg" className="icn_forgotpwd" style={{marginRight: "5%" }} />
                                            {t(
                                                "pages.login.forgotPassword",
                                                "Forgot password"
                                            )}
                                        </Button>
                                    </Col>
                                </Row> */}

                                {/* <div style={{marginTop: "3.5rem",marginBottom: "2rem",fontSize: "15px"}}>
                                    <span style={{marginRight: "1rem",color: "#45B2FF"}}>Don’t have an account yet ?</span>
                                    <span style={{color: "#fff",cursor: "pointer",textDecoration: "underline"}}>Create an account</span>
                                </div> */}

                                {/* <a onClick={show} onMouseUp={(e => setinputEmail(undefined))}>
                                        <p style={{ fontSize: "15px", float:"right",color:"#FF8787",marginTop: "10%"}}>Forgot password ?</p>
                                    </a>

                                    <div style={{ marginBottom: "80px" }}></div>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        id ="btn_signin"
                                        // style={"buttoncolor"}
                                        border-radius= "30px"
                                        block
                                    >
                                        {t("pages.login.signin", "Sign in")}
                                    </Button> */}
                            </Form>
                        </div>
                    </Card>
                </div>

                <Text className="font_size_12_cus_i" style={{color: "#fff", fontWeight: '300', position: "absolute", top: "93%", left: "5%",letterSpacing: "1px"}}>Version {VERSION}<br/>Powered By TECHSCAPE</Text>
            </div>
        </AntdLayout>
        
        <Modal {...modalProps} title="Forgot Password" onCancel={close} onOk={close} footer={
            [
                <Button key="submit" type="primary" onClick={() => {
                    callAPI(inputEmail);
                    setinputEmail(undefined);
                }}
                disabled ={inputEmail?.length > 5 ? false : true}
                style={{borderRadius: 5}}
                >Reset Password</Button>
            ]}>

            {/* <Row gutter={16}>
                <Col className="gutter-row" span={21}>
                    <Input placeholder ="Input Email"
                         onChange={e => {
                            if(e.target.value.length > 0){
                                setinputEmail(e.target.value)
                            }else{
                                setinputEmail(undefined)
                            }
                        }}
                    />
                </Col>
                <Col className="gutter-row" span={2}>
                    <Button style={{borderRadius: 5}} onClick={() => callAPI(inputEmail)}><Icons.SendOutlined /></Button>
                </Col>
            </Row> */}

            <Input placeholder ="Input Email"
                onChange={e => {
                    if(e.target.value.length > 0){
                        setinputEmail(e.target.value)
                    }else{
                        setinputEmail(undefined)
                    }
                }}
                value={inputEmail}
            />
        </Modal>
    </>);
};
